import { Button, CircularProgress, InputAdornment } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Validation } from "./validationSchema";
import { useBreakpoints } from "../../../../../hooks/useBreakpoins";
import { useCommonData } from "../../../../../hooks/useCommonData";
import useAuth from "../../../../../hooks/context/useAuth";
import {
  BoxContent,
  BreakpointBox,
  CircularLoading,
} from "../../../../../components/AtomicDesign/Atoms";
import { GenericInput } from "../../../../../components/AtomicDesign/Molecules";
import { HtmlTextEditor } from "../../../../../components/AtomicDesign/Organisms/ReactQuillHtmlEditor";
import { DropImageZone } from "../../../../../components/AtomicDesign/Organisms";

interface Props {
  handleSubmit(val: { type: string; data: CreateTreasureProduct }): void;
  submitLoading: boolean;
  product: TreasureProducts;
  removeFileAdmin(imageID: number): void;
  removeFileLoading: boolean;
}

export function EditTreasureProductBox({
  handleSubmit,
  submitLoading,
  product,
  removeFileAdmin,
  removeFileLoading,
}: Props) {
  const { orderModal } = useCommonData();
  const { isMobile } = useBreakpoints();
  const { language } = useAuth();
  const { validationSchema } = Validation(language);

  const [cleanFileState, setCleanFileState] = useState(false);

  const formik = useFormik<UpdateTreasureProduct>({
    initialValues: {
      name: "",
      description: "",
      kg: 0,
      itemsQuantity: 0,
      origin: "",
      type: "",
      file: [],
      oldFiles: [],
      price: 0,
      productID: 0,
    },
    validationSchema,
    async onSubmit(values: UpdateTreasureProduct) {
      handleSubmit({
        type: "update",
        data: {
          ...values,
        },
      });
      formik.resetForm();
      setCleanFileState((old) => !old);
    },
  });

  useEffect(() => {
    // controla se o modal vai ser de cadastro ou edição

    formik.setFieldValue("name", product?.name ?? "");
    formik.setFieldValue("description", product?.description ?? "");
    formik.setFieldValue("kg", product?.kg ?? 0);
    formik.setFieldValue("itemsQuantity", product?.itemsQuantity ?? 0);
    formik.setFieldValue("origin", product?.origin ?? "");
    formik.setFieldValue("type", product?.type ?? "");
    formik.setFieldValue("productID", product?.id ?? 0);
    formik.setFieldValue("price", product?.price ?? 0);
    formik.setFieldValue(
      "oldFiles",
      product?.treasureProductsImages?.length > 0
        ? product?.treasureProductsImages
        : []
    );

    // eslint-disable-next-line
  }, [product]);

  return (
    <BoxContent config={{ minWidth: "180px", p: 3 }}>
      {submitLoading ? (
        <CircularLoading text="Atualizando produto" />
      ) : (
        <>
          <GenericInput
            hasIcon={false}
            onChange={(value, name) => formik.setFieldValue(name, value)}
            error={formik?.touched?.name && formik?.errors?.name ? true : false}
            errorMessage={formik?.touched?.name ? formik?.errors?.name : ""}
            value={formik?.values?.name}
            placeholder={orderModal.name}
            inputName="name"
          />
          <GenericInput
            hasIcon={false}
            onChange={(value, name) => formik.setFieldValue(name, value)}
            error={formik?.touched?.type && formik?.errors?.type ? true : false}
            errorMessage={formik?.touched?.type ? formik?.errors?.type : ""}
            value={formik?.values?.type}
            placeholder={orderModal.type}
            inputName="type"
          />
          <GenericInput
            hasIcon={false}
            onChange={(value, name) => formik.setFieldValue(name, value)}
            error={
              formik?.touched?.origin && formik?.errors?.origin ? true : false
            }
            errorMessage={formik?.touched?.origin ? formik?.errors?.origin : ""}
            value={formik?.values?.origin}
            placeholder={orderModal.origin}
            inputName="origin"
          />
          <HtmlTextEditor
            onChange={(val) => formik.setFieldValue("description", val)}
            value={formik.values.description}
          />
          <BreakpointBox props={{ mt: isMobile ? 10 : 6 }}>
            <GenericInput
              hasIcon={false}
              onChange={(value, name) =>
                formik.setFieldValue(name, Number(value))
              }
              error={
                formik?.touched?.price && formik?.errors?.price ? true : false
              }
              errorMessage={formik?.touched?.price ? formik?.errors?.price : ""}
              value={formik?.values?.price}
              placeholder={orderModal.price}
              inputName="price"
              inputType="number"
              startAdornment={
                <InputAdornment position="start">￥</InputAdornment>
              }
            />
            <GenericInput
              hasIcon={false}
              onChange={(value, name) =>
                formik.setFieldValue(name, Number(value))
              }
              error={formik?.touched?.kg && formik?.errors?.kg ? true : false}
              errorMessage={formik?.touched?.kg ? formik?.errors?.kg : ""}
              value={formik?.values?.kg}
              placeholder={orderModal.kg}
              inputName="kg"
              inputType="number"
              startAdornment={
                <InputAdornment position="start">g</InputAdornment>
              }
            />
            <GenericInput
              hasIcon={false}
              onChange={(value, name) =>
                formik.setFieldValue(name, Number(value))
              }
              error={
                formik?.touched?.itemsQuantity && formik?.errors?.itemsQuantity
                  ? true
                  : false
              }
              errorMessage={
                formik?.touched?.itemsQuantity
                  ? formik?.errors?.itemsQuantity
                  : ""
              }
              value={formik?.values?.itemsQuantity}
              placeholder={orderModal.quantity}
              inputName="itemsQuantity"
              inputType="number"
            />
          </BreakpointBox>
          {removeFileLoading ? (
            <CircularProgress />
          ) : (
            <DropImageZone
              oldFiles={formik.values.oldFiles}
              handleChangeFiles={(images) =>
                formik.setFieldValue("file", images)
              }
              removeFileAdmin={removeFileAdmin}
              cleanFileState={cleanFileState}
            />
          )}

          <Button
            sx={{ mt: 3 }}
            fullWidth
            variant="contained"
            onClick={() => formik.handleSubmit()}
          >
            Editar
          </Button>
        </>
      )}
    </BoxContent>
  );
}
