import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import {
  AppLayout,
  LoginLayout,
  AdminLayout,
  LpLayout,
} from "../components/layouts";
import {
  AboutUs,
  AdminDashboard,
  AlertsConfig,
  ClientDashboard,
  ClientMyInfo,
  ClientNewOrder,
  ContactPage,
  Credits,
  CreditsAdmin,
  ForgotPassword,
  FreteConfigAdmin,
  LandingPage,
  Login,
  MaintenancePage,
  NewUser,
  OrdersAdmin,
  PrivacyPolicy,
  Shippments,
  ShippmentsAdmin,
  SiteConfigAdmin,
  TermsConditions,
  TreasureProducts,
  TreasureProductsOrders,
  UsersAdmin,
  UsersProducts,
  UsersProductsAdmin,
} from "../pages";
import { OrdersProvider } from "../hooks/context/useOrders";
import { OrdersAdminProvider } from "../hooks/context/useOrdersAdmin";
import { CreditsProvider } from "../hooks/context/useCredits";
import { CreditsAdminProvider } from "../hooks/context/useCreditsAdmin";
import { ProductsAdminProvider } from "../hooks/context/useProductsAdmin";
import { ProductsProvider } from "../hooks/context/useProducts";
import { ShippmentsProvider } from "../hooks/context/useShippments";
import { PaymentConclusion } from "../pages/PaymentConclusion";
import { TreasureProductsProvider } from "../hooks/context/useTreasureProducts";

export function RoutesTreasureBox() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LpLayout />}>
          <Route path="" element={<LandingPage />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/terms-and-conditions" element={<TermsConditions />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Route>

        {/* login */}

        <Route path="/login" element={<LoginLayout />}>
          <Route path="" element={<Login />} />
          <Route path="new-user" element={<NewUser />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
        </Route>

        {/* client */}

        <Route path="/treasurebox-app" element={<AppLayout />}>
          <Route path="" element={<ClientDashboard />} />
          <Route
            path="register-order"
            element={
              <OrdersProvider>
                <ClientNewOrder />
              </OrdersProvider>
            }
          />
          <Route
            path="credits"
            element={
              <CreditsProvider>
                <Credits />
              </CreditsProvider>
            }
          />
          <Route
            path="suite"
            element={
              <ProductsProvider>
                <UsersProducts />
              </ProductsProvider>
            }
          />
          <Route
            path="shippments"
            element={
              <ShippmentsProvider>
                <Shippments />
              </ShippmentsProvider>
            }
          />
          <Route path="my-info" element={<ClientMyInfo />} />
          <Route
            path="treasure-store"
            element={
              <TreasureProductsProvider>
                <TreasureProducts />
              </TreasureProductsProvider>
            }
          />
          <Route
            path="treasure-product-orders"
            element={
              <TreasureProductsProvider>
                <TreasureProductsOrders />
              </TreasureProductsProvider>
            }
          />
        </Route>

        {/* admin */}

        <Route
          path="/treasurebox-app/admin"
          element={
            <TreasureProductsProvider>
              <ProductsAdminProvider>
                <OrdersAdminProvider>
                  <ShippmentsProvider>
                    <AdminLayout />
                  </ShippmentsProvider>
                </OrdersAdminProvider>
              </ProductsAdminProvider>
            </TreasureProductsProvider>
          }
        >
          <Route path="" element={<AdminDashboard />} />
          <Route path="orders" element={<OrdersAdmin />} />
          <Route path="auctions" element={<OrdersAdmin />} />
          <Route path="products" element={<UsersProductsAdmin />} />
          <Route path="shippments" element={<ShippmentsAdmin />} />
          <Route path="treasure-store" element={<TreasureProducts />} />
          <Route
            path="treasure-product-orders"
            element={<TreasureProductsOrders />}
          />

          <Route path="system-config" element={<SiteConfigAdmin />} />
          <Route path="fretes-config" element={<FreteConfigAdmin />} />
          <Route path="alerts-config" element={<AlertsConfig />} />
          <Route path="users" element={<UsersAdmin />} />
          <Route
            path="credits-admin"
            element={
              <CreditsAdminProvider>
                <CreditsAdmin />
              </CreditsAdminProvider>
            }
          />
        </Route>

        <Route path="/success-pay" element={<PaymentConclusion />} />
        <Route path="/maintenance" element={<MaintenancePage />} />

        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
  );
}
