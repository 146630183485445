import { useBreakpoints } from "../../../../hooks/useBreakpoins";
import { CustomModal } from "../../Organisms";
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { chargeByStripeOrderToBuy } from "../../../../services/orderToBuyService";
import { chargeShippmentByCardSerice } from "../../../../services/shippmentsService";
import { chargeCreditCardCreditsService } from "../../../../services/creditsService";
import useAuth from "../../../../hooks/context/useAuth";
import { createTreasureProductOrderCard } from "../../../../services/treasureProductsOrder";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY as any);

interface Props {
  open: boolean;
  setOpen: (val: boolean) => void;
  itensInfo: ChargeCardOrderToBuy;
  isEmbed?: boolean;
}

export function PaymentModalCreditCard({
  open,
  setOpen,
  itensInfo,
  isEmbed = false,
}: Props) {
  const { isSmalldesktop } = useBreakpoints();
  const { user } = useAuth();

  const fetchClientSecret = async () => {
    // await createCreditCardCharge(payload);
    if (itensInfo.type === "orderToBuy") {
      const options = await chargeByStripeOrderToBuy({
        ...itensInfo,
      });
      return options;
    }

    if (itensInfo.type === "Shippment") {
      const options = await chargeShippmentByCardSerice({
        ...itensInfo,
      });
      return options;
    }

    if (itensInfo.type === "credit") {
      const options = await chargeCreditCardCreditsService({
        creditID: itensInfo.itemID,
        totalValue: itensInfo.totalValue,
      });
      return options;
    }

    if (itensInfo.type === "treasureProductOrder") {
      const options = await createTreasureProductOrderCard({
        productID: itensInfo.productID,
        boughtPrice: Math.ceil(itensInfo.totalValue),
        quantity: itensInfo.quantity,
        suiteID: user?.suite[0].id,
      });
      return options;
    }
  };

  if (isEmbed) {
    return (
      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={{
          fetchClientSecret,
        }}
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    );
  }

  return (
    <CustomModal
      openModal={open}
      setOpenModal={() => {
        setOpen(false);
      }}
      width={isSmalldesktop ? "90%" : "40%"}
      disableBackdropClick
      title={"Please, insert your credit card data"}
    >
      {/* <PaymentSuccess seconds={seconds} /> */}

      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={{
          fetchClientSecret,
        }}
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </CustomModal>
  );
}
