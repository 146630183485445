import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Paper,
  Typography,
} from "@mui/material";
import { useCommonData } from "../../../../hooks/useCommonData";
import { UseDateTime } from "../../../../hooks/useDateTime";
import { useBreakpoints } from "../../../../hooks/useBreakpoins";
import { useJPYmoney } from "../../../../hooks/useJPYmoney";
import { amber, grey, red, teal } from "@mui/material/colors";
import useAuth from "../../../../hooks/context/useAuth";
import {
  DropFileInput,
  SimpleConfirmModal,
} from "../../../../components/AtomicDesign/Molecules";
import { useState } from "react";
import { NoData } from "../../../../components/AtomicDesign/Atoms";

interface Props {
  data: TreasureProductOrder;
  isLoading?: boolean;
  submitNewStatus: (data: UpdateTreasureProductOrder) => void;
  verifyPay: (data: ConfirmPayTreasureProductOrder) => void;
}

export function TreasureProductOrderDetails({
  data,
  isLoading,
  submitNewStatus,
  verifyPay,
}: Props) {
  const { treasureProductBuyModal } = useCommonData();
  const { formatedDate } = UseDateTime();
  const { isSmalldesktop, isMobile } = useBreakpoints();
  const { JPYmoney } = useJPYmoney();
  const { isAdmin } = useAuth();
  const [openModal, setOpenModal] = useState({
    message: "",
    open: false,
    status: "",
  });

  const translateStatus = (st: TreasureProductsOrderStatus) => {
    const status = {
      paymentSent: {
        text: treasureProductBuyModal.paymentSent,
        color: teal[400],
      },
      waitingPayment: {
        text: treasureProductBuyModal.waitingPayment,
        color: amber[400],
      },
      pickingFromStock: {
        text: treasureProductBuyModal.pickingFromStock,
        color: amber[800],
      },
      registeredSuite: {
        text: treasureProductBuyModal.registeredSuite,
        color: teal[800],
      },
      canceled: { text: treasureProductBuyModal.canceled, color: red[400] },
      canceledWithRefund: {
        text: treasureProductBuyModal.canceledWithRefund,
        color: red[800],
      },
      notPaid: { text: treasureProductBuyModal.notPaid, color: red[900] },
    };
    return status[st as keyof typeof status];
  };

  const imgsize = isMobile ? 100 : 150;
  const fontSize = isMobile ? 14 : 18;

  const canMakeChanges =
    isAdmin &&
    !["registeredSuite", "canceled", "canceledWithRefund"].includes(
      data?.status
    );

  return (
    <Box display={"flex"} flexDirection={"column"} gap={2}>
      {isLoading ? (
        <CircularProgress />
      ) : data?.id ? (
        <>
          <Box
            display={"flex"}
            flexWrap={"wrap"}
            justifyContent={"center"}
            gap={3}
            width={"100%"}
          >
            <Paper
              elevation={2}
              sx={{
                display: "flex",
                gap: 1,
                p: 1,
                height: "fit-content",
                margin: isSmalldesktop ? "10px 0" : "0",
                width: "100%",
              }}
            >
              <img
                src={`${data?.treasureProductsXOrders[0]?.treasureProducts?.treasureProductsImages[0]?.code}`}
                width={imgsize}
                height={imgsize}
                alt="figure"
                style={{
                  maxHeight: imgsize,
                  maxWidth: imgsize,
                  objectFit: "contain",
                  borderRadius: 5,
                  background: grey[200],
                  marginBottom: 8,
                }}
              />
              <Box width={"100%"}>
                <Typography
                  fontSize={fontSize}
                  style={{ wordBreak: "break-word" }}
                >
                  Order n° {data?.id} - {formatedDate(data?.createdAt)}
                </Typography>
                <Typography
                  fontSize={fontSize}
                  color={grey[600]}
                  style={{ wordBreak: "break-word" }}
                >
                  {data?.treasureProductsXOrders[0]?.treasureProducts?.name}
                </Typography>

                <Typography
                  fontSize={fontSize}
                  color={grey[600]}
                  style={{ wordBreak: "break-word" }}
                  className="elip2"
                >
                  Product id:{" "}
                  {data?.treasureProductsXOrders[0]?.treasureProductsId}
                </Typography>

                <Typography
                  style={{
                    wordBreak: "break-word",
                    fontSize: fontSize,
                  }}
                  mt={1}
                  color={amber[900]}
                >
                  {JPYmoney(data?.boughtPrice)} - x{data?.quantity}
                </Typography>

                <Typography
                  style={{
                    wordBreak: "break-word",
                    fontSize: fontSize,
                  }}
                  mt={1}
                  color={amber[900]}
                >
                  Total: {JPYmoney(data?.boughtPrice * data?.quantity)}
                </Typography>
                <Typography
                  fontSize={fontSize}
                  style={{ wordBreak: "break-word" }}
                >
                  Suite {data?.suiteId}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "flex-end",
                  }}
                >
                  <Chip
                    label={translateStatus(data?.status).text}
                    style={{
                      background: translateStatus(data?.status).color,
                      color: "white",
                      fontWeight: 500,
                    }}
                  />
                </Box>
              </Box>
            </Paper>
          </Box>
          {isAdmin && (
            <Box
              display={"flex"}
              flexWrap={"wrap"}
              justifyContent={"center"}
              gap={3}
              width={"100%"}
            >
              <Paper
                elevation={2}
                sx={{
                  display: "flex",
                  gap: 1,
                  p: 1,
                  height: "fit-content",
                  margin: isSmalldesktop ? "10px 0" : "0",
                  width: "100%",
                }}
              >
                <Box>
                  <Typography
                    fontSize={fontSize}
                    style={{ wordBreak: "break-word" }}
                  >
                    Client: {data?.suite?.Users?.name}
                  </Typography>
                  <Typography
                    fontSize={fontSize}
                    style={{ wordBreak: "break-word" }}
                  >
                    E-mail: {data?.suite?.Users?.email}
                  </Typography>
                </Box>
              </Paper>
            </Box>
          )}
          {isAdmin && data?.paymentMethod === "card" && (
            <Box
              display={"flex"}
              flexWrap={"wrap"}
              justifyContent={"center"}
              gap={3}
              width={"100%"}
            >
              <Typography variant="h1">Payment info</Typography>
              <Paper
                elevation={2}
                sx={{
                  display: "flex",
                  gap: 1,
                  p: 1,
                  height: "fit-content",
                  margin: isSmalldesktop ? "10px 0" : "0",
                  width: "100%",
                }}
              >
                <Box>
                  <Typography
                    fontSize={fontSize}
                    style={{ wordBreak: "break-word" }}
                  >
                    Payment intent: {data?.paymentIntent}
                  </Typography>
                  <Typography
                    fontSize={fontSize}
                    style={{ wordBreak: "break-word" }}
                  >
                    Charge: {data?.chargeID}
                  </Typography>
                </Box>
              </Paper>
            </Box>
          )}

          {data?.paymentMethod !== "card" && (
            <>
              <Typography variant="h1" textAlign={"center"} mb={2} mt={2}>
                Payment file:
              </Typography>
              <DropFileInput
                onFileChange={() => {}}
                oldFiles={data?.paymentVoucherTreasurProductsOrders ?? []}
                fileRemoveAdmin={() => {}}
              />
            </>
          )}

          {data?.paymentMethod === "card" &&
            data?.status === "waitingPayment" && (
              <Button
                variant="contained"
                onClick={() =>
                  verifyPay({
                    sessionid: data?.chargeID,
                    treasureProductOrderID: data?.id,
                  })
                }
              >
                Verify payment
              </Button>
            )}

          {isAdmin && canMakeChanges && (
            <Box>
              <Typography textAlign={"center"} variant="h1" mb={2}>
                Select a new status
              </Typography>

              <Box
                display={"flex"}
                justifyContent={"center"}
                width={"100%"}
                gap={1}
                flexWrap={"wrap"}
              >
                <Button
                  variant="contained"
                  onClick={() =>
                    setOpenModal({
                      message: treasureProductBuyModal.messageSeparating,
                      open: true,
                      status: "pickingFromStock",
                    })
                  }
                  disabled={!canMakeChanges}
                  sx={{
                    width: 150,
                    background: amber[800],
                    "&:hover": { background: amber[500] },
                  }}
                >
                  {treasureProductBuyModal.pickingFromStock}
                </Button>
                <Button
                  onClick={() =>
                    setOpenModal({
                      message: treasureProductBuyModal.suiteRegistration,
                      open: true,
                      status: "registeredSuite",
                    })
                  }
                  disabled={!canMakeChanges}
                  variant="contained"
                  sx={{
                    width: 150,
                    background: teal[800],
                    "&:hover": { background: teal[500] },
                  }}
                >
                  {treasureProductBuyModal.registeredSuite}
                </Button>
                <Button
                  onClick={() =>
                    setOpenModal({
                      message: treasureProductBuyModal.cancel,
                      open: true,
                      status: "canceled",
                    })
                  }
                  disabled={!canMakeChanges}
                  variant="contained"
                  sx={{
                    width: 150,
                    background: red[400],
                    "&:hover": { background: amber[200] },
                  }}
                >
                  {treasureProductBuyModal.canceled}
                </Button>
                <Button
                  onClick={() =>
                    setOpenModal({
                      message: treasureProductBuyModal.cancelRefund,
                      open: true,
                      status: "canceledWithRefund",
                    })
                  }
                  disabled={!canMakeChanges}
                  variant="contained"
                  sx={{
                    width: 150,
                    background: red[800],
                    "&:hover": { background: red[500] },
                  }}
                >
                  {treasureProductBuyModal.canceledWithRefund}
                </Button>
              </Box>
            </Box>
          )}
        </>
      ) : (
        <NoData text="No data" />
      )}

      <SimpleConfirmModal
        cancelClick={() =>
          setOpenModal({ message: "", open: false, status: "" })
        }
        confirmClick={() => {
          submitNewStatus({
            status: openModal.status,
            treasureProductOrderID: data?.id,
          });
          setOpenModal({ message: "", open: false, status: "" });
        }}
        open={openModal.open}
        text={openModal.message}
      />
    </Box>
  );
}
