import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useBreakpoints } from "../../../../hooks/useBreakpoins";
import { useJPYmoney } from "../../../../hooks/useJPYmoney";
import {
  BreakpointBox,
  DataText,
  FadeInContainer,
  NoData,
} from "../../../../components/AtomicDesign/Atoms";
import { amber, grey, teal } from "@mui/material/colors";
import useAuth from "../../../../hooks/context/useAuth";
import {
  DropFileInput,
  GenericSelect,
} from "../../../../components/AtomicDesign/Molecules";
import { useCommonData } from "../../../../hooks/useCommonData";
import { PaymentModalCreditCard } from "../../../../components/AtomicDesign/Molecules/PaymentModalCreditCard";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import { calcTaxTreasureProductOrder } from "../../../../services/treasureProductsOrder";

interface Props {
  product: TreasureProducts;
  setOpenEdit: (val: boolean) => void;
  treasureProductOrderBuy: (val: CreateTreasureProductOrderByFiles) => void;
  loadingBuy: boolean;
  isProductLoading: boolean;
}

export function TreasureProductDetails({
  product,
  setOpenEdit,
  treasureProductOrderBuy,
  loadingBuy,
  isProductLoading,
}: Props) {
  const { isSmalldesktop, isMobile } = useBreakpoints();
  const { orderModal, paymentChoices, treasureProductBuyModal } =
    useCommonData();
  const { isAdmin, user } = useAuth();
  const { JPYmoney, getPixValue } = useJPYmoney();
  const [imageIndex, setImageIndex] = useState(0);
  const [selectedQTD, setSelectedQTD] = useState(1);
  const [activeStep, setActiveStep] = useState(1);
  const [quantitySelectedToBuy, setQuantitySelectedToBuy] = useState({
    quantity: 0,
    paymentMethod: "",
  });
  const [payModalOpen, setPayModalOpen] = useState(false);
  const [filesOrder, setFilesOrder] = useState([]);
  const [taxes, setTaxes] = useState({
    tax: 0,
    total: 0,
    brl: "",
    unitPrice: 0,
  });

  const maxWidth = isSmalldesktop ? "100%" : 350;
  const maxWidthToBuy = isSmalldesktop ? "100%" : 361;

  const getInfoPaymentChoice = () => {
    switch (quantitySelectedToBuy.paymentMethod) {
      case "wise":
        return orderModal.wisePayment;
      case "paypal":
        return orderModal.paypalPayment;
      case "pix":
        return orderModal.pixPayment;
      case "card":
        return orderModal.cardPayment;
      default:
        return "";
    }
  };

  const calcTaxTreasureOrder = async () => {
    try {
      const response = await calcTaxTreasureProductOrder({
        paymentMethod: quantitySelectedToBuy.paymentMethod,
        value: product?.price * quantitySelectedToBuy.quantity,
        quantity: quantitySelectedToBuy.quantity,
      });

      if (quantitySelectedToBuy.paymentMethod === "pix") {
        const pix = await getPixValue(response.total);
        setTaxes({
          brl: pix,
          tax: response.tax,
          total: response.total,
          unitPrice: response.unitPrice,
        });
      } else {
        setTaxes({
          brl: "",
          tax: response.tax,
          total: response.total,
          unitPrice: response.unitPrice,
        });
      }
    } catch {}
  };

  useEffect(() => {
    if (quantitySelectedToBuy.paymentMethod) {
      calcTaxTreasureOrder();
    }
    // eslint-disable-next-line
  }, [quantitySelectedToBuy.paymentMethod]);

  const ResumeOrder = ({ payment = true }: { payment: boolean }) => (
    <FadeInContainer>
      <Box>
        <Typography textAlign={"center"} variant="h1" mb={2}>
          {treasureProductBuyModal.confirmOder}
        </Typography>
        <Box
          display={"flex"}
          flexWrap={"wrap"}
          justifyContent={"center"}
          gap={3}
          width={"100%"}
        >
          <Box width={isSmalldesktop ? "100%" : "auto"}>
            <img
              src={
                product?.treasureProductsImages?.length > 0
                  ? product?.treasureProductsImages[0]?.code
                  : ""
              }
              width={maxWidthToBuy}
              height={361}
              alt="figure"
              style={{
                maxHeight: 350,
                objectFit: "contain",
                borderRadius: 5,
                background: grey[200],
                marginBottom: 8,
              }}
            />
          </Box>

          <Paper
            elevation={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              p: 3,
              height: "fit-content",
              margin: isSmalldesktop ? "10px 0" : "0",
              width: isSmalldesktop ? "80%" : "58%",
            }}
          >
            <Box>
              <Typography
                fontSize={isSmalldesktop ? 18 : 28}
                color={grey[600]}
                style={{ wordBreak: "break-word" }}
              >
                {product?.name}
              </Typography>
              <Typography sx={{ mt: 2 }} color={grey[600]}>
                {product?.origin}
              </Typography>
              <Typography sx={{ mt: 2 }} color={grey[600]}>
                {product?.type}
              </Typography>

              <Typography
                style={{
                  wordBreak: "break-word",
                  fontSize: isSmalldesktop ? 30 : 40,
                }}
                mt={1}
                color={amber[900]}
              >
                {taxes.total === 0
                  ? JPYmoney(product?.price * quantitySelectedToBuy.quantity)
                  : JPYmoney(taxes.total)}
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  mt: 2,
                }}
              >
                <Typography>
                  {treasureProductBuyModal.quantitySelected}:{" "}
                  {quantitySelectedToBuy.quantity}
                </Typography>

                {payment && (
                  <BreakpointBox>
                    <GenericSelect
                      onChange={(value) =>
                        setQuantitySelectedToBuy((old) => ({
                          ...old,
                          paymentMethod: value,
                        }))
                      }
                      value={quantitySelectedToBuy.paymentMethod}
                      placeholder={orderModal.payment}
                      name="paymentChoice"
                      options={paymentChoices}
                      props={{ sx: { mt: 2 } }}
                    />

                    <Button
                      variant="contained"
                      sx={{
                        bgcolor: teal[300],
                        "&:hover": { bgcolor: teal[500] },
                        mt: isMobile ? 2 : 0,
                      }}
                      disabled={
                        quantitySelectedToBuy.quantity < 1 ||
                        quantitySelectedToBuy.paymentMethod === ""
                      }
                      onClick={() => {
                        setPayModalOpen(true);
                        setActiveStep(3);
                      }}
                    >
                      {treasureProductBuyModal.goToPayment}
                    </Button>
                  </BreakpointBox>
                )}

                {!payment && quantitySelectedToBuy.paymentMethod !== "" && (
                  <FormHelperText
                    sx={{ bgcolor: amber[100], borderRadius: 2, p: 1 }}
                  >
                    <WarningAmberRoundedIcon sx={{ marginBottom: -0.8 }} />
                    {getInfoPaymentChoice()}
                  </FormHelperText>
                )}
              </Box>
            </Box>
            <Box mt={2}>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                width={"100%"}
                mb={1}
                mt={1}
              >
                <Typography>{orderModal.paymentTax}</Typography>
                <Typography>{JPYmoney(taxes.tax)}</Typography>
              </Box>
              <Divider />
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                width={"100%"}
                mb={1}
                mt={1}
              >
                <Typography>{orderModal.total}</Typography>
                <Typography>{JPYmoney(taxes.total)}</Typography>
              </Box>
              <Divider />

              {quantitySelectedToBuy?.paymentMethod === "pix" && (
                <>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    width={"100%"}
                    mb={1}
                    mt={1}
                  >
                    <Typography>BRL</Typography>
                    <Typography>{taxes.brl}</Typography>
                  </Box>
                  <Divider />
                </>
              )}
            </Box>
          </Paper>
        </Box>
      </Box>
    </FadeInContainer>
  );

  const makeTreasureProductOrder = () => {
    treasureProductOrderBuy({
      boughtPrice: taxes.unitPrice,
      files: filesOrder,
      paymentMethod: quantitySelectedToBuy.paymentMethod,
      productID: product?.id,
      quantity: quantitySelectedToBuy.quantity,
      suiteID: user?.suite[0]?.id,
    });
  };

  return (
    <Box width={isMobile ? "100%" : isSmalldesktop ? "100%" : "100%"}>
      {isProductLoading ? (
        <CircularProgress />
      ) : (
        <>
          {activeStep === 1 && (
            <FadeInContainer>
              {product?.id ? (
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                  width={"100%"}
                >
                  {isAdmin && (
                    <Button
                      fullWidth
                      variant="contained"
                      onClick={() => setOpenEdit(true)}
                      sx={{ mb: 3 }}
                    >
                      Editar produto
                    </Button>
                  )}
                  <Box
                    display={"flex"}
                    flexWrap={"wrap"}
                    justifyContent={"center"}
                    gap={3}
                    width={"100%"}
                  >
                    <Box width={isSmalldesktop ? "100%" : "auto"}>
                      <img
                        src={`${product?.treasureProductsImages[imageIndex]?.code}`}
                        width={maxWidth}
                        height={350}
                        alt="figure"
                        style={{
                          maxHeight: 350,
                          objectFit: "contain",
                          borderRadius: 5,
                          background: grey[200],
                          marginBottom: 8,
                        }}
                      />

                      <Box
                        display={"flex"}
                        maxWidth={maxWidth}
                        sx={{ overflowX: "auto" }}
                        gap={1}
                      >
                        {product?.treasureProductsImages?.map(
                          (image, index) => (
                            <img
                              key={index}
                              style={{
                                width: 100,
                                height: 100,
                                objectFit: "contain",
                                cursor: "pointer",
                                borderRadius: 5,
                                background: grey[200],
                                marginBottom: 6,
                              }}
                              src={image?.code}
                              alt={image?.code}
                              onMouseOver={() => {
                                setImageIndex(index);
                              }}
                              onClick={() => setImageIndex(index)}
                            />
                          )
                        )}
                      </Box>
                    </Box>

                    <Paper
                      elevation={2}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        p: 3,
                        height: "fit-content",
                        margin: isSmalldesktop ? "10px 0" : "0",
                        width: isSmalldesktop ? "80%" : "58%",
                      }}
                    >
                      <Box>
                        <Box display={"flex"} gap={1}>
                          <Chip
                            label={
                              product?.itemsQuantity <= 0
                                ? "Out of stock"
                                : "In stock"
                            }
                            color={"default"}
                            sx={{ mt: 1 }}
                          />
                        </Box>

                        <Typography
                          fontSize={isSmalldesktop ? 18 : 28}
                          color={grey[600]}
                          style={{ wordBreak: "break-word" }}
                        >
                          {product?.name}
                        </Typography>
                        <Typography sx={{ mt: 2 }} color={grey[600]}>
                          {product?.origin}
                        </Typography>
                        <Typography sx={{ mt: 2 }} color={grey[600]}>
                          {product?.type}
                        </Typography>

                        <Typography
                          style={{
                            wordBreak: "break-word",
                            fontSize: isSmalldesktop ? 30 : 40,
                          }}
                          mt={1}
                          color={amber[900]}
                        >
                          {JPYmoney(product?.price)}
                        </Typography>

                        <Typography>Stock: {product?.itemsQuantity}</Typography>
                        {product?.itemsQuantity > 0 ? (
                          <Box display={"flex"} gap={2} mt={3}>
                            <FormControl sx={{ width: 80 }}>
                              <InputLabel id="demo-simple-select-label">
                                Quantity
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedQTD}
                                label="Quantity"
                                defaultValue={1}
                                defaultChecked
                                onChange={(e) =>
                                  setSelectedQTD(Number(e.target.value))
                                }
                              >
                                {Array.from(
                                  {
                                    length: product?.itemsQuantity,
                                  },
                                  (_v, i) => i
                                ).map((qtd, index) => {
                                  const removeZero = qtd + 1;
                                  return (
                                    <MenuItem value={removeZero} key={index}>
                                      {removeZero}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                            <Button
                              variant="contained"
                              fullWidth={false}
                              style={{
                                width: 300,
                                fontSize: isSmalldesktop ? 18 : 20,
                              }}
                              onClick={() => {
                                setActiveStep(2);
                                setQuantitySelectedToBuy((old) => ({
                                  ...old,
                                  quantity: selectedQTD,
                                }));
                              }}
                              disabled={isAdmin || product?.itemsQuantity <= 0}
                            >
                              <ShoppingCartIcon sx={{ mr: 1 }} /> Comprar
                            </Button>
                          </Box>
                        ) : (
                          <Typography fontSize={20} mt={1} color={grey[500]}>
                            Sorry, all of our stock was sold.
                          </Typography>
                        )}
                      </Box>
                    </Paper>
                  </Box>

                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    width={"100%"}
                  >
                    <Paper
                      elevation={3}
                      sx={{
                        p: isSmalldesktop ? 3 : 5,
                        mt: isSmalldesktop ? 0 : 5,
                        mb: 5,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignContent: "center",
                        gap: 3,
                        width: "80%",
                      }}
                    >
                      <DataText
                        title={"Origin/Anime:"}
                        text={product?.origin}
                        textProps={{
                          width: "100%",
                          sx: { wordBreak: "break-all" },
                        }}
                      />
                      <Divider sx={{ mt: -2 }} />
                      <DataText
                        title={"Type:"}
                        text={product?.type}
                        textProps={{
                          width: "100%",
                          sx: { wordBreak: "break-all" },
                        }}
                      />
                      <Divider sx={{ mt: -2 }} />

                      <DataText
                        html={product?.description}
                        title={"Description:"}
                        text={product?.description}
                        textProps={{
                          width: "100%",
                          sx: { wordBreak: "break-all" },
                          overflow: "inherit",
                          maxHeight: "100%",
                        }}
                        titleProps={{ mr: 2 }}
                      />
                      <Divider sx={{ mt: -2 }} />
                    </Paper>
                  </Box>
                </Box>
              ) : (
                <NoData text="No product found" />
              )}
            </FadeInContainer>
          )}

          {activeStep === 2 && <ResumeOrder payment />}

          {activeStep === 3 &&
            quantitySelectedToBuy.paymentMethod === "card" && (
              <PaymentModalCreditCard
                isEmbed
                open={payModalOpen}
                setOpen={() => setPayModalOpen(false)}
                itensInfo={{
                  itemID: product?.id,
                  totalValue: taxes.unitPrice,
                  type: "treasureProductOrder",
                  quantity: quantitySelectedToBuy.quantity,
                  productID: product.id,
                }}
              />
            )}

          {activeStep === 3 &&
            quantitySelectedToBuy.paymentMethod !== "card" && (
              <Box
                sx={{
                  mb: 5,

                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <ResumeOrder payment={false} />
                <Typography variant="h1" textAlign={"center"} mb={2} mt={2}>
                  Payment file:
                </Typography>
                <DropFileInput
                  onFileChange={(files) => setFilesOrder(files)}
                  oldFiles={[]}
                />
                <Button
                  variant="contained"
                  sx={{ mt: 2 }}
                  disabled={filesOrder.length === 0}
                  onClick={() => makeTreasureProductOrder()}
                >
                  Send payment
                </Button>
              </Box>
            )}
        </>
      )}
    </Box>
  );
}
