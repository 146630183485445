import {
  Box,
  Container,
  Typography,
  Button,
  Divider,
  Paper,
} from "@mui/material";
import merryBright from "../../../../../assets/images/merry_bright.png";
import { amber, blue, cyan, grey, red, teal } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
import { useBreakpoints } from "../../../../../hooks/useBreakpoins";
import { BasicCard } from "../../../Molecules/BasicCard";
import { ShippingFeeSimulator } from "../../ShippingFeeSimulator";
import { FadeInContainer, TitleDivider } from "../../../Atoms";
import actionFigurePNG from "../../../../../assets/images/actionfigure.png";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ApartmentIcon from "@mui/icons-material/Apartment";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import Diversity1OutlinedIcon from "@mui/icons-material/Diversity1Outlined";
import { colors } from "../../../../../theme/colors";
import { Slider2 } from "./Slider2";

import { Slider2Products } from "./Slider2Products";
import { useEffect, useState } from "react";

export function Home() {
  const nav = useNavigate();
  const { isSmalldesktop } = useBreakpoints();
  const [isVisible, setIsVisible] = useState({
    weCare1: false,
    itsEasy: false,
  });

  const elevation = 5;
  const padding = 3;
  const typographycss = {
    width: 180,
    wordBreak: "break-word",
    textAlign: "center",
    mt: 2,
    height: 200,
    fontSize: 18,
  };
  const titlesize = isSmalldesktop ? 20 : 25;

  useEffect(() => {
    // Criar o IntersectionObserver
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;

        // Verificar se o elemento está visível na tela
        if (entry.isIntersecting) {
          if (entry.target.id === "weCare1") {
            setIsVisible((old) => ({ ...old, weCare1: true })); // O elemento entrou na tela
          }
          if (entry.target.id === "itsEasy") {
            setIsVisible((old) => ({ ...old, itsEasy: true })); // O elemento entrou na tela
          }
        }
      },
      {
        threshold: 0.5, // Quando 50% do elemento estiver visível, ele é considerado visível
      }
    );

    // Pega o elemento pelo ID
    const elementCare = document.getElementById("weCare1");
    const elementEasy = document.getElementById("itsEasy");

    if (elementCare) {
      // Iniciar a observação do elemento
      observer.observe(elementCare);
    }
    if (elementEasy) {
      // Iniciar a observação do elemento
      observer.observe(elementEasy);
    }

    // Limpeza do observer quando o componente for desmontado
    return () => {
      if (elementCare) {
        observer.unobserve(elementCare);
      }
      if (elementEasy) {
        observer.unobserve(elementEasy);
      }
    };
  }, []);

  return (
    <FadeInContainer>
      <Container maxWidth="lg" sx={{ overflow: "hidden" }}>
        <Container
          maxWidth="lg"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mt: 5,
            mb: isSmalldesktop ? -8 : -15,
            zIndex: 1515418518,
          }}
        >
          <Typography
            sx={{
              color: "black",
              fontSize: titlesize,
              textAlign: "center",
            }}
          >
            From Japan directly to your address
          </Typography>
          <Typography
            sx={{
              color: "black",
              fontSize: titlesize,
              textAlign: "center",
              zIndex: 488748748,
            }}
          >
            Without leaving home!
          </Typography>
          <Typography
            sx={{
              color: "black",
              fontSize: isSmalldesktop ? 19 : 25,
              textAlign: "center",
              zIndex: 488748748,
            }}
          >
            Ease and comfort in your hands
          </Typography>
          <Typography
            sx={{
              color: "black",
              fontSize: isSmalldesktop ? 19 : 25,
              textAlign: "center",
              zIndex: 488748748,
            }}
          >
            We ship worldwide!
          </Typography>
          <Button
            variant={"contained"}
            sx={{
              width: 300,
              height: 60,

              background: amber[500],
              "&:hover": { background: amber[200] },
              mt: 3,
              fontSize: 20,
            }}
            onClick={() => nav("/login/new-user")}
          >
            Create your account
          </Button>
          <TitleDivider fontSize={30} title="WHY USE OUR SERVICES?" />
          <img
            src={merryBright}
            alt="boxPhoto"
            width={"100%"}
            height={"100%"}
            style={{ overflowY: "hidden", objectFit: "cover", zIndex: 1 }}
          />
        </Container>

        <Container maxWidth="lg">
          <Container
            maxWidth="lg"
            sx={{ background: colors.blue[900], borderRadius: 5 }}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 1,
                justifyContent: "center",
                mt: isSmalldesktop ? 10 : 20,
              }}
            >
              {/* <Box width={isSmalldesktop ? "100%" : "48%"}>
            <Box width={"100%"} mb={2}>
              <Typography
                fontSize={titlesize}
                fontWeight={500}
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                How to buy on our site
              </Typography>
              <Divider sx={{ mt: 2 }} />
            </Box>
            <Box width={"100%"} display={"flex"} justifyContent={"center"}>
              <iframe
                width="100%"
                height="500px"
                src="https://www.youtube.com/embed/ZHIp9JDfrMM?si=a6dlFaJmsuZLoH1s"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              />
            </Box>
          </Box>

          <Box width={isSmalldesktop ? "100%" : "48%"} mb={5}>
            <Box width={"100%"} mb={2}>
              <Typography
                fontSize={titlesize}
                fontWeight={500}
                textAlign={"center"}
                alignSelf={"center"}
              >
                How to ask for shipping?
              </Typography>
              <Divider sx={{ mt: 2 }} />
            </Box>
            <Box width={"100%"} display={"flex"} justifyContent={"center"}>
              <iframe
                width="100%"
                height="500px"
                src="https://www.youtube.com/embed/PNxrk-j6QB8?si=pGj5EdCBCaE9ZH8j"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              />
            </Box>
          </Box>

          <Box width={isSmalldesktop ? "100%" : "48%"} mb={5}>
            <Box width={"100%"} mb={2}>
              <Typography
                fontSize={titlesize}
                fontWeight={500}
                textAlign={"center"}
                alignSelf={"center"}
              >
                How does order redirection works?
              </Typography>
              <Divider sx={{ mt: 2 }} />
            </Box>
            <Box width={"100%"} display={"flex"} justifyContent={"center"}>
              <iframe
                width="100%"
                height="500px"
                src="https://www.youtube.com/embed/Cj_CXB5DIiA?si=VkvQf7p3SkrXL5HS"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              />
            </Box>
          </Box> */}

              <TitleDivider title="It's easy" titleColor="#FFFFFF" />

              <Box
                sx={{
                  display: "flex",
                  mb: 2,
                  gap: 2,
                  flexWrap: "wrap",
                  justifyContent: "center",
                }}
              >
                <Paper
                  elevation={elevation}
                  sx={{
                    p: padding,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    transition: "transform 2s ease-out",
                    opacity: isVisible.itsEasy ? 1 : 0,
                    transform: isVisible.itsEasy
                      ? "translateX(0%)"
                      : "translateX(-40%)",
                  }}
                  id="itsEasy"
                >
                  <Box
                    height={80}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <img src={actionFigurePNG} alt="action figure" width={80} />
                  </Box>
                  <Typography sx={typographycss}>
                    Choose a product from any website in japan you want, you can
                    see the most wanted ones in the menu "BEST STORES TO BUY"
                  </Typography>
                </Paper>

                <Paper
                  elevation={elevation}
                  sx={{
                    p: padding,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    transition: "transform 2s ease-out",
                    opacity: isVisible.itsEasy ? 1 : 0,
                    transform: isVisible.itsEasy
                      ? "translateY(0%)"
                      : "translateY(-40%)",
                  }}
                >
                  <Box
                    height={80}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <LocalGroceryStoreIcon fontSize="large" />
                  </Box>
                  <Typography sx={typographycss}>
                    Place an order in our system and we will buy for you! <br />
                    When the product arrives in our stock, you can ship it to
                    your address.
                  </Typography>
                </Paper>

                <Paper
                  elevation={elevation}
                  sx={{
                    p: padding,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    transition: "transform 2s ease-out",
                    opacity: isVisible.itsEasy ? 1 : 0,
                    transform: isVisible.itsEasy
                      ? "translateX(0%)"
                      : "translateX(40%)",
                  }}
                >
                  <Box
                    height={80}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <CardGiftcardIcon fontSize="large" />
                  </Box>
                  <Typography sx={typographycss}>
                    Your product is stored in your private inventory (suite) and
                    you can combine all products in one delivery.
                  </Typography>
                </Paper>

                <Paper
                  elevation={elevation}
                  sx={{
                    p: padding,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    transition: "transform 2s ease-out",
                    opacity: isVisible.itsEasy ? 1 : 0,
                    transform: isVisible.itsEasy
                      ? "translateY(0%)"
                      : "translateY(40%)",
                  }}
                >
                  <Box
                    height={80}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <AirplanemodeActiveIcon fontSize="large" />
                  </Box>
                  <Typography sx={typographycss}>
                    We will make a very secure package and your product will
                    arrive in perfect condition at the delivery address!
                  </Typography>
                </Paper>
              </Box>

              <TitleDivider
                title="Best prices and service"
                titleColor="#FFFFFF"
              />

              <Box display={"flex"} gap={1} flexWrap={"wrap"} mb={5}>
                <BasicCard
                  icon={"Assignment"}
                  text={"Register and buy from any online website"}
                  background={grey[100]}
                  cardWidth={isSmalldesktop ? "100%" : 200}
                  iconColor={blue[900]}
                />
                <BasicCard
                  icon={"LocalShipping"}
                  text={"Have your purchase delivered to our address"}
                  background={grey[100]}
                  cardWidth={isSmalldesktop ? "100%" : 200}
                  iconColor={teal[500]}
                />
                <BasicCard
                  icon={"CardGiftcard"}
                  text={"We consolidate and store your purchase at no cost"}
                  background={grey[100]}
                  cardWidth={isSmalldesktop ? "100%" : 200}
                  iconColor={red[500]}
                />
                <BasicCard
                  icon={"FlightTakeoff"}
                  text={"We ship to your address at the lowest prices"}
                  background={grey[100]}
                  cardWidth={isSmalldesktop ? "100%" : 200}
                  iconColor={cyan[300]}
                />
              </Box>
            </Box>
          </Container>

          <TitleDivider title="We buy from any place!" />
          <Slider2 />

          <TitleDivider title="Or buy from our stock!" />

          <Slider2Products />

          <Container maxWidth="lg" sx={{ mt: 5 }}>
            <Box
              display={"flex"}
              gap={1}
              flexWrap={"wrap"}
              mt={20}
              mb={20}
              justifyContent={"center"}
              alignItems={"center"}
              flexDirection={"column"}
            >
              <Typography sx={{ fontWeight: 500, fontSize: 30 }}>
                Start buying from Japan now!
              </Typography>
              <Typography variant="h1">
                Enjoy the convenience of home delivery.
              </Typography>
              <Button
                variant={"contained"}
                sx={{
                  width: 280,
                  height: 60,

                  background: amber[500],
                  "&:hover": { background: amber[200] },
                  mt: 3,
                  fontSize: 20,
                }}
                onClick={() => nav("/login/new-user")}
              >
                Start now
              </Button>
            </Box>
          </Container>

          <Container
            maxWidth="lg"
            sx={{ background: colors.blue[900], borderRadius: 5 }}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 1,
                pb: 5,
                justifyContent: "center",
                mt: isSmalldesktop ? 10 : 20,
              }}
            >
              <TitleDivider title="We care" titleColor="#FFFFFF" />

              <Box
                sx={{
                  display: "flex",
                  mb: 2,
                  gap: 3,
                  flexWrap: "wrap",
                  justifyContent: "center",
                }}
              >
                <Paper
                  elevation={elevation}
                  sx={{
                    p: padding,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    transition: "2s ease-in-out",
                    opacity: isVisible.weCare1 ? 1 : 0,
                  }}
                  // className={`message ${isVisible ? "fade-in" : ""}`}
                  id="weCare1"
                >
                  <Box
                    height={80}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <FavoriteBorderIcon fontSize="large" />
                  </Box>
                  <Typography sx={typographycss}>
                    Why choose us? <br />
                    We have the logistics in place to ensure your purchase is a
                    great experience
                  </Typography>
                </Paper>

                <Paper
                  elevation={elevation}
                  sx={{
                    p: padding,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    transition: "3s ease-in-out",
                    opacity: isVisible.weCare1 ? 1 : 0,
                  }}
                >
                  <Box
                    height={80}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <ApartmentIcon fontSize="large" />
                  </Box>
                  <Typography sx={typographycss}>
                    We have been in the market for years and have shipped over
                    100,000 products
                  </Typography>
                </Paper>

                <Paper
                  elevation={elevation}
                  sx={{
                    p: padding,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    transition: "4s ease-in-out",
                    opacity: isVisible.weCare1 ? 1 : 0,
                  }}
                >
                  <Box
                    height={80}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <SupportAgentOutlinedIcon fontSize="large" />
                  </Box>
                  <Typography sx={typographycss}>
                    We are here to provide all the support you need. Each order
                    comes with a chat where you can ask questions or make
                    requests
                  </Typography>
                </Paper>

                <Paper
                  elevation={elevation}
                  sx={{
                    p: padding,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    transition: "transform 2s ease-out",
                    opacity: isVisible.weCare1 ? 1 : 0,
                    transform: isVisible.weCare1
                      ? "translateY(0)"
                      : "translateY(100%)",
                  }}
                >
                  <Box
                    height={80}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Diversity1OutlinedIcon fontSize="large" />
                  </Box>
                  <Typography sx={typographycss}>
                    Our team is here to ensure customer satisfaction and the
                    best shopping experience in Japan
                  </Typography>
                </Paper>
              </Box>
            </Box>
          </Container>

          <Container maxWidth="lg" sx={{ mt: 5 }}>
            <Box
              display={"flex"}
              gap={1}
              flexWrap={"wrap"}
              mt={20}
              mb={20}
              justifyContent={"center"}
              alignItems={"center"}
              flexDirection={"column"}
            >
              <Typography sx={{ fontWeight: 500, fontSize: 30 }}>
                We ship worldwide!
              </Typography>
              <Typography variant="h1">
                Any country, any city, any place! help you!
              </Typography>
              <Typography variant="h1">
                Come to us, we’re happy to help you!
              </Typography>
              <Button
                variant={"contained"}
                sx={{
                  width: 280,
                  height: 60,

                  background: amber[500],
                  "&:hover": { background: amber[200] },
                  mt: 3,
                  fontSize: 20,
                }}
                onClick={() => nav("/login/new-user")}
              >
                Start now
              </Button>
            </Box>
          </Container>
        </Container>
      </Container>
      <Box sx={{ background: grey[200], width: "100%", pb: 5 }}>
        <Container maxWidth="lg">
          <Box width={"100%"} mt={5} mb={5}>
            <Typography
              fontSize={40}
              fontWeight={500}
              textAlign={"center"}
              pt={3}
            >
              Price Simulation
            </Typography>
            <Divider sx={{ mt: 2, bgcolor: "white" }} />
          </Box>

          <ShippingFeeSimulator />
        </Container>
      </Box>

      {/* <Box sx={{ background: grey[900], width: "100%", pb: 5 }}></Box> */}
    </FadeInContainer>
  );
}
