import { api } from "./api";

export const createTreasureProductService = async (
  data: CreateTreasureProduct
) => {
  const { name, description, kg, itemsQuantity, type, origin, file, price } =
    data;

  try {
    await api.post(
      "/create-treasure-product",
      {
        name,
        description,
        kg,
        itemsQuantity,
        type,
        origin,
        file,
        price,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  } catch (err: any) {
    throw new Error(err);
  }
};

export const updateTreasureProductService = async (
  data: UpdateTreasureProduct
) => {
  const {
    name,
    description,
    kg,
    itemsQuantity,
    productID,
    origin,
    type,
    price,
  } = data;

  const response2 = await api.post("/update-treasure-product", {
    data: {
      name,
      description,
      kg,
      itemsQuantity,
      productID,
      origin,
      type,
      price,
    },
  });
  return response2.data;
};

export const getTreasureProductsListService = async (filter: {
  key: string;
  searchTerm: string;
  itensPerPage: number;
  offset: number;
  userID: number;
}) => {
  const response2 = await api.get("/find-treasure-products", {
    params: {
      searchKey: filter.key,
      searchTerm: filter.searchTerm,
      itensPerPage: filter.itensPerPage,
      offset: filter.offset,
      userID: filter.userID,
    },
  });
  return response2.data;
};

export const getTreasureProductsListHomeService = async () => {
  const response2 = await api.get("/find-treasure-products-home");
  return response2.data;
};

export const getOneTreasureProductService = async (productID: number) => {
  const response2 = await api.get("/find-one-treasure-product", {
    params: {
      productID,
    },
  });
  return response2.data;
};

export const updateTreasureProductImages = async (data: any) => {
  const { productID, file } = data;

  try {
    await api.post(
      "/update-treasure-product-images",
      {
        productID,
        file,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  } catch (err: any) {
    throw new Error(err);
  }
};

export const deleteTreasureProductImage = async (id: number) => {
  try {
    await api.delete("/delete-treasure-product-image", {
      params: { imageID: id },
    });
  } catch (err: any) {
    throw new Error(err);
  }
};
