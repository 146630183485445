import { Box, Container, Divider, Link } from "@mui/material";
import { FadeInContainer, TitleDivider } from "../../../Atoms";
import yamadaIMG from "../../../../../assets/images/stores/yamada.svg";
import hobbylinkIMG from "../../../../../assets/images/stores/hljLogo.png";
import sanrioIMG from "../../../../../assets/images/stores/sanrio.png";
import toysrusIMG from "../../../../../assets/images/stores/toys4us.png";
import ecapcomIMG from "../../../../../assets/images/stores/ecapcom.png";
import mandarakeIMG from "../../../../../assets/images/stores/Mandarake_logo.png";
import bandaiIMG from "../../../../../assets/images/stores/bandaipremium.png";
import amazonIMG from "../../../../../assets/images/stores/amazon.png";
import amiamiIMG from "../../../../../assets/images/stores/amiami.png";
import mercariIMG from "../../../../../assets/images/stores/mercari.png";
import charaaniIMG from "../../../../../assets/images/stores/charaani.png";
import surugayaIMG from "../../../../../assets/images/stores/surugaya.svg";
import pokemoncenterIMG from "../../../../../assets/images/stores/pokemoncenter.png";
import rakutenIMG from "../../../../../assets/images/stores/rakuten.png";
import takaratomyIMG from "../../../../../assets/images/stores/takaratomy.png";
import yahooauctionIMG from "../../../../../assets/images/stores/yahooauction.png";
import yahooshoppingIMG from "../../../../../assets/images/stores/yahooshopping.png";
import animateIMG from "../../../../../assets/images/stores/animate.png";
import melonbooks from "../../../../../assets/images/stores/melonbooks.png";
import { useNavigate } from "react-router-dom";

export const makeOrderStores = [
  {
    label: "Mercari",
    file: mercariIMG,
    link: "https://jp.mercari.com/",
  },
  {
    label: "Yahoo Auctions",
    file: yahooauctionIMG,
    link: "https://auctions.yahoo.co.jp/",
  },
  {
    label: "AmiAmi",
    file: amiamiIMG,
    link: "https://www.amiami.com/eng/",
  },
];
export const imagesStores = [
  {
    label: "Amazon JP",
    file: amazonIMG,
    link: "https://www.amazon.co.jp/",
  },
  {
    label: "Yahoo Shopping",
    file: yahooshoppingIMG,
    link: "https://www.yahoo.co.jp/",
  },
  {
    label: "MelonBooks",
    file: melonbooks,
    link: "https://www.melonbooks.co.jp/",
  },
  {
    label: "Hobby Link",
    file: hobbylinkIMG,
    link: "https://www.hlj.com/",
  },
  {
    label: "Mandarake",
    file: mandarakeIMG,
    link: "https://www.mandarake.co.jp/index2.html",
  },
  {
    label: "Pokemon Center",
    file: pokemoncenterIMG,
    link: "https://www.pokemoncenter-online.com/",
  },
  {
    label: "Surugaya",
    file: surugayaIMG,
    link: "https://www.suruga-ya.jp/",
  },
  {
    label: "Rakuten",
    file: rakutenIMG,
    link: "https://www.rakuten.co.jp/",
  },
  {
    label: "Bandai Premium",
    file: bandaiIMG,
    link: "https://p-bandai.jp/",
  },
  {
    label: "e-Capcom",
    file: ecapcomIMG,
    link: "https://www.e-capcom.com/",
  },
  {
    label: "Toys R us",
    file: toysrusIMG,
    link: "https://www.toysrus.co.jp/",
  },
  {
    label: "Sanrio",
    file: sanrioIMG,
    link: "https://shop.sanrio.co.jp/",
  },

  {
    label: "Yamada",
    file: yamadaIMG,
    link: "https://www.yamada-denkiweb.com/",
  },
  {
    label: "Charaani",
    file: charaaniIMG,
    link: "https://www.chara-ani.com/",
  },
  {
    label: "Takaratomy",
    file: takaratomyIMG,
    link: "https://www.takaratomy.co.jp/",
  },
  {
    label: "Animate",
    file: animateIMG,
    link: "https://www.animate-onlineshop.jp/",
  },
];
export function BestStoreToBuy() {
  const nav = useNavigate();
  return (
    <Container maxWidth={"lg"}>
      <FadeInContainer>
        <TitleDivider title="Best stores to buy" />

        <TitleDivider title="Personal Shopper" fontSize={20} dividerW="40%" />
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          flexWrap={"wrap"}
          gap={10}
          mb={5}
        >
          {makeOrderStores.map((image) => (
            <a href={image.link} target="_blank" rel="noreferrer">
              <img src={image.file} alt={image.label} width={200} />
            </a>
          ))}
        </Box>

        <TitleDivider title="Use our address" fontSize={20} dividerW="40%" />
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          flexWrap={"wrap"}
          gap={10}
          mb={5}
        >
          {imagesStores.map((image) => (
            <a href={image.link} target="_blank" rel="noreferrer">
              <img src={image.file} alt={image.label} width={200} />
            </a>
          ))}
        </Box>
        <Box
          display={"flex"}
          width={"100%"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          mb={5}
        >
          <Divider sx={{ width: "80%", mb: 5 }} />
          <Link
            component="button"
            variant="body2"
            underline="none"
            onClick={() => {
              nav("/login/new-user");
            }}
            fontSize={25}
          >
            Sign up, it's free!
          </Link>
        </Box>
      </FadeInContainer>
    </Container>
  );
}
