import {
  Box,
  Button,
  IconButton,
  InputBase,
  Pagination,
  Paper,
} from "@mui/material";
import { FadeInContainer } from "../../../components/AtomicDesign/Atoms";
import useTreasureProducts from "../../../hooks/context/useTreasureProducts";
import SearchIcon from "@mui/icons-material/Search";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import "./styles.css";
import { TreasureProductCards } from "./components/TreasureProductCards";
import { CustomModal } from "../../../components/AtomicDesign/Organisms";
import { useState } from "react";
import { TreasureProductDetails } from "./components/TreasureProductDetails";
import { NewTreasureProductBox } from "./components/NewTreasureProductBox";
import useAuth from "../../../hooks/context/useAuth";
import { EditTreasureProductBox } from "./components/EditTreasureProductBox";

export function TreasureProducts() {
  const {
    treasureProductsDataMemo,
    setHandleIDProduct,
    treasureProduct,
    loading,
    records,
    getFilterStorage,
    setFilters,
    setAttData,
    submitTreasureProduct,
    updateTreasureProduct,
    treasureProductRemoveImage,
    createTreasureProductOrderByFiles,
    setTreasureProduct,
  } = useTreasureProducts();
  const { isAdmin } = useAuth();
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [search, setSearch] = useState("");

  const handleKeyPress = (event: any) => {
    // Previne o comportamento de submit ao pressionar Enter
    if (event.key === "Enter") {
      event.preventDefault();
      setFilters("searchTerm", search);
      setAttData((bool: boolean) => !bool);
    }
  };

  return (
    <FadeInContainer>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          mb: 5,
        }}
      >
        <Paper
          component="form"
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: "90%",
            mt: 2,
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1, width: "90%" }}
            placeholder="Search"
            onChange={(e) => {
              e.preventDefault();
              setSearch(e.target.value);
            }}
            onKeyDown={handleKeyPress}
            value={search}
          />
          {search && (
            <IconButton
              type="button"
              sx={{ p: "10px" }}
              aria-label="search"
              onClick={() => {
                setSearch("");
                setFilters("searchTerm", "");
                setAttData((bool: boolean) => !bool);
                setPage(1);
              }}
              color="error"
            >
              <HighlightOffIcon />
            </IconButton>
          )}
          <IconButton
            type="button"
            sx={{ p: "10px" }}
            aria-label="search"
            onClick={() => {
              setFilters("searchTerm", search);
              setAttData((bool: boolean) => !bool);
              setPage(1);
            }}
            color="primary"
          >
            <SearchIcon />
          </IconButton>
        </Paper>

        {isAdmin && (
          <Button
            variant="contained"
            onClick={() => setOpenCreate(true)}
            sx={{ mb: 3, mt: 3 }}
          >
            + New Product
          </Button>
        )}
        <Pagination
          count={Math.ceil(records / getFilterStorage().itensPerPage)}
          page={page}
          color="primary"
          defaultPage={1}
          onChange={(_e, page) => {
            setPage(page);
            setFilters("offset", page - 1);
            setAttData((bool: boolean) => !bool);
          }}
          sx={{ mt: isAdmin ? 0 : 3 }}
        />

        <Box
          sx={{
            mt: 2,
            mb: 5,
            display: "flex",
            flexWrap: "wrap",
            gap: 2,
            justifyContent: "center",
          }}
        >
          <TreasureProductCards
            handleClickProduct={(productID) => {
              setHandleIDProduct(productID);
              setOpen(true);
            }}
            treasureProductsDataMemo={treasureProductsDataMemo}
            isLoading={loading.productsLoading}
          />
        </Box>
        <Pagination
          count={Math.ceil(records / getFilterStorage().itensPerPage)}
          page={page}
          defaultPage={1}
          color="primary"
          onChange={(_e, page) => {
            setPage(page);
            setFilters("offset", page - 1);
            setAttData((bool: boolean) => !bool);
          }}
        />
      </Box>
      <CustomModal
        openModal={open}
        setOpenModal={(val) => {
          setOpen(val);
          setTreasureProduct({});
          setHandleIDProduct(null);
        }}
        width={"90%"}
      >
        <TreasureProductDetails
          isProductLoading={loading.oneOrder}
          product={treasureProduct}
          setOpenEdit={(val) => {
            setOpenEdit(val);
          }}
          treasureProductOrderBuy={createTreasureProductOrderByFiles}
          loadingBuy={loading.submitOrder}
        />
      </CustomModal>
      <CustomModal
        openModal={openCreate}
        setOpenModal={setOpenCreate}
        width={"90%"}
      >
        <NewTreasureProductBox
          handleSubmit={submitTreasureProduct}
          submitLoading={loading.submitProduct}
        />
      </CustomModal>
      <CustomModal
        openModal={openEdit}
        setOpenModal={setOpenEdit}
        width={"90%"}
      >
        <EditTreasureProductBox
          handleSubmit={updateTreasureProduct}
          submitLoading={loading.submitProduct}
          product={treasureProduct}
          removeFileAdmin={treasureProductRemoveImage}
          removeFileLoading={loading.uploadLoading}
        />
      </CustomModal>
    </FadeInContainer>
  );
}
