import { Box, Divider, Typography } from "@mui/material";

export function TitleDivider({
  title,
  fontSize = 40,
  dividerW = "80%",
  titleColor = "black",
}: {
  title: string;
  fontSize?: number;
  dividerW?: string;
  titleColor?: string;
}) {
  return (
    <Box
      width={"100%"}
      mt={3}
      mb={5}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
    >
      <Typography
        fontSize={fontSize}
        fontWeight={500}
        textAlign={"center"}
        pt={3}
        zIndex={458545848}
        color={titleColor}
      >
        {title}
      </Typography>
      <Divider
        sx={{ mt: 2, bgcolor: "white", width: dividerW, zIndex: 41584848 }}
      />
    </Box>
  );
}
