import step1 from "../../../../assets/images/aumentarLance/step1.png";
import step2 from "../../../../assets/images/aumentarLance/step2.png";
import step3 from "../../../../assets/images/aumentarLance/step3.png";
import step4 from "../../../../assets/images/aumentarLance/step4.png";
import step5 from "../../../../assets/images/aumentarLance/step5.png";
import step6 from "../../../../assets/images/aumentarLance/step6.png";
import step7 from "../../../../assets/images/aumentarLance/step7.png";
import celular from "../../../../assets/images/menina_com_celular.png";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { useEffect, useState } from "react";
import { FadeInContainer } from "../../../AtomicDesign/Atoms";
import { Box, IconButton, Typography } from "@mui/material";
import { blue, grey } from "@mui/material/colors";
import useAuth from "../../../../hooks/context/useAuth";
import { useBreakpoints } from "../../../../hooks/useBreakpoins";

export function HelpOrder() {
  const [activeIndex, setActiveIndex] = useState(0);
  const { language } = useAuth();
  const { isSmalldesktop } = useBreakpoints();
  const texts = [
    {
      image: celular,
      text: {
        portuguese:
          "Agora é possível aumentar seu lance no leilão, nos próximos passos vou te mostrar como fazer isso!",
        english:
          "Now it is possible to increase your bid in the auction, in the next steps I will show you how to do this!",
      },
      index: 1,
    },
    {
      image: step1,
      text: {
        portuguese:
          "Clique em nova ordem para abrir o menu de tipos de pedido.",
        english: "Click on new order to open the order menu.",
      },
      index: 2,
    },
    {
      image: step2,
      text: {
        portuguese: "Clique em Leilão e crie o pedido normalmente.",
        english: "Click on Auction and create the order as usual.",
      },
      index: 3,
    },
    {
      image: step3,
      text: {
        portuguese:
          "Após a criação do leilão, agora temos uma sessão dedicada aos lances, e ao clicar em 'ATUALIZAR MEU LANCE', um pop up vai se abrir.",
        english:
          "After creating the auction, we now have a session dedicated to bids, and when clicking on 'UPDATE MY BID', a pop up will open.",
      },
      index: 4,
    },
    {
      image: step4,
      text: {
        portuguese:
          "Nesta opção, você vai poder aumentar o seu lance da forma que desejar, lembrando que o lance não pode ser menor que o atual.",
        english:
          "In this option, you will be able to increase your bid as you wish, remembering that the bid cannot be lower than the current one.",
      },
      index: 5,
    },
    {
      image: step5,
      text: {
        portuguese:
          "Após decidir qual será o novo valor, o botão de confirmação vai iniciar uma contagem para ter certeza que o novo valor é o desejado, no final da contagem, você poderá clicar.",
        english:
          "After deciding what the new value will be, the confirmation button will start a countdown to make sure the new value is the one you want, at the end of the countdown you will be able to click.",
      },
      index: 6,
    },
    {
      image: step6,
      text: {
        portuguese:
          "Então o pedido vai ficar aguardando nossa equipe atualizar o lance na plataforma de leilão escolhida, basta aguardar nossa equipe atualiza-lo e você também pode clicar no botão histórico para ver todos os lances que você solicitou.",
        english:
          "Then the order will be waiting for our team to update the bid on the chosen auction platform, just wait for our team to update it and you can also click the history button to see all the bids you have requested.",
      },
      index: 7,
    },
    {
      image: step7,
      text: {
        portuguese:
          "Após clicar no botão histórico, você terá as informações dos lances que você solicitou o aumento.",
        english:
          "After clicking on the history button, you will have information about the bids for which you requested an increase.",
      },
      index: 8,
    },
    {
      image: celular,
      text: {
        portuguese:
          "Qualquer dúvida entre em contato, estamos aqui para ajudar, obrigado!",
        english:
          "If you have any questions, please contact us, we are here to help, thank you!",
      },
      index: 9,
    },
  ];

  useEffect(() => {
    const listener = (e: any) => {
      e = e || window.event;

      if (e.keyCode === 37) {
        setActiveIndex((old) => {
          if (old > 0) {
            return old - 1;
          } else {
            return old;
          }
        });
      } else if (e.keyCode === 39) {
        const getLast = texts[texts.length - 1].index;

        setActiveIndex((old) => {
          if (old + 1 < getLast) {
            return old + 1;
          } else {
            return old;
          }
        });
      }
    };

    window.addEventListener("keydown", listener);
    return () => window.removeEventListener("keydown", listener);
    // eslint-disable-next-line
  }, []);

  return (
    <FadeInContainer>
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={1}
        width={"100%"}
        pt={5}
        pb={5}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={1}
          justifyContent={"center"}
          alignItems={"center"}
          height={isSmalldesktop ? 300 : 490}
        >
          <img
            src={texts[activeIndex].image}
            style={{ maxWidth: isSmalldesktop ? 300 : 600 }}
            alt="example"
          />
          <Typography textAlign={"center"} fontSize={18} color={grey[700]}>
            {texts[activeIndex].text[language]}
          </Typography>
        </Box>

        <Box
          display={"flex"}
          gap={1}
          width={"100%"}
          justifyContent={"center"}
          alignItems={"center"}
          mt={10}
        >
          <PlayArrowIcon
            sx={{ rotate: "180deg", cursor: "pointer", fontSize: 30 }}
            onClick={() => {
              if (activeIndex > 0) {
                setActiveIndex((old) => old - 1);
              }
            }}
          />
          {Array.from({ length: texts.length }).map((_item, index) => (
            <IconButton
              size="small"
              sx={{
                color: activeIndex === index ? blue[300] : "",
                width: 20,
                height: 20,
              }}
              onClick={() => setActiveIndex(index)}
            >
              <FiberManualRecordIcon />
            </IconButton>
          ))}
          <PlayArrowIcon
            sx={{ cursor: "pointer", fontSize: 30 }}
            onClick={() => {
              if (activeIndex < texts.length - 1) {
                setActiveIndex((old) => old + 1);
              }
            }}
          />
        </Box>
      </Box>
    </FadeInContainer>
  );
}
