import { createContext, useContext, useEffect, useMemo, useState } from "react";
import useAuth from "./useAuth";
import { useLocalStorage } from "../useLocalStorage";
import useToast from "./useToast";
import { useTranslate } from "../useTranslate";
import { useLocation, useNavigate } from "react-router-dom";

import {
  createTreasureProductService,
  deleteTreasureProductImage,
  getOneTreasureProductService,
  getTreasureProductsListService,
  updateTreasureProductImages,
  updateTreasureProductService,
} from "../../services/treasureProducts";
import {
  createTreasureProductOrderByFilesService,
  getOneTreasureProductOrderService,
  getTreasureProductsOrdersService,
  updateTreasureProductOrder,
  verifyPayTreasureProductOrderService,
} from "../../services/treasureProductsOrder";

const TreasureProductsContext = createContext(
  {} as TreasureProductProviderProps
);

type Props = {
  children: React.ReactNode;
};

function TreasureProductsProvider({ children }: Props) {
  const { pathname } = useLocation();
  const nav = useNavigate();
  const { getFilterStorage, setFilters } = useLocalStorage();
  const { language, user } = useAuth();
  const { setOpen, setToastData } = useToast();
  const { strings } = useTranslate();
  const [treasureProductsData, setTreasureProductsData] = useState<
    TreasureProducts[]
  >([]);
  const [treasureProduct, setTreasureProduct] = useState(
    {} as TreasureProducts
  );
  const [handleIDProduct, setHandleIDProduct] = useState<number | null>();
  const [handleIDProductOrder, setHandleIDProductOrder] = useState<
    number | null
  >();
  const [records, setRecords] = useState(0);
  const [attData, setAttData] = useState(false);
  const [loading, setLoading] = useState({
    uploadLoading: false,
    usersLoading: false,
    productsLoading: false,
    submitProduct: false,
    oneOrder: false,
    ordersLoading: false,
    submitOrder: false,
  });

  // orderns
  const [orderList, setOrderList] = useState<TreasureProductOrder[]>([]);
  const [treasureProductOrder, setTreasureProductOrder] = useState(
    {} as TreasureProductOrder
  );
  const [treasureProductsOrderRecords, setTreasureProductsOrderRecords] =
    useState(0);
  const [menuTreasureProductOrdersCount, setMenuTreasureProductOrdersCount] =
    useState(0);
  const treasureProductsDataMemo = useMemo(
    () => treasureProductsData,
    [treasureProductsData]
  );

  const treasureProductsOrdersDataMemo = useMemo(() => orderList, [orderList]);

  const getTreasureProductsList = async (
    cleanFilter?: boolean,
    noLoading?: boolean
  ) => {
    if (!noLoading) {
      setLoading((old) => ({ ...old, productsLoading: true }));
    }

    try {
      const filt = getFilterStorage(cleanFilter);
      const response = await getTreasureProductsListService({
        ...filt,
        userID: user?.user?.id,
      });
      setTreasureProductsData(response.data);
      setRecords(response.count);
    } catch {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "error",
        message: strings.toastMessages.genericError[language],
      });
    } finally {
      setLoading((old) => ({ ...old, productsLoading: false }));
    }
  };

  const getOneTreasureProduct = async (loading?: boolean) => {
    if (loading) {
      setLoading((old) => ({ ...old, oneOrder: true }));
    }
    try {
      if (handleIDProduct) {
        const response = await getOneTreasureProductService(handleIDProduct);
        setTreasureProduct(response);
      }
    } catch {
    } finally {
      setLoading((old) => ({ ...old, oneOrder: false }));
    }
  };

  const submitTreasureProduct = async (info: any) => {
    setLoading((old) => ({ ...old, submitProduct: true }));
    try {
      await createTreasureProductService(info.data);
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "success",
        message: strings.toastMessages.orderCreated[language],
      });
      setOpen(true);
      return true;
    } catch {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "error",
        message: strings.toastMessages.genericError[language],
      });
      setOpen(true);
      return false;
    } finally {
      if (user?.user?.id) {
        getTreasureProductsList(false, true);
      }
      setLoading((old) => ({ ...old, submitProduct: false }));
    }
  };

  const updateTreasureProduct = async (info: any) => {
    setLoading((old) => ({ ...old, submitProduct: true }));
    try {
      await updateTreasureProductService(info.data);
      if (info?.data?.file?.length > 0) {
        await updateTreasureProductImages(info.data);
      }
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "success",
        message: strings.toastMessages.orderCreated[language],
      });
      setOpen(true);
    } catch {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "error",
        message: strings.toastMessages.genericError[language],
      });
      setOpen(true);
    } finally {
      if (user?.user?.id) {
        getTreasureProductsList(false, true);
      }
      getOneTreasureProduct();
      setLoading((old) => ({ ...old, submitProduct: false }));
    }
  };

  const treasureProductRemoveImage = async (imageID: number) => {
    setLoading((old) => ({ ...old, uploadLoading: true }));
    try {
      await deleteTreasureProductImage(imageID);
    } catch {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "error",
        message: strings.toastMessages.genericError[language],
      });
      setOpen(true);
    } finally {
      getOneTreasureProduct();
      setLoading((old) => ({ ...old, uploadLoading: false }));
    }
  };

  // ordens

  const getTreasureProductsOrdersList = async (
    cleanFilter?: boolean,
    noLoading?: boolean
  ) => {
    if (!noLoading) {
      setLoading((old) => ({ ...old, ordersLoading: true }));
    }

    try {
      const filt = getFilterStorage(cleanFilter);
      const response = await getTreasureProductsOrdersService({
        ...filt,
        userID: user?.user?.id,
      });
      setOrderList(response.data);
      setTreasureProductsOrderRecords(response.count);
      setMenuTreasureProductOrdersCount(response.activeServices);
    } catch {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "error",
        message: strings.toastMessages.genericError[language],
      });
    } finally {
      setLoading((old) => ({ ...old, ordersLoading: false }));
    }
  };

  const getOneTreasureProductOrder = async (loading?: boolean) => {
    if (loading) {
      setLoading((old) => ({ ...old, oneOrder: true }));
    }
    try {
      if (handleIDProductOrder) {
        const response = await getOneTreasureProductOrderService(
          handleIDProductOrder
        );
        setTreasureProductOrder(response);
      }
    } catch {
    } finally {
      setLoading((old) => ({ ...old, oneOrder: false }));
    }
  };

  const updateOrderStatus = async (data: UpdateTreasureProductOrder) => {
    setLoading((old) => ({ ...old, submitProduct: true }));
    try {
      await updateTreasureProductOrder({
        status: data.status,
        treasureProductOrderID: data?.treasureProductOrderID,
      });
    } catch (err: any) {
    } finally {
      getTreasureProductsOrdersList(false, true);
      getOneTreasureProductOrder();
      setLoading((old) => ({ ...old, submitProduct: false }));
    }
  };

  const createTreasureProductOrderByFiles = async (
    data: CreateTreasureProductOrderByFiles
  ) => {
    setLoading((old) => ({ ...old, submitOrder: true }));
    try {
      await createTreasureProductOrderByFilesService(data);
      nav("/treasurebox-app/treasure-product-orders");
    } catch {
    } finally {
      setLoading((old) => ({ ...old, submitOrder: false }));
    }
  };

  const verifyPayTreasureProductOrder = async (
    data: ConfirmPayTreasureProductOrder
  ) => {
    try {
      await verifyPayTreasureProductOrderService(data);
    } catch {
    } finally {
      getTreasureProductsOrdersList(false, true);
      getOneTreasureProductOrder();
    }
  };

  const cleanFilters = () => {
    getFilterStorage(true);
    if (
      [
        "/treasurebox-app/admin/treasure-store",
        "/treasurebox-app/treasure-store",
      ].includes(pathname)
    ) {
      getTreasureProductsList(true);
    }
    if (
      [
        "/treasurebox-app/admin/treasure-product-orders",
        "/treasurebox-app/treasure-product-orders",
      ].includes(pathname)
    ) {
      getTreasureProductsOrdersList(true);
    }
  };

  const productPathns = [
    "/treasurebox-app/admin/treasure-store",
    "/treasurebox-app/treasure-store",
  ].includes(pathname);

  const adminProducts = [
    "/treasurebox-app/admin/treasure-product-orders",
    "/treasurebox-app/treasure-product-orders",
  ].includes(pathname);

  useEffect(() => {
    if (productPathns) {
      getOneTreasureProduct(true);
    }
    if (adminProducts) {
      getOneTreasureProductOrder(true);
    }
    // eslint-disable-next-line
  }, [handleIDProduct, handleIDProductOrder]);

  useEffect(() => {
    if (productPathns && user?.user?.id) {
      if (getFilterStorage().searchTerm !== "") {
        getTreasureProductsList();
      } else {
        getTreasureProductsList(false, true);
      }
    }
    if (adminProducts && user?.user?.id) {
      if (getFilterStorage().searchTerm !== "") {
        const interval = setInterval(() => {
          getTreasureProductsOrdersList();
        }, 60000);

        return () => clearInterval(interval);
      } else {
        const interval = setInterval(() => {
          getTreasureProductsOrdersList(false, true);
        }, 60000);
        getTreasureProductsOrdersList(false, true);
        return () => clearInterval(interval);
      }
    }

    // eslint-disable-next-line
  }, [attData, pathname, user?.user?.id]);

  useEffect(() => {
    if (user?.user?.id) {
      getTreasureProductsOrdersList();
    }
    // eslint-disable-next-line
  }, [user?.user?.id]);

  return (
    <TreasureProductsContext.Provider
      value={{
        setFilters,
        cleanFilters,
        treasureProduct,
        treasureProductsDataMemo,
        treasureProductsOrdersDataMemo,
        treasureProductsOrderRecords,
        records,
        loading,
        handleIDProduct,
        handleIDProductOrder,
        treasureProductOrder,
        menuTreasureProductOrdersCount,
        setHandleIDProductOrder,
        setHandleIDProduct,
        getOneTreasureProduct,
        submitTreasureProduct,
        setTreasureProduct,
        setAttData,
        getFilterStorage,
        updateTreasureProduct,
        treasureProductRemoveImage,
        getTreasureProductsOrdersList,
        updateOrderStatus,
        createTreasureProductOrderByFiles,
        verifyPayTreasureProductOrder,
      }}
    >
      {children}
    </TreasureProductsContext.Provider>
  );
}

export { TreasureProductsProvider, TreasureProductsContext };

export default function useTreasureProducts() {
  return useContext(TreasureProductsContext);
}
