import "./styles.css"; // Se você estiver usando o mesmo CSS
import { imagesStores, makeOrderStores } from "../../BestStoreToBuy";

const images = [...makeOrderStores, ...imagesStores].map((item) => ({
  imgSrc: item.file,
  title: item.label,
}));

export function Slider2() {
  return (
    <div className="logos">
      <div className="logos-slide">
        {[...images, ...images].map(({ imgSrc }) => (
          <img src={imgSrc} alt="" width={150} />
        ))}
      </div>
    </div>
  );
}
