import { Box, Paper } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import useAuth from "../../../hooks/context/useAuth";
import { colors } from "../../../theme/colors";
import { useEffect, useState } from "react";
import { useBreakpoints } from "../../../hooks/useBreakpoins";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { AlertBox } from "../../AtomicDesign/Molecules";
import NewOrderEnglish from "../../../assets/images/NOVIDADELEILAOEN.png";
import NewOrderPT from "../../../assets/images/LEILAONOVOPT.png";
import { CustomModal } from "../../AtomicDesign/Organisms";
import { HelpOrder } from "../AppLayout/HelpOrder";

export function AdminLayout() {
  const navigate = useNavigate();
  const route = useLocation();
  const { getFilterStorage } = useLocalStorage();
  const { token, getUserLocalStorage, getUser, language } = useAuth();
  const { user } = getUserLocalStorage();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { isSmalldesktop } = useBreakpoints();
  const [modalOpen, setModalOpen] = useState(false);

  const maintenance = process.env.REACT_APP_MAINTENANCE === "ON";

  if (maintenance) {
    navigate("/maintenance");
  }

  if (!user || !token || user?.user?.role !== "admin") {
    navigate("/");
  }

  const getUserAtt = async () => {
    getUser(user?.user?.id);
  };

  useEffect(() => {
    getFilterStorage(true);

    // eslint-disable-next-line
  }, [route]);

  useEffect(() => {
    getUserAtt();

    // eslint-disable-next-line
  }, []);

  const imageOrder = {
    english: NewOrderEnglish,
    portuguese: NewOrderPT,
  };

  const titleModalText = {
    portuguese: "Aumentar lance do leilão",
    english: "Increase auction bid",
  };

  return (
    <Box
      bgcolor={colors.gray[100]}
      sx={{ overflowX: "hidden" }}
      height={"100vh"}
    >
      <Paper elevation={2} sx={{ borderRadius: 0 }}>
        <Header drawerStatus={setDrawerOpen} />
      </Paper>
      <Box
        display={"flex"}
        gap={1}
        width={"100%"}
        mb={-5}
        flexWrap={isSmalldesktop ? "wrap" : "nowrap"}
      >
        <img
          onClick={() => setModalOpen(true)}
          src={imageOrder[language]}
          alt="new order logo"
          width={isSmalldesktop ? 180 : 250}
          height={isSmalldesktop ? 150 : 180}
          style={{
            marginLeft: drawerOpen ? "250px" : "0",
            marginTop: 5,
            marginBottom: -40,
            cursor: "pointer",
          }}
        />
        <AlertBox />
      </Box>
      <Box
        mt={5}
        marginLeft={drawerOpen && !isSmalldesktop ? "240px" : "0"}
        width={"100%"}
        display={"flex"}
        justifyContent={drawerOpen ? "center" : "flex-start"}
      >
        <Box
          maxWidth={"1920px"}
          width={"100%"}
          marginRight={drawerOpen && !isSmalldesktop ? "240px" : "0"}
        >
          <Outlet />
        </Box>
      </Box>
      <CustomModal
        openModal={modalOpen}
        setOpenModal={setModalOpen}
        width={isSmalldesktop ? "90%" : "60%"}
        title={titleModalText[language]}
      >
        <HelpOrder />
      </CustomModal>
    </Box>
  );
}
