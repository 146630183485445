import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CircularLoading } from "../../components/AtomicDesign/Atoms";
import { verifyOrderToBuyPayment } from "../../services/orderToBuyService";
import { PaymentSuccess } from "./PaymentSuccess";
import { Container, Typography } from "@mui/material";
import { verifyShippmentPayment } from "../../services/shippmentsService";
import { updatePaymentCreditsService } from "../../services/creditsService";
import { updatePaymentTreasureProductOrder } from "../../services/treasureProductsOrder";

export function PaymentConclusion() {
  const nav = useNavigate();
  const [searchParams] = useSearchParams();
  const payID = searchParams.get("session_id");
  const orderID = searchParams.get("orderID");

  const shippmentID = searchParams.get("shippmentID");
  const treasureProductOrderID = searchParams.get("treasureProductOrderID");
  const creditID = searchParams.get("creditID");
  const [payStatus, setPayStatus] = useState(false);

  const [loading, setLoading] = useState(false);
  const [seconds, setSeconds] = useState(15);
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  useEffect(() => {
    let interval: any = undefined;
    if (paymentSuccess) {
      interval = setInterval(
        () =>
          setSeconds((old) => {
            if (seconds <= 0) return 0;
            else return old - 1;
          }),
        1000
      );
      if (seconds === 0) {
        if (orderID) {
          nav("/treasurebox-app/register-order");
        }
        if (shippmentID) {
          nav("/treasurebox-app/shippments");
          // setSeconds(15);
        }
        if (creditID) {
          nav("/treasurebox-app/credits");
        }
        if (treasureProductOrderID) {
          nav("/treasurebox-app/treasure-product-orders");
        }
      }
    } else {
      setSeconds(15);
    }

    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [paymentSuccess, seconds]);

  const getPayInfo = async () => {
    setLoading(true);
    try {
      if (orderID) {
        const response = await verifyOrderToBuyPayment(
          payID ?? "",
          Number(orderID) ?? 0
        );
        setPayStatus(response.payStatus === "paid" ? true : false);
        setPaymentSuccess(true);
      }
      if (shippmentID) {
        const response = await verifyShippmentPayment(
          payID ?? "",
          Number(shippmentID) ?? 0
        );
        setPayStatus(response.payStatus === "paid" ? true : false);
        setPaymentSuccess(true);
      }

      if (creditID) {
        const response = await updatePaymentCreditsService({
          creditID: Number(creditID),
          sessionid: payID ?? "",
        });
        setPayStatus(response.payStatus === "paid" ? true : false);
        setPaymentSuccess(true);
      }

      if (treasureProductOrderID) {
        const response = await updatePaymentTreasureProductOrder({
          treasureProductOrderID: Number(treasureProductOrderID),
          sessionid: payID ?? "",
        });
        setPayStatus(response.payStatus === "paid" ? true : false);
        setPaymentSuccess(true);
      }
    } catch {
      setPaymentSuccess(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (payID) {
      getPayInfo();
    }
    // eslint-disable-next-line
  }, [payID]);

  return loading ? (
    <Container
      maxWidth="lg"
      sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Typography sx={{ fontSize: 30 }}>
        Processing payment, please wait
      </Typography>
      <CircularLoading />
    </Container>
  ) : (
    <PaymentSuccess seconds={seconds} isPaid={payStatus} />
  );
}
