import { api } from "./api";

export const createTreasureProductOrderCard = async (
  data: ChargeCardTreasureProductOrder
) => {
  const response2 = await api.post("/create-order-treasure-product-card", {
    ...data,
  });
  return response2.data;
};

export const createTreasureProductOrderByFilesService = async (
  data: CreateTreasureProductOrderByFiles
) => {
  const { boughtPrice, files, paymentMethod, productID, quantity, suiteID } =
    data;

  try {
    await api.post(
      "/create-order-treasure-product-card-files",
      {
        boughtPrice,
        file: files,
        paymentMethod,
        productID,
        quantity,
        suiteID,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  } catch (err: any) {
    throw new Error(err);
  }
};

export const updatePaymentTreasureProductOrder = async (
  data: ConfirmPayTreasureProductOrder
) => {
  const response2 = await api.post("/update-treasure-product-order-payment", {
    ...data,
  });
  return response2.data;
};

export const getTreasureProductsOrdersService = async (filter: {
  key: string;
  searchTerm: string;
  itensPerPage: number;
  offset: number;
  userID: number;
}) => {
  const response2 = await api.get("/find-treasure-products-orders", {
    params: {
      searchKey: filter.key,
      searchTerm: filter.searchTerm,
      itensPerPage: filter.itensPerPage,
      offset: filter.offset,
      userID: filter.userID,
    },
  });
  return response2.data;
};

export const getOneTreasureProductOrderService = async (
  treasureProductOrderID: number
) => {
  const response2 = await api.get("/find-one-treasure-product-order", {
    params: {
      treasureProductOrderID,
    },
  });
  return response2.data;
};

export const updateTreasureProductOrder = async (
  data: UpdateTreasureProductOrder
) => {
  const response2 = await api.post("/update-treasure-product-order", {
    ...data,
  });
  return response2.data;
};

export const calcTaxTreasureProductOrder = async (
  data: CalcTreasureProductOrderTax
) => {
  const response2 = await api.post("/calctax-order-treasure-product", {
    ...data,
  });
  return response2.data;
};

export const verifyPayTreasureProductOrderService = async (
  data: ConfirmPayTreasureProductOrder
) => {
  const response2 = await api.post("/verify-pay-order-treasure-product", {
    ...data,
  });
  return response2.data;
};
