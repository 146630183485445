export function useTranslate() {
  const strings = {
    drawerMenu: {
      dashboard: {
        portuguese: "Painel",
        english: "Dashboard",
      },
      myInfo: {
        portuguese: "Meu cadastro",
        english: "My info",
      },
      shippments: {
        portuguese: "Envios",
        english: "Shippments",
      },
      store: {
        portuguese: "Loja integrada",
        english: "Store",
      },
      treasureProductOrders: {
        portuguese: "Pedidos da loja",
        english: "Store orders",
      },
      treasureProductOrdersClient: {
        portuguese: "Meus pedidos",
        english: "My orders",
      },
      createProduct: {
        portuguese: "Novo produto",
        english: "New product",
      },
      order: {
        portuguese: "Pedidos",
        english: "Orders",
      },
      auctions: {
        portuguese: "Leilões",
        english: "Auction",
      },
      credits: {
        portuguese: "Créditos",
        english: "Credits",
      },
      systemConfig: {
        portuguese: "Config do sistema",
        english: "System config",
      },
      freteConfig: {
        portuguese: "Fretes",
        english: "Shipping",
      },
      alertsConfig: {
        portuguese: "Alertas",
        english: "Alerts",
      },
      suite: {
        portuguese: "Suite",
        english: "Suite",
      },
      users: {
        portuguese: "Usuários",
        english: "Users",
      },
      products: {
        portuguese: "Produtos",
        english: "Products",
      },
    },
    buttons: {
      newSolicitation: {
        portuguese: "Nova solicitação",
        english: "New request",
      },
      deleteShip: {
        portuguese: "Deletar entrega",
        english: "Delete shippment",
      },
      update: {
        portuguese: "Atualizar",
        english: "Update",
      },
      editShip: {
        portuguese: "Solicitar edição da entrega",
        english: "Request delivery edit",
      },
      shipped: {
        portuguese: "Entrega enviada",
        english: "Shipped",
      },
      addPrds: {
        portuguese: "Adicionar produtos",
        english: "Add products",
      },
      turnBack: {
        portuguese: "Voltar",
        english: "Back",
      },
      newProduct: {
        portuguese: "Novo produto",
        english: "New product",
      },
      newShippment: {
        portuguese: "Nova entrega",
        english: "New shippment",
      },
      confirm: {
        portuguese: "Confirmar",
        english: "Confirm",
      },
      cancel: {
        portuguese: "Cancelar",
        english: "Cancel",
      },
      signInbtn: {
        portuguese: "Entrar",
        english: "Log in",
      },
      credited: {
        portuguese: "Creditar",
        english: "Credit",
      },
      cancelBtn: {
        portuguese: "Cancelar",
        english: "Cancel",
      },
      verifyBtn: {
        portuguese: "Verificar",
        english: "Verify",
      },
      changePassword: {
        portuguese: "Trocar senha",
        english: "Change password",
      },
      createLogin: {
        portuguese: "Cadastre-se",
        english: "Register",
      },
      newServiceBox: {
        portuguese: "Novo pedido",
        english: "New order",
      },
      newService: {
        portuguese: "Pedido de compra",
        english: "Order request",
      },
      removeFromPackage: {
        portuguese: "Remover da embalagem",
        english: "Remove from package",
      },
      takeNewPhotos: {
        portuguese: "Tirar novas fotos",
        english: "Take new photos",
      },
      askProduct: {
        portuguese: "Perguntar em um produto",
        english: "Ask in product",
      },
      bid: {
        portuguese: "Ofertar valor em um produto",
        english: "Offer value in a product",
      },
      auction: {
        portuguese: "Leilão",
        english: "Auction",
      },
      edit: {
        portuguese: "Editar",
        english: "Edit",
      },
      deleteUer: {
        portuguese: "Deletar usuário",
        english: "Delete user",
      },
      open: {
        portuguese: "Abrir",
        english: "Open",
      },
      waitingPayment: {
        portuguese: "Aguardando pagamento",
        english: "Waiting payment",
      },
      orderDone: {
        portuguese: "Ordem comprada",
        english: "ORDER DONE",
      },
      cancelOrder: {
        portuguese: "Cancelar ordem",
        english: "Cancel order",
      },
      cancelOrderWithRefund: {
        portuguese: "Cancelar e creditar na conta",
        english: "Cancel and refund credits",
      },
      create: {
        portuguese: "Criar",
        english: "Create",
      },
    },
    validationFields: {
      email: {
        portuguese: "O E-mail precisa ser válido",
        english: "Must be a valid e-mail",
        required: {
          portuguese: "Por favor, insira seu email",
          english: "Please, type your e-mail",
        },
      },
      access: {
        required: {
          portuguese: "Selecione",
          english: "Select",
        },
      },
      money: {
        required: {
          portuguese: "Digite um valor ou coloque 0",
          english: "Type a value or 0",
        },
      },
      role: {
        required: {
          portuguese: "Selecione o tipo do acesso",
          english: "Select access type",
        },
      },
      password: {
        portuguese: "Por favor, insira sua senha",
        english: "Please, type your password",
      },
      age: {
        required: {
          portuguese: "Insira sua idade",
          english: "Type your age",
        },
      },
      addressNumber: {
        portuguese: "Informe o número",
        english: "Type the number",
      },
      addressComplement: {
        portuguese: "Informe o bairro do endereço",
        english: "Type address neighborhood",
      },
      city: {
        portuguese: "Informe a cidade",
        english: "Type the city",
      },
      state: {
        portuguese: "Informe o estado",
        english: "Type the state",
      },
      zipCode: {
        portuguese: "Informe o CEP",
        english: "Type the postal code",
      },
      country: {
        portuguese: "Informe o país",
        english: "Type the country",
      },
      name: {
        required: {
          portuguese: "Insira seu nome",
          english: "Type your name",
        },
      },
      ssn: {
        portuguese: "Insira seu documento",
        english: "Type your document",
      },
      phoneCode: {
        required: {
          portuguese: "Insira seu telefone",
          english: "Type your phone",
        },
        typeError: {
          portuguese: "Apenas números",
          english: "Only numbers",
        },
      },
      title: {
        portuguese: "Insira um título",
        english: "Write a title",
      },
      price: {
        portuguese: "Insira um preço",
        english: "Write a price",
      },
      kg: {
        portuguese: "Insira o peso",
        english: "Write the weight",
      },
      itemQtd: {
        portuguese: "Insira a quantidade",
        english: "Type quantity",
      },
      suite: {
        portuguese: "Selecione a suite",
        english: "Select suite",
      },
      date: {
        portuguese: "Selecione uma data",
        english: "Select date",
      },
      file: {
        portuguese: "Insira pelo menos uma foto",
        english: "Insert photos",
      },
      payChoice: {
        portuguese: "Selecione o metodo de pagamento",
        english: "Select you pay choice",
      },
      termsConditions: {
        portuguese: "Você precisa aceitar os termos e condições",
        english: "You need to accept our terms and conditions",
      },
    },
    login: {
      haveAccount: {
        portuguese: "Já tem uma conta?",
        english: "Do you have an account?",
      },
      verifyCredentials: {
        portuguese: "Verifique suas credenciais",
        english: "Verify your credentials",
      },

      forgotPassword: {
        portuguese: "Esqueceu sua senha?",
        english: "Forgot your password?",
      },
      noAccount: {
        portuguese: "Não tem uma conta?",
        english: "Don't have an account?",
      },
      registerFree: {
        portuguese: "Cadastre-se grátis",
        english: "Register for free",
      },
    },
    createUser: {
      mailNotification: {
        portuguese: "Receber notificações por e-mail",
        english: "Receive notifications by email",
      },
      age: {
        portuguese: "Idade",
        english: "Age",
      },
      money: {
        portuguese: "Créditos",
        english: "Money",
      },
      namePlaceholder: {
        portuguese: "Nome",
        english: "Name",
      },
      phonePlaceholder: {
        portuguese: "Telefone",
        english: "Phone",
      },
      ssnPlaceholder: {
        portuguese: "CPF",
        english: "SSN",
      },
      emailPlaceholder: {
        portuguese: "E-mail",
        english: "E-mail",
      },
      passwordPlaceholder: {
        portuguese: "Senha",
        english: "Password",
      },
      registerBtn: {
        portuguese: "Cadastre-se",
        english: "Sign in",
      },
      ssn: {
        portuguese: "CPF",
        english: "SSN",
      },
    },
    forgotPassword: {
      emailStep: {
        forgotPassword: {
          portuguese: "Solicite uma nova senha pelo seu E-mail.",
          english: "Request a new password with your E-mail",
        },
        askCode: {
          portuguese: "Solicitar código",
          english: "Request code",
        },
      },
      codeStep: {
        code: {
          portuguese:
            "Por favor, informe o código enviado ao e-mail informado.",
          english: "Please, type the code sent to the email provided.",
        },
      },
      changePasswordStep: {
        registerNewPassword: {
          portuguese: "Por favor, cadastre sua nova senha.",
          english: "Please, register your new password",
        },
      },
    },
    myAddress: {
      title: {
        portuguese: "Meus endereços",
        english: "My addresses",
      },
      labels: {
        address: {
          portuguese: "Endereço:",
          english: "Address:",
        },
        Neighborhood: {
          portuguese: "Bairro:",
          english: "Neighborhood:",
        },
        complementInCard: {
          portuguese: "Complemento:",
          english: "Complement:",
        },
        complement: {
          portuguese: "Complemento - apto/bloco/ponto de referência",
          english: "Complement - apto/block/reference point",
        },
        city: {
          portuguese: "Cidade:",
          english: "City:",
        },
        country: {
          portuguese: "País:",
          english: "Country:",
        },
        state: {
          portuguese: "Estado:",
          english: "State:",
        },
        zipCode: {
          portuguese: "CEP:",
          english: "Zip code:",
        },
        receiver: {
          portuguese: "Destinatário:",
          english: "Receiver:",
        },
        ssn: {
          portuguese: "CPF:",
          english: "SSN:",
        },
        number: {
          portuguese: "Número:",
          english: "Number:",
        },
        addressComplement: {
          portuguese: "Bairro",
          english: "Neighborhood",
        },
      },
    },
    toastMessages: {
      packageError: {
        portuguese: "Você não tem créditos para remover a embalagem",
        english: "You don't have credits for removing the packaging",
      },
      welcome: {
        portuguese: "Seja bem vindo!",
        english: "Welcome!",
      },
      loginError: {
        portuguese: "Não foi possível logar, verifique suas credenciais",
        english: "Unable to log in, check your credentials",
      },
      userBlocked: {
        portuguese: "seu usuário está bloqueado, contate a loja",
        english: "Your user was blocked, contact the store",
      },
      welcomeNew: {
        portuguese: "Seja bem vindo!, login criado com sucesso",
        english: "Welcome, login created successfully",
      },
      recoverPassword: {
        portuguese: "E-mail enviado, verifique sua caixa de entrada e spam",
        english: "E-mail sent, verify your inbox and spam",
      },
      verifyEmail: {
        portuguese: "Verifique o e-mail",
        english: "Verify e-mail",
      },
      emailAlreadyExists: {
        portuguese: "Este e-mail já possui cadastro",
        english: "This e-mail already have an account",
      },
      confirmCode: {
        portuguese: "Código confirmado, por favor informe a nova senha",
        english: "Code confirmed, please type your new password",
      },
      errorCode: {
        portuguese: "Verifique o código",
        english: "Verify your code",
      },
      newPassword: {
        portuguese: "Senha alterada, efetue o login com a nova senha",
        english: "Password changed, log in with your new password",
      },
      errorNewPassword: {
        portuguese: "Ocorreu um erro ao alterar a senha, tente novamente",
        english: "An error has occured, try again",
      },
      errorAddress: {
        portuguese: "ocorreu um erro ao buscar endereços, tente novamente",
        english:
          "An error occurred while searching for addresses, please try again",
      },
      successCreateAddress: {
        portuguese: "Endereço criado com sucesso",
        english: "Address created successfully",
      },
      errorCreateAddress: {
        portuguese: "Erro ao criar endereço",
        english: "Error creating address",
      },
      successUpdateAddress: {
        portuguese: "Endereço editado com sucesso",
        english: "Address edited successfully",
      },
      errorUpdateAddress: {
        portuguese: "Erro ao editar endereço",
        english: "Error editing address",
      },
      editPersonalData: {
        portuguese: "Dados editados com sucesso",
        english: "Data edited successfully",
      },
      deleteUser: {
        portuguese: "Usuário deletado com sucesso",
        english: "User deleted successfully",
      },
      deleteUserError: {
        portuguese: "Não foi possível deletar o usuário",
        english: "Unable to delete user",
      },
      copy: {
        portuguese: "Copiado com sucesso",
        english: "Copy successfully",
      },
      copyError: {
        portuguese: "Erro ao copiar",
        english: "Error while i try to copy",
      },
      editErrorPersonalData: {
        portuguese: "Não foi possível editar os dados",
        english: "Unable to edit your information",
      },
      successEditFrete: {
        portuguese: "Frete editado com sucesso",
        english: "Shipping edited successfully",
      },
      errorEditFrete: {
        portuguese: "Não foi possível atualizar o frete",
        english: "An error occurred while editing shipping fee",
      },
      successEditTax: {
        portuguese: "Taxa atualizada com sucesso",
        english: "Tax updated successfully",
      },
      successDeleteTax: {
        portuguese: "Taxa deletada com sucesso",
        english: "Tax deleted successfully",
      },
      errorDeleteTax: {
        portuguese: "Ocorreu um erro ao deletar a taxa",
        english: "An error occurred while deleting tax",
      },
      errorTaxFrete: {
        portuguese: "Não foi possível atualizar a taxa",
        english: "An error occurred while editing tax",
      },
      successDeleteAddress: {
        portuguese: "Endereço deletado com sucesso",
        english: "Address deleted successfully",
      },
      errorDeleteAddress: {
        portuguese: "Erro ao deletar endereço",
        english: "Error deleting address",
      },
      proofPayment: {
        portuguese: "Comprovantes enviados! Aguarde a atualização do pedido",
        english:
          "Proof of payment sent successfully, wait for the order to update",
      },
      genericError: {
        portuguese: "Ocorreu um erro, tente novamente",
        english: "An error has ocurred, please try again",
      },
      fileMissing: {
        portuguese: "Envie o comprovante do pagamento",
        english: "Send proof of payment",
      },
      noStock: {
        portuguese: "Todos os itens deste produto já foram enviados",
        english: "All items for this product have already been shipped",
      },
      orderCreated: {
        portuguese: "Pedido atualizado com sucesso",
        english: "Order updated successfully",
      },
      alreadySolicited: {
        portuguese: "Esta ação já foi solicitada",
        english: "This action has already been requested",
      },
      deletePrd: {
        portuguese: "Produto deletado",
        english: "Product deleted",
      },
      deletePrdError: {
        portuguese: "Erro ao deletar produto deletado",
        english: "Error while i try to delete",
      },
    },
    myInfoPage: {
      personalData: {
        portuguese: "Dados pessoais",
        english: "Personal info",
      },
      editPersonalData: {},
      myAddress: {
        portuguese: "Meus endereços",
        english: "My addresses",
      },
      addressBTN: {
        portuguese: "+ Novo Endereço",
        english: "+ New Address",
      },
    },
    creditsPage: {
      title: {
        portuguese: "Créditos",
        english: "Credits",
      },
    },
    shippmentPage: {
      title: {
        portuguese: "Envios",
        english: "Shippments",
      },
      cards: {
        days: {
          portuguese: "dia",
          english: "day",
        },
        arrivedAt: {
          portuguese: "Criado em:",
          english: "Created at:",
        },
        daysInStock: {
          portuguese: "Armazenado",
          english: "Stored",
        },
        kg: {
          portuguese: "Kg:",
          english: "Kg:",
        },
        g: {
          portuguese: "g:",
          english: "g:",
        },
        shipped: {
          portuguese: "Enviado:",
          english: "Shipped:",
        },
        type: {
          portuguese: "Tipo",
          english: "Type",
        },
      },
      modal: {
        newShipTitleWarning: {
          portuguese: "Aviso sobre novos envios",
          english: "Notice about new shipments",
        },
        newShipWarning: {
          portuguese:
            "Após o envio mudar o status para 'Aguardando pagamento', você tem 48 horas para efetuar o pagamento, caso contrario, os produtos serão devolvidos para suite e pode haver uma taxa extra de 1,000 yenes para montagem de um novo envio, por isso, para evitar tais taxas adicionais, somente crie o envio quando desejar enviar seus produtos.",
          english:
            "After the shipment changes status to 'Waiting for payment', you have 48 hours to make the payment, otherwise, the products will be returned to suite and there may be an extra fee of 1,000 yen to assemble a new shipment, so to avoid such additional fees, only create the shipment when you want to send your products.",
        },
        shippment: {
          portuguese: "Envio",
          english: "Shippment",
        },
        deleteShip: {
          portuguese: "Deseja deletar esta entrega? Esta ação é irreversivel",
          english:
            "Do you want to delete this delivery? This action is irreversible",
        },
        editShip: {
          portuguese: "Deseja confirmar a edição?",
          english: "Do you want to confirm the edit?",
        },
        cancelShip: {
          portuguese: "Deseja cancelar esta entrega? Esta ação é irreversivel",
          english:
            "Do you want to cancel this delivery? This action is irreversible",
        },
        declaredValue: {
          portuguese: "Informe o valor a ser declarado para este envio",
          english: "Enter the value to be declared for this shippment",
        },
        declaredValueLabel: {
          portuguese: "Valor declarado",
          english: "Declared value",
        },
        notDefined: {
          portuguese: "Não definido",
          english: "Undefined",
        },
        jpyValue: { portuguese: "Valor em ¥", english: "Value in ¥" },
        editDelivery: {
          portuguese: "Deseja solicitar ao comprador que edite esta entrega?",
          english: "Do you want to ask the buyer to edit this delivery?",
        },
        confirmStatusWaitingPayment: {
          portuguese: 'Deseja mudar a entrega para "Aguardando pagamento" ?',
          english: 'Do you want to change the delivery to "Awaiting payment"?',
        },
        confirmStatusShipped: {
          portuguese:
            'Tem certeza que deseja marcar essa entrega como "Enviada" ?',
          english: 'Are you sure you want to mark this delivery as "Sent"?',
        },
        typeDiscount: {
          portuguese: "Digite o desconto",
          english: "Type discount",
        },
        penalty: {
          portuguese: "Multa",
          english: "Penalty",
        },
        leftInStock: {
          portuguese: "Ainda no estoque",
          english: "Left in stock",
        },
        kgWarming: {
          portuguese:
            'Aviso! Esta entrega não poderá ser enviada por "small packet" devido a ter um peso superior a 2kg, somente será aceito as demais modalidades',
          english:
            'Notice! This delivery cannot be sent by "small packet" due to its weight exceeding 2kg, only other types will be accepted',
        },
        track: {
          portuguese: "Rastrear",
          english: "Track order",
        },
        trackingNumber: {
          portuguese: "Rastreio",
          english: "Tracking number",
        },
        shippmentID: {
          portuguese: "Entrega número",
          english: "Shippment number",
        },
        popover: {
          portuguese: "Clique aqui e selecione os produtos",
          english: "Click here and select itens to ship",
        },
        selectedItems: {
          portuguese: "Produtos selecionados",
          english: "Items selected",
        },
        oldProducts: {
          portuguese: "Produtos já na entrega",
          english: "Items already in shippment",
        },
        quantity: {
          portuguese: "Quantidade",
          english: "Quantity",
        },
        plasticB: {
          portuguese: "Plastico bolha",
          english: "Bubble wrap",
        },
        securityFee: {
          portuguese: "Seguro do envio",
          english: "Shipping insurance",
        },
        dhlChoice: {
          portuguese:
            "Para a escolha DHL, precisamos fazer alguns cálculos primeiro, quando estiver concluído, mostraremos o preço para este método, se você quiser continuar com a DHL, crie esta remessa.",
          english:
            "For DHL choice, we need to make some calculations first, when it done, we will show you the price for this method, if you want to continue with DHL, please create this shippment.",
        },
        reinforcedBoxPrice: {
          portuguese: "Caixa reforçada",
          english: "Reinforced box",
        },
        defaultBoxWeight: {
          portuguese: "Peso da caixa de envio",
          english: "Shippment box weight",
        },
        itemsWeight: {
          portuguese: "Peso dos produtos",
          english: "Items weight",
        },
        totalWeight: {
          portuguese: "Peso total",
          english: "Total weight",
        },
        shipBoxEditText: {
          portuguese: "Digite o novo peso da caixa",
          english: "Write new box weight",
        },
        shipTotalPrice: {
          portuguese: "Total da entrega",
          english: "Shippment total",
        },
        remaingFee: {
          portuguese: "Frete restante",
          english: "Remaing fee",
        },
        stockDays: {
          func: (text: string | number) => {
            return {
              portuguese: `Total de dias no estoque após ${text} dias grátis`,
              english: `Total days in stock after ${text} free days`,
            };
          },
        },
        stockPrice: {
          portuguese: "Preço do armazenamento",
          english: "Stock price",
        },
        serviceTax: {
          portuguese: "Taxa de serviço",
          english: "Service tax",
        },
        taxRate: {
          portuguese: "Imposto de consumo - Japão",
          english: "Tax rate - Japan",
        },
        shippmentFee: {
          portuguese: "Preço do frete",
          english: "Shippment fee",
        },
        creditAlert: {
          portuguese: "Você poderá usar seus créditos na hora do pagamento",
          english: "You can use your credits at the time of payment",
        },
        shipChoice: {
          portuguese: "Escolha o tipo do envio",
          english: "Choose shippment type",
        },
        address: {
          portuguese: "Endereço",
          english: "Address",
        },
      },
    },
    address: {
      recipient: {
        portuguese: "Destinatário",
        english: "Recipient",
      },
      address: {
        portuguese: "Endereço",
        english: "Address",
      },
      city: {
        portuguese: "Cidade",
        english: "City",
      },
      state: {
        portuguese: "Estado",
        english: "State",
      },
      zipCode: {
        portuguese: "Cep",
        english: "Zip Code",
      },
      country: {
        portuguese: "Japão",
        english: "Japan",
      },
      store: {
        portuguese: "dia(s) de armazenamento gratuito.",
        english: "day(s) of free storage.",
      },
    },
    workHours: {
      work: {
        portuguese: "Horário de Funcionamento",
        english: "Work hours",
      },
      hours: {
        portuguese: "Seg a Seg 8:00 - 20:00 hs",
        english: "Seg a Seg 8:00 - 20:00 hs",
      },
      email: "asoimport@hotmail.com",
      tell: "09012381547",
    },
    systemUtility: {
      text1: {
        portuguese:
          "Utilize nosso endereço no Japão gerado pelo site para começar a fazer suas compras.",
        english:
          "Use our website-generated address in Japan to start making your purchases.",
      },
      text2: {
        portuguese:
          "Compras no site MERCARI devem ser feitas através do 'compra assistida' pois não é possivel comprar diretamente, idem ao site Yahoo Auctions.",
        english:
          "Purchases on the MERCARI website must be made through 'assisted purchasing' as it is not possible to buy directly, the same as on the Yahoo Auctions website.",
      },
      text3: {
        portuguese:
          "Sua suíte deve ser informada no campo ADDRESS das lojas. É de extrema importância que você informe o número da suíte para identificação do cliente.",
        english:
          "Your suite must be entered in the stores' ADDRESS field. It is extremely important that you provide the suite number to identify the customer.",
      },
      text4: {
        portuguese:
          "Em ONDE COMPRAR temos lojas para te ajudar a encontrar o que procura.",
        english:
          "In WHERE TO BUY we have stores to help you find what you are looking for.",
      },
      text5: {
        portuguese: "60 dias de armazenamento gratuito.",
        english: "60 days of free storage.",
      },
      text6: {
        portuguese:
          "Recebemos, tiramos foto e pesamos cada item recebido para cadastro em sua suíte (MINHA SUÍTE).",
        english:
          "We receive, take a photo and weigh each item received for registration in your suite (MY SUITE).",
      },
      text7: {
        portuguese:
          "Compre em quantas lojas quiser, nós consolidamos suas encomendas.",
        english:
          "Buy in as many stores as you want, we consolidate your orders.",
      },
      text8: {
        portuguese:
          "Solicite o envio quando quiser, selecionando os itens na aba Minha suíte e clique em 'Criar envio com itens selecionados'",
        english:
          "Request shippment whenever you want by selecting items in the My Suite tab and clicking 'Create shippment with selected items'",
      },
      text9: {
        portuguese:
          "Não tem cartão internacional? Solicite um orçamento conosco na opção COMPRA ASSISTIDA. Descreva ao máximo o produto.",
        english:
          "Don't have an international card? Request a quote from us using the ASSISTED PURCHASE option. Describe the product as much as possible.",
      },
      text10: {
        portuguese:
          "Compre em nossa LOJA VIRTUAL, os itens vão diretamente para sua suíte.",
        english: "Shop in our VIRTUAL STORE, items go directly to your suite.",
      },
      text11: {
        portuguese: "Dúvidas? Entre em contato conosco.",
        english: "Questions? Contact us.",
      },
    },
    services: {
      text1: {
        portuguese: "Cadastro de Produtos em 48 horas",
        english: "Product Registration in 48 hours",
      },
      text2: {
        portuguese:
          "Envios de Produtos em até 48 horas após a confirmação de pagamento",
        english: "Products shipped within 48 hours after payment confirmation",
      },
      text3: {
        portuguese:
          "Pedido Especial em até 48 horas após a confirmação de pagamento",
        english: "Special Order within 48 hours of payment confirmation",
      },
    },
    servicesTitle: {
      hours: {
        portuguese: "NOSSOS HORÁRIOS",
        english: "OUR HOURS",
      },
      deadlines: {
        portuguese: "NOSSOS PRAZOS",
        english: "OUR DEADLINES",
      },
      address: {
        portuguese: "SEU ENDEREÇO DE COMPRAS",
        english: "YOUR SHOPPING ADDRESS",
      },
      systemUse: {
        portuguese: "SAIBA COMO UTILIZAR O SISTEMA",
        english: "HOW TO USE THE SYSTEM",
      },
    },
    resumeService: {
      product: {
        portuguese: "Produtos/Suite",
        english: "Products/Suite",
      },
      credits: {
        portuguese: "Créditos",
        english: "Credits",
      },
      creditsInSystem: {
        portuguese: "Créditos no sistema",
        english: "Credits in system",
      },
      services: {
        portuguese: "Serviços",
        english: "Services",
      },
      shipments: {
        portuguese: "Envios",
        english: "Shippments",
      },
    },
    dashboardAdmin: {
      graphslabels: {
        services: {
          order: { portuguese: "Pedidos", english: "Orders" },
          askQuestion: {
            portuguese: "Perguntar em anúncio",
            english: "Ask question",
          },
          bid: { portuguese: "Ofertar em anúncio", english: "Offer in ad" },
          auction: { portuguese: "Leilão", english: "Auction" },
        },
        canceled: {
          portuguese: "Cancelado",
          english: "Canceled",
        },
        notCanceled: {
          portuguese: "Não cancelado",
          english: "Not canceled",
        },
        money: {
          portuguese: "Dinheiro ganho",
          english: "Money earned",
        },
        removePackage: {
          portuguese: "Remover da caixa",
          english: "Remove package",
        },
        takeNewPhotos: {
          portuguese: "Tirar novas fotos",
          english: "Take new photos",
        },
        quantity: {
          portuguese: "Quantidade",
          english: "Quantity",
        },
      },
      graphs: {
        services: {
          portuguese: "Serviços por mês (Pedidos)",
          english: "Services per month (Orders)",
        },
        shippments: {
          portuguese: "Envios/Serviços extras por mês",
          english: "Shippments/Extra services per month",
        },
        money: { portuguese: "Lucro por mês", english: "Profit per month" },
        products: {
          portuguese: "Produtos por mês",
          english: "Products per month",
        },
        ordersRequested: {
          portuguese: "Pedidos solicitados por mês",
          english: "Orders requested per month",
        },
        shippmentsRequested: {
          portuguese: "Envios solicitados por mês",
          english: "Shippments requested per month",
        },
        productsArrive: {
          portuguese: "Produtos chegados por mês",
          english: "Products arrived per month",
        },
      },
    },
    newOrderPage: {
      history: {
        portuguese: "Histórico",
        english: "History",
      },
      bids: {
        portuguese: "Lances",
        english: "Bids",
      },
      inactive: {
        portuguese: "Inativo",
        english: "Inactive",
      },
      new: {
        portuguese: "Novo",
        english: "New",
      },
      active: {
        portuguese: "Ativo",
        english: "Active",
      },
      waitingBidUpdate: {
        portuguese: "Aguardando atualização do lance",
        english: "Waiting for bid update",
      },
      updateMyBid: {
        portuguese: "Atualizar meu lance",
        english: "Update my bid",
      },
      updateClienteBid: {
        portuguese: "Atualizar lance",
        english: "Update bid",
      },
      refuseBid: {
        portuguese: "Recusar lance",
        english: "Refuse bid",
      },
      bidNow: {
        portuguese: "Lance atual",
        english: "Bid now",
      },
      newBid: {
        portuguese: "Novo lance",
        english: "New bid",
      },
      bidError: {
        portuguese: " O novo valor não pode ser menor que o lance atual",
        english: "The new value cannot be less than the current bid",
      },
      warningNewBid: (val: number) => {
        return {
          portuguese: `Ao clicar em "Enviar novo lance", o lance de ￥${val} será enviado para nossa equipe atualizar, tenha certeza do novo valor a ser atualizado.`,
          english: `By clicking "Send new bid", the bid of ￥${val} will be sent to our team to update, be sure of the new value to be updated.`,
        };
      },
      sendNewqBid: {
        portuguese: "Enviar novo lance",
        english: "Send new bid",
      },
      warningSendNewBid: {
        portuguese: "Deseja confirmar a mudança de status para",
        english: "Do you want to confirm the status change to",
      },
      title: {
        portuguese: "Serviços contratados",
        english: "Service orders",
      },
      auctionWarning: {
        portuguese:
          "Preencha os campos para criar o pedido de leilão, apenas 1 leilão é permitido por pedido, por favor, não insira vários links de leilões no mesmo pedido",
        english:
          "Fill in the fields to create the auction request, only 1 auction is allowed per request, please do not insert multiple auction links in the same request",
      },
      auctionWait: {
        portuguese: "Aguarde o termino do leilão",
        english: "Wait for the auction to end",
      },
      steps: {
        ordered: {
          portuguese: "Leilão criado",
          english: "Auction created",
        },
        auctionInProgress: {
          portuguese: "Leilão em andamento",
          english: "Auction in progress",
        },
        auctionLost: {
          portuguese: "Leilão perdido",
          english: "Auction lost",
        },
        auctionWin: {
          portuguese: "Leilão vencido por",
          english: "Auction won",
        },
      },
    },
    usersPage: {
      deleteUser: {
        portuguese:
          "Tem certeza que quer deletar este usuário? Esta ação é irreversível, caso o usuário queira comprar novamente ele terá que realizar um novo cadastro",
        english:
          "Are you sure you want to delete this user? This action is irreversible, if the user wants to buy again they will have to register again",
      },
      title: {
        portuguese: "Lista de usuários",
        english: "Users list",
      },
      editTitle: {
        portuguese: "Editar informações do usuário",
        english: "Edit user",
      },
    },
    productsPage: {
      title: {
        portuguese: "Minha suite",
        english: "My suite",
      },
      titleAdmin: {
        portuguese: "Produtos",
        english: "Products",
      },
      cards: {
        bought: {
          portuguese: "Comprado",
          english: "Bought",
        },
      },
      packageRemove: {
        portuguese: "Remover",
        english: "Remove",
      },
      packageRemoved: {
        portuguese: "Removida",
        english: "Removed",
      },
      packageNoRemove: {
        portuguese: "Não remover",
        english: "No remove",
      },
      tankeNewPhotos: {
        portuguese: "Tirar novas",
        english: "Take new ones",
      },
      photosDone: {
        portuguese: "Tiradas",
        english: "New photos",
      },
      box: {
        portuguese: "Caixa:",
        english: "Retail box:",
      },
      solicitationModal: {
        title: {
          portuguese: "Selecione sua solicitação",
          english: "Select your request",
        },
        removePackageText: {
          portuguese:
            "Confirmar retirada do item da caixa original? Este processo é irreversível!",
          english:
            "Confirm removal of the item from the original box? This process is irreversible!",
        },
        newPhotos: {
          portuguese: "Solicitar novas fotos?",
          english: "Request new photos?",
        },
        explanationRemovePrice: {
          portuguese:
            "O valor cobrado é calculado com base na quantidade que resta a ser enviado.",
          english:
            "The amount charged is calculated based on the quantity remaining to be shipped.",
        },
        creditWarning: {
          portuguese:
            "É necessário conter créditos para realizar esta ação, todo o estoque deste produto será tirado da caixa e será cobrado um total de",
          english:
            "Credits are required to perform this action, all stock of this product will be removed from the retail box and you will be charged a total of",
        },
      },
    },
    siteConfigPage: {
      title: {
        portuguese: "Configurações do sistema",
        english: "System config",
      },
    },
    siteAlertsPage: {
      title: {
        portuguese: "Alertas do sistema",
        english: "System alerts",
      },
      buttonShowAlert: {
        portuguese: "Mostrar mais avisos",
        english: "Show more notices",
      },
      buttonHiddenAlert: {
        portuguese: "Mostrar menos avisos",
        english: "Show less notices",
      },
    },
    dragDrop: {
      title1: {
        portuguese: "Solte as imagens aqui...",
        english: "Drop the files here ...",
      },
      title2: {
        portuguese: "Arraste e solte as imagens aqui, ou clique e selecione",
        english: "Drag & drop files here, or click to select files",
      },
      ready: {
        portuguese: "Imagens prontas",
        english: "Images ready",
      },
      images: {
        portuguese: "Imagens",
        english: "Images",
      },
      files: {
        portuguese: "Arquivos",
        english: "Files",
      },
    },
    filter: {
      orderType: {
        portuguese: "Tipo da ordem",
        english: "Order type",
      },
      userName: {
        portuguese: "Nome do usuário",
        english: "User name",
      },
      productName: {
        portuguese: "Nome do produto",
        english: "Product name",
      },
      payMethod: {
        portuguese: "Método de pagamento",
        english: "Pay method",
      },
      title: {
        portuguese: "Filtro",
        english: "Filter",
      },
    },
    userStatus: {
      permitted: {
        portuguese: "Permitido",
        english: "Permitted",
      },
      blocked: {
        portuguese: "Bloqueado",
        english: "Blocked",
      },
    },
    creditCards: {
      pricePlaceHolder: {
        portuguese: "Digite o valor a ser creditado em ienes",
        english: "Type the value to credit in yen",
      },
      warning: {
        portuguese: "O valor inserido deve ser em ienes.",
        english: "The amount entered must be in yen.",
      },
      canceled: {
        portuguese: "Cancelado",
        english: "Canceled",
      },
      credited: {
        portuguese: "Creditado",
        english: "Credited",
      },
      underAnalysis: {
        portuguese: "Em análise",
        english: "Under analysis",
      },
      editDelivery: {
        portuguese: "Editar entrega",
        english: "Edit delivery",
      },
      cancelWithRefund: {
        portuguese: "Cancelada/creditado",
        english: "Canceled/credited",
      },
      onAnalysis: {
        portuguese: "Em análise",
        english: "Under analysis",
      },
    },
    orderCards: {
      pixDisabled: {
        portuguese: "Pagamento por Pix (Brasil) temporariamente desabilitado.",
        english: "Payment by Pix (Brazil) temporarily disabled.",
      },
      orderType: {
        orderToBuy: {
          portuguese: "Ordem de compra",
          english: "Purchase Order",
        },
        askQuestion: {
          portuguese: "Perguntar em um anúncio",
          english: "Ask in an ad",
        },
        bid: {
          portuguese: "Ofertar em anúncio",
          english: "Offer in ad",
        },
        auction: {
          portuguese: "Leilão",
          english: "Auction",
        },
      },

      labels: {
        pendingBid: {
          portuguese: "Lance pendente de aprovação",
          english: "Bid pending approval",
        },
        waitingBidApproval: {
          portuguese: "Aguardando aprovação do lance",
          english: "Waiting for bid approval",
        },
        deliverieRequested: {
          portuguese: "Envio solicitado",
          english: "Deliverie requested",
        },
        waitingPayment: {
          portuguese: "Aguardando pagamento",
          english: "Waiting payment",
        },
        processingPayment: {
          portuguese: "Processando pagamento",
          english: "Payment processing",
        },
        notPaid: {
          portuguese: "Não pago",
          english: "Not paid",
        },
        orderDone: {
          portuguese: "Ordem finalizada",
          english: "Order Done",
        },
        shipped: {
          portuguese: "Enviado",
          english: "Shipped",
        },
        waitingCustomer: {
          portuguese: "Aguardando cliente",
          english: "Waiting customer",
        },
        waitingStore: {
          portuguese: "Aguardando loja",
          english: "Waiting store",
        },
        cancelOrder: {
          portuguese: "Cancelada",
          english: "Canceled",
        },
        orderType: {
          portuguese: "Tipo do pedido",
          english: "Order type",
        },
        description: {
          portuguese: "Descrição",
          english: "Description",
        },
        onAnalysis: { portuguese: "Em ánalise", english: "On analysis" },

        cancelOrderWithRefund: {
          portuguese: "Cancelada/Creditado",
          english: "Canceled/Credited",
        },
        editDelivery: {
          portuguese: "Edição solicitada",
          english: "Edit delivery",
        },
        ordered: {
          portuguese: "Pedido",
          english: "Ordered",
        },
        deliveryRequested: {
          portuguese: "Entrega solicitada",
          english: "Delivery requested",
        },
        paymentSent: {
          portuguese: "Pagamento enviado",
          english: "Paid sent",
        },
      },
    },
    productPreview: {
      name: {
        portuguese: "Nome",
        english: "Name",
      },
      quantity: {
        portuguese: "Quantidade comprada",
        english: "Bought quantity",
      },
      date: {
        portuguese: "Cadastrado em",
        english: "Registered in",
      },
      openBTN: {
        portuguese: "Abrir produto",
        english: "Open product",
      },
      details: {
        portuguese: "Para consultar mais detalhes, entre na sua suíte.",
        english: "To see more details, go to your suite.",
      },
      previewTitle: {
        portuguese: "Prévia do produto",
        english: "Product preview",
      },
    },
    paymentTreasureModal: {
      confirmOrder: {
        portuguese: "Confirme seu pedido",
        english: " Confirm your order",
      },
      quantity: {
        portuguese: "Quantidade selecionada",
        english: "Quantity selected",
      },
      goToPayment: {
        portuguese: "Ir para o pagamento",
        english: "Go to payment",
      },
      paymentSent: {
        portuguese: "Pagamento enviado",
        english: "Payment sent",
      },
      waitingPayment: {
        portuguese: "Aguardando pagamento",
        english: "Waiting payment",
      },
      pickingFromStock: {
        portuguese: "Separando no estoque",
        english: "Picking from stock",
      },
      registeredSuite: {
        portuguese: "Registrado na suite",
        english: "Registered in your suite",
      },
      canceled: {
        portuguese: "Cancelado",
        english: "Canceled",
      },
      canceledWithRefund: {
        portuguese: "Cancelado com reembolso",
        english: "Canceled with refund",
      },
      notPaid: { portuguese: "Não pago", english: "Not paid" },
      messageSeparating: {
        portuguese:
          "Deseja alterar o status desse pedido para separar no estoque?",
        english:
          "Do you want to change the status of this order to separate from stock?",
      },
      suiteRegistration: {
        portuguese:
          "Este status vai cadastrar o produto na suite do cliente, tem certeza que deseja alterar o status deste pedido para registrado na suite?",
        english:
          "This status will register the product in the customer suite. Are you sure you want to change the status of this order to registered in the suite?",
      },
      cancel: {
        portuguese: "Confirmar o cancelamento deste pedido?",
        english: "Do you confirm the cancellation of this order?",
      },

      cancelRefund: {
        portuguese:
          "Confirma o cancelamento deste pedido com reembolso em créditos?. Todo o valor será créditado na conta do cliente",
        english:
          "Do you confirm the cancellation of this order with a refund in credits? The entire amount will be credited to the customer's account.",
      },
    },
    orderModal: {
      type: {
        portuguese: "Tipo do produto",
        english: "Product type",
      },
      origin: {
        portuguese: "Origem/Anime",
        english: "Origin/Anime",
      },
      canMakeOrders: {
        portuguese:
          "Sua conta está bloqueada para fazer novos pedidos temporariamente, porém você pode fazer envios normalmente, qualquer dúvida entre em contato conosco.",
        english:
          "Your account is temporarily blocked to placing new orders, however you can make shipments normally. If you have any questions, please contact us.",
      },
      cardPayment: {
        portuguese:
          "Clique no botão abaixo para realizar o pagamento por cartão de crédito, após o pagamento aguarde o termino do processamento",
        english:
          "Click the button below to make the payment by credit card, after payment, wait for processing to complete",
      },
      pixPayment: {
        portuguese:
          "Envie o valor para o CPF 192.619.638.46 - Nubank - Miriam Akemi Yassuda",
        english:
          "Envie o valor para o CPF 192.619.638.46 - Nubank - Miriam Akemi Yassuda",
      },
      paypalPayment: {
        portuguese:
          "Envie o valor para o email miriam.yassuda2013@gmail.com - Miriam Akemi Yassuda no PayPal",
        english:
          "Send the amount to email miriam.yassuda2013@gmail.com - Miriam Akemi Yassuda on PayPal",
      },
      wisePayment: {
        portuguese:
          "Envie o valor para email treasurebox.noreply@gmail.com no Wise",
        english:
          "Send the amount to email treasurebox.noreply@gmail.com on Wise",
      },
      choiceRefund: {
        portuguese: "Escolha o valor a ser devolvido ou digite um valor",
        english: "Choose the value to refund or type one",
      },
      productName: {
        portuguese: "Nome do produto",
        english: "Product name",
      },
      trackingCode: {
        portuguese: "Rastreio",
        english: "Tracking code",
      },
      boxWeight: {
        portuguese: "Novo peso",
        english: "New weight",
      },
      kg: {
        portuguese: "Peso",
        english: "Weight",
      },
      arrivedAt: {
        portuguese: "Chegada",
        english: "Arrived",
      },
      shippedAt: {
        portuguese: "Enviado",
        english: "Shipped",
      },
      changeStatus: {
        portuguese: "Mudar status para",
        english: "Change status to",
      },
      limitWeight: {
        portuguese:
          "Peso ultrapassou o limite do tipo de envio escolhido, essa entrega não poderá ser enviada por small packet, você também pode solicitar a edição para alterar o tipo da entrega ou a retirada da embalagem no menu 'Suite' para tentar diminuir o peso",
        english:
          "The weight has exceeded the limit for the chosen shipping type, this delivery cannot be sent by small packet, you can also request an edit to change the type of delivery or remove the packaging in the 'Suite' menu to try to reduce the weight",
      },
      limitWeightFirst: {
        portuguese:
          "Peso ultrapassou o limite do tipo de envio escolhido, essa entrega não poderá ser enviada por small packet, você também pode solicitar a retirada da embalagem no menu 'Suite' para tentar diminuir o peso",
        english:
          "The weight has exceeded the limit for the chosen shipping type, this delivery cannot be sent by small packet, you can also request removal of the packaging in the 'Suite' menu to try to reduce the weight",
      },
      exceedMaximalWeight: {
        portuguese: "Peso máximo atingido, limite é de 30kg",
        english: "Maximum weight reached, limit is 30kg",
      },
      confirmLostAuctionCancel: {
        portuguese: "Este status vai cancelar o pedido.",
        english: "This process will cancel the order.",
      },
      errorFile: {
        portuguese: "Insira o comprovante",
        english: "Upload proof",
      },
      titleWichOrder: {
        portuguese: "Escolha o tipo de serviço",
        english: "Select service type",
      },
      tooltip: {
        portuguese: "Clique aqui para visualizar ou enviar mensagens",
        english: "Click here to send or view messages",
      },
      title: {
        portuguese: "Título",
        english: "Title",
      },

      textLink: {
        portuguese: "Insira o link",
        english: "Insert the link",
      },
      qtd: {
        portuguese: "Quantidade",
        english: "Quantity",
      },
      payment: {
        portuguese: "Selecione o metodo de pagamento",
        english: "Select payment method",
      },
      proofPayment: {
        portuguese: "Comprovante de pagamento",
        english: "Proof of payment",
      },
      price: {
        portuguese: "Valor unitário",
        english: "Price unit",
      },
      priceTotal: {
        portuguese: "Valor total",
        english: "Total price",
      },
      serviceTax: {
        portuguese: "Taxa de serviço",
        english: "Service tax",
      },
      linkTax: {
        portuguese: "Taxa de compra",
        english: "Purchase fee",
      },
      linkTaxExpl: (val: number) => ({
        portuguese: `A taxa de ￥${val} ienes é cobrada por link igual ou abaixo de ￥1000 ienes.`,
        english: `A fee of ¥${val} yen is charged for links below or equal ¥1000 yen.`,
      }),
      linkTaxExplLojista: (val: number) => ({
        portuguese: `A taxa de ￥${val} ienes é cobrada por link.`,
        english: `A fee of ¥${val} yen is charged for each link`,
      }),
      subTotal: {
        portuguese: "Sub Total",
        english: "Sub Total",
      },
      discount: {
        portuguese: "Desconto",
        english: "Discount",
      },
      useCredit: {
        portuguese: "Crédito usado",
        english: "Used credit",
      },
      paymentTax: {
        portuguese: "Taxa de pagamento",
        english: "Payment tax",
      },
      total: {
        portuguese: "Total",
        english: "Total",
      },
      errorCanceled: {
        portuguese:
          "Atenção: Este status vai cancelar a ordem, esse processo é IRREVERSÍVEL.",
        english:
          "Attention: This status will cancel the order, this process is IRREVERSIBLE.",
      },
      errorCanceledFull: {
        portuguese:
          "Atenção: Este status vai cancelar a ordem devolver o valor total da compra, esse processo é IRREVERSÍVEL.",
        english:
          "Attention: This status will cancel the order and return the value of your choice, this process is IRREVERSIBLE.",
      },
    },
    messages: {
      title: {
        portuguese: "Mensagens",
        english: "Messages",
      },
      btn: {
        portuguese: "Marcar como lida",
        english: "Mark as read",
      },
    },
    termsConditions: {
      title: {
        portuguese: "Termos e Condições",
        english: "Terms and Conditions",
      },
      checkbox: {
        text1: {
          portuguese: "Eu concordo com os",
          english: "I agree to the",
        },
        text2: {
          portuguese: "Termos e Condições",
          english: "terms and conditions",
        },
        text3: {
          portuguese: "ao fazer uma solicitação",
          english: "when making a request",
        },
      },
    },
    tooltips: {
      copyAddress: {
        portuguese: "Copiar endereço",
        english: "Copy address",
      },
    },
    links: {
      bought: {
        portuguese: "Comprado",
        english: "Bought",
      },
      canceled: {
        portuguese: "Cancelado",
        english: "Canceled",
      },
      arrived: {
        portuguese: "Cadastrado",
        english: "Arrived",
      },
      ordered: {
        portuguese: "Pedido",
        english: "Ordered",
      },
      warnings: {
        step1: {
          portuguese: "Pedidos gerados entram na fila de compra.",
          english: "Generated orders go into the purchase queue.",
        },
        step2: {
          portuguese:
            "Após o link ser análisado pela nossa equipe, ele mudará de status para 'comprado' ou 'cancelado' dependendo da situação do anúncio.",
          english:
            "After the link is analyzed by our team, it will change status to 'bought' or 'canceled' depending on the ad situation.",
        },
        step3: {
          portuguese:
            "Quando o produto do link chegar, o status vai mudar para 'cadastrado' e você poderá visualizar o produto na sua suíte.",
          english:
            "When the product from the link arrives, the status will change to 'arrived' and you will be able to view the product in your suite.",
        },
        step4: {
          portuguese:
            "Qualquer dúvida, entre em contato via chat pelo botão no canto superior esquerdo deste pedido.",
          english:
            "If you have any questions, contact us by chat by clicking the button in the upper left corner of this order.",
        },
      },
    },
  };

  return { strings };
}
