import { Box, Paper } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import useAuth from "../../../hooks/context/useAuth";
import { colors } from "../../../theme/colors";
import { useEffect, useState } from "react";
import { useBreakpoints } from "../../../hooks/useBreakpoins";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import NewOrderEnglish from "../../../assets/images/NOVIDADELEILAOEN.png";
import NewOrderPT from "../../../assets/images/LEILAONOVOPT.png";
import { CustomModal } from "../../AtomicDesign/Organisms";
import { HelpOrder } from "./HelpOrder";
import { AlertBox } from "../../AtomicDesign/Molecules";

export function AppLayout() {
  const { isSmalldesktop } = useBreakpoints();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const route = useLocation();

  const navigate = useNavigate();
  const { getUserLocalStorage, token, getUser, language, siteConfig } =
    useAuth();
  const { getFilterStorage } = useLocalStorage();
  const { user } = getUserLocalStorage();

  const maintenance = process.env.REACT_APP_MAINTENANCE === "ON";

  if (maintenance) {
    navigate("/maintenance");
  }

  if (!token || user.user.role !== "client") {
    navigate("/");
  }

  const getUserAtt = async () => {
    getUser(user?.user?.id);
  };

  useEffect(() => {
    if (user?.user?.id) {
      getUserAtt();
      getFilterStorage(true);
    }
    // eslint-disable-next-line
  }, [route]);

  useEffect(() => {
    if (siteConfig?.appVersion) {
      const getLastBrowserUpdate = localStorage.getItem("@treasurebox_appver");
      if (!getLastBrowserUpdate) {
        window.location.reload();
        localStorage.setItem("@treasurebox_appver", siteConfig?.appVersion);
      }
      if (getLastBrowserUpdate) {
        if (getLastBrowserUpdate !== siteConfig?.appVersion) {
          localStorage.setItem("@treasurebox_appver", siteConfig?.appVersion);
          window.location.reload();
        }
      }
    }
    // eslint-disable-next-line
  }, [siteConfig]);

  const imageOrder = {
    english: NewOrderEnglish,
    portuguese: NewOrderPT,
  };

  const titleModalText = {
    portuguese: "Aumentar lance do leilão",
    english: "Increase auction bid",
  };

  return (
    <Box
      bgcolor={colors.gray[100]}
      sx={{ overflowX: "hidden" }}
      height={"100vh"}
    >
      <Paper elevation={2} sx={{ borderRadius: 0 }}>
        <Header drawerStatus={setDrawerOpen} />
      </Paper>

      <Box
        display={"flex"}
        gap={1}
        width={"100%"}
        mb={-5}
        flexWrap={isSmalldesktop ? "wrap" : "nowrap"}
      >
        <img
          onClick={() => setModalOpen(true)}
          src={imageOrder[language]}
          alt="new order logo"
          width={isSmalldesktop ? 180 : 250}
          height={isSmalldesktop ? 130 : 180}
          style={{
            marginLeft: drawerOpen ? "250px" : "0",
            marginTop: 5,
            marginBottom: -40,
            cursor: "pointer",
          }}
        />
        <AlertBox />
      </Box>
      <Box
        mt={5}
        marginLeft={drawerOpen && !isSmalldesktop ? "240px" : "0"}
        width={"100%"}
        display={"flex"}
        justifyContent={drawerOpen ? "center" : "flex-start"}
      >
        <Box
          maxWidth={"1920px"}
          width={"100%"}
          marginRight={drawerOpen && !isSmalldesktop ? "240px" : "0"}
        >
          <Outlet />
        </Box>
      </Box>
      {/* <Container maxWidth="lg"></Container> */}
      <CustomModal
        openModal={modalOpen}
        setOpenModal={setModalOpen}
        width={isSmalldesktop ? "90%" : "60%"}
        title={titleModalText[language]}
      >
        <HelpOrder />
      </CustomModal>
    </Box>
  );
}
