import {
  Box,
  Button,
  Card,
  CardMedia,
  Checkbox,
  Divider,
  FormControlLabel,
  FormHelperText,
  IconButton,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { BoxContent, CircularLoading, FadeInContainer } from "../../Atoms";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect, useMemo, useState } from "react";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import {
  BasicPopover,
  CopyClipboardBTN,
  DropFileInput,
  GenericInput,
  GenericSelect,
  MessageBox,
  MessageIcon,
  PrintComponentProductIDS,
} from "../../Molecules";
import useShippments from "../../../../hooks/context/useShippments";
import CreateIcon from "@mui/icons-material/Create";
import {
  green,
  red,
  amber,
  grey,
  blue,
  deepPurple,
  teal,
} from "@mui/material/colors";
import { CustomModal } from "../Modal";
import { useCommonData } from "../../../../hooks/useCommonData";
import { useBreakpoints } from "../../../../hooks/useBreakpoins";
import { UseDateTime } from "../../../../hooks/useDateTime";
import { useJPYmoney } from "../../../../hooks/useJPYmoney";
import { getUserAddress } from "../../../../services/addressService";
import useAuth from "../../../../hooks/context/useAuth";
import CustomDatePicker from "../../Molecules/DatePicker";
import { TrackingPackage } from "../TrackingPackage";
import { calcShipPrice } from "../../../../services/shippmentSimulation";
import { PaymentModalCreditCard } from "../../Molecules/PaymentModalCreditCard";

interface Props {
  getProducts(): Promise<Products[]>;
  data: OneShippment;
  closeModal: VoidFunction;
  fileRemoveAdmin?(file: any, voucherID: number): void;
}

type SelectedPRD = {
  name: string;
  id: number;
  imgUrl: string;
  qtd: number;
  kg: number;
  stock: number;
  packageRemove: boolean;
  adminRemovePackage: boolean;
}[];

export function UpdateShippmentBox({
  getProducts,
  data,
  closeModal,
  fileRemoveAdmin,
}: Props) {
  const { deliverie, deliverieAddress } = data;

  const { isMobile } = useBreakpoints();
  const {
    updateProductQTDinDelivery,
    deleteProductFromDelivery,
    addProductToDelivery,
    uploadPaymentProof,
    setKeepAttMessages,
    sendNewMessage,
    readAllMessages,
    loading,
    verifyPayment,
  } = useShippments();
  const { formatedDate } = UseDateTime();
  const { getUserLocalStorage, siteConfig } = useAuth();
  const { user } = getUserLocalStorage();
  const { JPYmoney, getPixValue } = useJPYmoney();
  const {
    adminOrder,
    orderModal,
    adminButtons,
    addressStrings,
    shippmentModal,
    paymentChoices,
  } = useCommonData();
  const { isSmalldesktop } = useBreakpoints();
  //   const { user } = getUserLocalStorage();
  const [selectedProducts, setSelectedProducts] = useState([] as SelectedPRD);
  const [oldProducts, setOldProducts] = useState([] as SelectedPRD);
  const [products, setProducts] = useState([] as Products[]);
  const [error, setError] = useState([] as SelectedPRD);
  const [oldError, setOldError] = useState([] as SelectedPRD);
  const [confirmModalOpen, setConfirmModalOpen] = useState({
    message: "",
    open: false,
    status: "",
  });
  const [declaredValueModal, setDeclaredValueModal] = useState(false);
  const [checkBoxes, setCheckBoxes] = useState({
    plasticBubble: false,
    reinforcedBox: false,
    securityFee: false,
  });
  const [trkCode, setTrkCode] = useState("");
  const [priceDlv, setPriceDlv] = useState("");
  const [newBoxWeight, setNewBoxWeight] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [penalty, setPenalty] = useState(0);
  const [dhlPrice, setDHlPrice] = useState(0);
  const [shipError, setShipError] = useState({ errorMessage: "", open: false });
  const [files, setFiles] = useState([] as any);
  const [address, setAddress] = useState<IAddress[]>();
  const [choiceIDAddress, setChoiceIDAddress] = useState("");
  const [creditCardModalOpen, setCreditCardModalOpen] = useState(false);
  const [choiceShippment, setChoiceShippment] = useState("");
  const [editInfoModal, setEditInfoModal] = useState({
    message: "",
    open: false,
    key: "",
  } as any);
  const [messageModal, setMessageModal] = useState(false);
  const [messageTooltip, setMessageTooltip] = useState(true);
  const isAdmin = user?.user?.role === "admin";
  const [trackingModal, setTrackingModal] = useState(false);
  const [shipPrice, setShipPrice] = useState({} as CalcServiceTaxResponse);
  const [refundValue, setRefundValue] = useState(0);
  const [refundModal, setRefundModal] = useState(false);
  const [switchUseMyCredits, setSwitchUseMyCredits] = useState(false);
  const [paymentChoice, setPaymentChoice] = useState("");
  const [declaredValue, setDeclaredValue] = useState(0);
  const [openIdsModal, setOpenIdsModal] = useState(false);

  const myAddress = async () => {
    const correctID = isAdmin ? deliverie?.Suite?.usersId : user?.user?.id;
    try {
      const myAddress = await getUserAddress(correctID);
      setAddress(myAddress);
    } catch (err) {}
  };

  const getUserPRDS = async () => {
    const prds = await getProducts();
    setProducts(prds);
  };

  const calcShipPrices = async () => {
    const customWeight =
      data?.deliverie?.customBoxWeight > 0
        ? data?.deliverie?.customBoxWeight
        : siteConfig?.defaultBoxWeight;

    const prices = await calcShipPrice({
      globalZoneId:
        address?.find((adr) => adr.id === Number(choiceIDAddress))
          ?.globalZoneId ?? 0,
      serviceType: choiceShippment,
      weight: prdKG + oldPrdKG + customWeight,
      deliverieID: data?.deliverie?.id,
    });

    if (deliverie?.paymentChoice === "pix") {
      const pix = await getPixValue(prices.prices.remainingValue);

      setShipPrice({
        ...prices,
        totalPix: pix,
      });
      return prices;
    } else {
      setShipPrice(prices);
      return prices;
    }
  };

  useEffect(() => {
    getUserPRDS();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (deliverie) {
      myAddress();
    }
    // eslint-disable-next-line
  }, [deliverie]);

  useEffect(() => {
    setChoiceIDAddress(String(deliverie?.addressID));
    // eslint-disable-next-line
  }, [address]);

  useEffect(() => {
    setKeepAttMessages(true);
    setSwitchUseMyCredits(data?.deliverie?.useMyCredits);
    setPaymentChoice(data?.deliverie?.paymentChoice);

    setTimeout(() => {
      setMessageTooltip(false);
    }, 5000);

    return () => setKeepAttMessages(false);
    // eslint-disable-next-line
  }, [data]);

  const hasNewMessage = () => {
    const messages = data?.deliverie?.WarningMessage as any;
    if (!messages) return false;
    if (messages?.length === 0) return false;
    if (!messages[0]?.read && user?.user?.id !== messages[0]?.usersId) {
      if (user?.user?.role === messages[0]?.type) {
        return false;
      }
      return true;
    }
    return false;
  };

  const handleChangeQTD = (qtd: number, productID: number) => {
    setError((old) => old.filter((prd) => prd.id !== productID));
    let prds = selectedProducts;
    const filt = prds.findIndex((prd) => prd.id === productID);

    prds[filt] = {
      id: prds[filt]?.id,
      imgUrl: prds[filt]?.imgUrl,
      name: prds[filt]?.name,
      stock: prds[filt]?.stock,
      kg: prds[filt]?.kg,
      packageRemove: prds[filt]?.packageRemove,
      adminRemovePackage: prds[filt]?.adminRemovePackage,
      qtd,
    };

    setSelectedProducts(prds);
  };

  const AddItens = async () => {
    const verify = selectedProducts.filter(
      (item) => item.stock < item.qtd || item.qtd === 0
    );
    if (verify.length > 0) {
      return setError(verify);
    } else {
      await Promise.all(
        selectedProducts.map(async (item) => {
          const payload = {
            deliverieID: deliverie?.id,
            productsID: {
              id: item?.id,
              shipQTD: item?.qtd,
            },
          };
          addProductToDelivery(payload);
        })
      );
      setSelectedProducts([]);
    }
  };

  const updateQTDOldProducts = (
    item: {
      name: string;
      id: number;
      imgUrl: string;
      qtd: number;
      kg: number;
      stock: number;
      packageRemove: boolean;
      adminRemovePackage: boolean;
    },
    shipQTD: number
  ) => {
    const { id, stock, qtd } = item;
    if (shipQTD === 0) {
      return setOldError((old) => [...old, item]);
    } else {
      setOldError([]);
    }

    if (shipQTD > stock + qtd) {
      return setOldError((old) => [...old, item]);
    } else {
      setOldError([]);
    }

    const payload = {
      deliverieID: deliverie?.id,
      productsIDS: [
        {
          shipQTD,
          id,
        },
      ],
    };

    updateProductQTDinDelivery(payload);
  };

  const oldFiles = () => {
    const oldData = deliverie?.OrderToShip?.map((order) => {
      let obj = {
        qtd: order?.shippedQtd,
        name: "",
        stock: 0,
        kg: 0,
        imgUrl: "",
        id: order?.arrivedProductsId,
        packageRemove: false,
        adminRemovePackage: false,
      };
      const prdsArray = deliverie?.Delivery?.map(
        (item) => item?.ArrivedProducts
      ) as any;
      const findPRD = prdsArray.find((prd: any) => prd?.id === obj?.id);
      obj = {
        ...obj,
        stock: findPRD?.leftInStock ?? 0,
        imgUrl: findPRD?.ProductImages[0]?.code ?? "",
        kg: findPRD?.kg,
        name: findPRD?.name ?? "",
        packageRemove: findPRD?.removePackage,
        adminRemovePackage: findPRD?.adminRemovePackage,
      };
      return obj;
    }) as SelectedPRD;
    setOldProducts(oldData);
  };

  useEffect(() => {
    if (deliverie?.OrderToShip) {
      oldFiles();
    }

    // eslint-disable-next-line
  }, [data]);

  const updateDeliveryStatus = () => {
    const mapitens = oldProducts.map((item) => ({
      shipQTD: item.qtd,
      id: item.id,
    }));

    const payloadwithoutItems = {
      deliverieID: deliverie?.id,
      status: confirmModalOpen?.status,
    };

    const payloadWithItems = {
      deliverieID: deliverie?.id,
      status: confirmModalOpen?.status,
      productsIDS: mapitens,
      ...(shipPrice?.prices?.discount > 0 && {
        confirmDiscount: shipPrice?.prices?.discount,
      }),
      ...(shipPrice?.prices?.stockCost > 0 && {
        stockPrice: shipPrice?.prices?.stockCost,
      }),
      ...(shipPrice?.fee?.tax > 0 && { tax: shipPrice?.fee?.tax }),
      ...(shipPrice?.prices?.extraServicesTotalPrice > 0 && {
        extras: shipPrice?.prices?.extraServicesTotalPrice,
      }),
      ...(deliverie.shipped === null && { shipped: new Date() }),
    };

    const payload =
      confirmModalOpen?.status === "shipped" ||
      confirmModalOpen?.status === "canceled"
        ? payloadWithItems
        : payloadwithoutItems;

    updateProductQTDinDelivery(payload);
  };

  const cancelAndRefund = () => {
    const mapitens = oldProducts.map((item) => ({
      shipQTD: item.qtd,
      id: item.id,
    }));

    const payload = {
      deliverieID: deliverie?.id,
      status: "cancelWithRefund",
      valueToRefund: refundValue,
      productsIDS: mapitens,
    };
    updateProductQTDinDelivery(payload);
    setRefundModal(false);
  };

  const changeShippmentType = (val: string) => {
    const payload = {
      deliverieID: deliverie?.id,
      shippmentType: val,
    };
    updateProductQTDinDelivery(payload);
  };

  const changeShippedDate = (date: Date) => {
    const payload = {
      deliverieID: deliverie?.id,
      shipped: date,
    };
    updateProductQTDinDelivery(payload);
  };

  const changeTrackingNumber = (trackingCode: string) => {
    const payload = {
      deliverieID: deliverie?.id,
      trackingCode,
    };
    updateProductQTDinDelivery(payload);
  };

  const changeBoxWeight = (customBoxWeight: Number) => {
    const payload = {
      deliverieID: deliverie?.id,
      customBoxWeight,
    };
    updateProductQTDinDelivery(payload);
  };

  const changeDiscount = (discount: Number) => {
    const payload = {
      deliverieID: deliverie?.id,
      discount,
    };
    updateProductQTDinDelivery(payload);
  };

  const changeDHLprice = (dhlPrice: Number) => {
    const payload = {
      deliverieID: deliverie?.id,
      dhlPrice,
    };
    updateProductQTDinDelivery(payload);
  };

  const changePrice = (price: number) => {
    const payload = {
      deliverieID: deliverie?.id,
      price: Number(price),
    };
    updateProductQTDinDelivery(payload);
  };

  const changePenalty = (price: number) => {
    const payload = {
      deliverieID: deliverie?.id,
      penalty: Number(price),
    };
    updateProductQTDinDelivery(payload);
  };

  const updateCustomOptions = async (choice?: any, value?: any) => {
    try {
      const verifyCreditUsed =
        deliverie?.status === "waitingPayment" ||
        deliverie?.status === "paymentSent";

      const payload = {
        deliverieID: deliverie?.id,
        addressID: Number(choiceIDAddress),
        [choice]: value,
        ...(verifyCreditUsed && {
          creditsUsed: shipPrice?.credits?.creditUsed,
        }),
      };

      updateProductQTDinDelivery(payload);
    } catch {}
  };

  const changeDeclaredValue = (value: number) => {
    const payload = {
      deliverieID: deliverie?.id,
      declaredValue: value,
    };

    updateProductQTDinDelivery(payload);
  };

  const deleteOldProds = async (id: number, shipQTD: number) => {
    const orderToShipID = deliverie.OrderToShip.find(
      (item) => item?.arrivedProductsId === id
    )?.id;
    const payload = {
      id,
      shipQTD,
      orderToShipID,
      deliverieID: deliverie?.id,
    };
    await deleteProductFromDelivery(payload);
    await getUserPRDS();
  };

  // const deleteShippment = () => {
  //   deleteDelivery(deliverie?.id);
  //   closeModal();
  // };

  useEffect(() => {
    setCheckBoxes({
      plasticBubble: deliverie?.plasticBubble as any,
      reinforcedBox: deliverie?.reinforcedBox as any,
      securityFee: deliverie?.securityFee as any,
    });

    setChoiceShippment(deliverie?.shippmentType);
    // eslint-disable-next-line
  }, [data]);

  const disableBtns = () => {
    switch (deliverie?.status) {
      case "editDelivery":
        return false;
      default:
        return true;
    }
  };

  const disableBtnsAdmin = () => {
    switch (deliverie?.status) {
      case "cancelWithRefund":
        return true;
      case "canceled":
        return true;
      case "shipped":
        return true;
      default:
        return false;
    }
  };

  const limitSizeFiles = useMemo(() => {
    const verifyLimite =
      files.reduce((acc: any, next: any) => acc + next?.size, 0) / 1024;
    return verifyLimite;
  }, [files]);

  const showPaymentOptions = () => {
    if (!deliverie?.status) return false;

    if (shipPrice?.prices?.remainingValue === 0) return false;
    if (deliverie?.status === "waitingPayment") return true;
    if (deliverie?.status === "deliverieRequested") return false;

    return true;
  };
  const showProof = () => {
    if (!deliverie?.status) return false;
    if (deliverie?.paymentChoice === "card") return false;
    if (shipPrice?.prices?.remainingValue === 0) return false;
    if (deliverie?.status === "waitingPayment") return true;
    if (deliverie?.PaymentVoucher?.length === 0) return false;
    return true;
  };

  const prdKG = selectedProducts.reduce(
    (acc, next) => acc + next.kg * next.qtd,
    0
  );

  const oldPrdKG = oldProducts.reduce(
    (acc, next) => acc + next.kg * next.qtd,
    0
  );

  useEffect(() => {
    const findZone = address?.find(
      (adr) => adr.id === Number(choiceIDAddress)
    )?.globalZoneId;
    if (
      choiceIDAddress &&
      address &&
      address?.length > 0 &&
      findZone &&
      choiceShippment &&
      data?.deliverie?.id
    ) {
      calcShipPrices();
    }
    // eslint-disable-next-line
  }, [choiceIDAddress, checkBoxes, prdKG, oldPrdKG, choiceShippment]);

  useEffect(() => {
    const boxweight =
      deliverie?.customBoxWeight > 0
        ? deliverie?.customBoxWeight
        : siteConfig?.defaultBoxWeight;
    const sumOldAndNew = prdKG + oldPrdKG + boxweight;

    if (sumOldAndNew > 2000 && choiceShippment === "smallPacket") {
      return setShipError({
        errorMessage: adminOrder.limitWeight,
        open: true,
      });
    }
    if (sumOldAndNew > 30000 && choiceShippment !== "smallPacket") {
      return setShipError({
        errorMessage: adminOrder.exceedMaximalWeight,
        open: true,
      });
    }
    return setShipError({
      errorMessage: "",
      open: false,
    });

    // eslint-disable-next-line
  }, [prdKG, oldPrdKG, choiceShippment, selectedProducts]);

  const verifyPackText = (admin: boolean, client: boolean) => {
    if (admin) return "Embalagem retirada";
    if (client) return "Solicitado remoção da embalagem";
    return "";
  };

  const addressToCopy = address?.find(
    (adr) => adr.id === Number(choiceIDAddress)
  );

  if (loading.oneOrder) {
    return <CircularLoading />;
  }

  const editInfoModalFunction: any = (
    info:
      | "price"
      | "customBoxWeight"
      | "trackingCode"
      | "discount"
      | "dhlPrice"
      | "penalty"
  ) => {
    switch (info) {
      case "penalty":
        return {
          title: JPYmoney(deliverie?.penalty),
          text: "Penalty",
          state: (val: any) => setPenalty(val),
          submit: () => changePenalty(Number(penalty)),
        };
      case "price":
        return {
          title: JPYmoney(deliverie?.price),
          text: orderModal.price,
          state: (val: any) => setPriceDlv(val),
          submit: () => changePrice(Number(priceDlv)),
        };
      case "customBoxWeight":
        return {
          title: data?.deliverie?.customBoxWeight,
          text: orderModal.boxWeight,
          state: (val: any) => setNewBoxWeight(val),
          submit: () => changeBoxWeight(Number(newBoxWeight)),
        };
      case "trackingCode":
        return {
          title: deliverie?.trackingCode,
          text: orderModal.trackingCode,
          state: (val: any) => setTrkCode(val),
          submit: () => changeTrackingNumber(trkCode),
        };
      case "discount":
        return {
          title: deliverie?.discount,
          text: orderModal.discount,
          state: (val: any) => setDiscount(Number(val)),
          submit: () => changeDiscount(discount),
        };
      case "dhlPrice":
        return {
          title: deliverie?.dhlPrice,
          text: "",
          state: (val: any) => setDHlPrice(Number(val)),
          submit: () => changeDHLprice(dhlPrice),
        };

      default:
        return {
          title: "",
          text: "",
          state: () => {},
          submit: () => {},
        };
    }
  };

  const getInfoPaymentChoice = () => {
    switch (deliverie.paymentChoice) {
      case "wise":
        return orderModal.wisePayment;
      case "paypal":
        return orderModal.paypalPayment;
      case "pix":
        return orderModal.pixPayment;
      case "card":
        return orderModal.cardPayment;
      default:
        return "";
    }
  };

  const disabledDeclaredValueBTN = () => {
    switch (deliverie?.status) {
      case "editDelivery":
        return false;
      case "deliverieRequested":
        return false;
      case "waitingPayment":
        return false;
      default:
        return true;
    }
  };

  const showPenaltyLine = () => {
    if (isAdmin) return true;
    if (deliverie?.penalty > 0) return true;
    return false;
  };

  const isLojista = user?.user?.needTaxOrders;
  const daysConfig = isLojista
    ? siteConfig?.daysFreeInStorageLojista ?? 0
    : siteConfig?.daysFreeInStorage ?? 0;

  const ShippmentPriceComponent = () => {
    return (
      <>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          width={"100%"}
          mb={1}
          mt={1}
        >
          <Typography>{shippmentModal.defaultBoxWeight}</Typography>
          {isAdmin && (
            <Button
              sx={{ mr: 1 }}
              variant="contained"
              onClick={() => {
                setEditInfoModal({
                  message: shippmentModal.shipBoxEditText,
                  open: true,
                  key: "customBoxWeight",
                });
              }}
            >
              <CreateIcon sx={{ mr: 1 }} />
              {shippmentModal.edit}
            </Button>
          )}
          <Typography>
            {data?.deliverie?.customBoxWeight > 0
              ? data?.deliverie?.customBoxWeight
              : siteConfig?.defaultBoxWeight?.toLocaleString() ?? 0}{" "}
            g
          </Typography>
        </Box>
        <Divider />

        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          width={"100%"}
          mb={1}
          mt={1}
        >
          <Typography>{shippmentModal.itemsWeight}</Typography>
          <Typography>{(prdKG + oldPrdKG).toLocaleString()} g</Typography>
        </Box>
        <Divider />

        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          width={"100%"}
          mb={1}
          mt={1}
        >
          <Typography>{shippmentModal.totalWeight}</Typography>
          <Typography>{shipPrice?.fee?.weight?.toLocaleString()} g</Typography>
        </Box>
        <Divider />

        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          width={"100%"}
          mb={1}
          mt={1}
        >
          <Typography>{shippmentModal.stockDays(daysConfig)}</Typography>
          <Typography>{shipPrice?.prices?.daysDelay}</Typography>
        </Box>
        <Divider />

        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          width={"100%"}
          mb={1}
          mt={1}
        >
          <Typography>{shippmentModal.stockPrice}</Typography>
          <Typography>{JPYmoney(shipPrice?.prices?.stockCost)}</Typography>
        </Box>
        <Divider />

        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          width={"100%"}
          mb={1}
          mt={1}
        >
          <Typography>{shippmentModal.serviceTax}</Typography>
          <Typography>{JPYmoney(shipPrice?.fee?.tax)}</Typography>
        </Box>
        <Divider />

        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          width={"100%"}
          mb={1}
          mt={1}
        >
          <Typography>{shippmentModal.taxRate}</Typography>
          <Typography>{JPYmoney(shipPrice?.fee?.taxRate)}</Typography>
        </Box>
        <Divider />

        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          width={"100%"}
          mb={1}
          mt={1}
        >
          <Typography>{shippmentModal.shippmentFee}</Typography>
          <Typography>{JPYmoney(shipPrice?.fee?.shippingFee)}</Typography>
        </Box>
        <Divider />

        {/* <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          width={"100%"}
          mb={1}
          mt={1}
        >
          <Typography>Extras</Typography>
          <Typography>
            {JPYmoney(shipPrice?.prices?.extraServicesTotalPrice)}
          </Typography>
        </Box> */}
        <Divider />

        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          width={"100%"}
          mb={1}
          mt={1}
        >
          <Typography>{shippmentModal.shipTotalPrice}</Typography>
          <Typography>{JPYmoney(shipPrice?.fee?.total)}</Typography>
        </Box>
        <Divider />

        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          width={"100%"}
          mb={1}
          mt={1}
        >
          <Typography>{shippmentModal.remaingFee}</Typography>
          <Typography>
            {JPYmoney(
              shipPrice?.prices?.remainingValue === 0
                ? 0
                : shipPrice?.prices?.remainingValue +
                    shipPrice?.prices?.discount
            )}
          </Typography>
        </Box>
        <Divider />

        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          width={"100%"}
          mb={1}
          mt={1}
        >
          <Typography>{shippmentModal.discount}</Typography>
          {isAdmin && (
            <Button
              sx={{ mr: 1 }}
              variant="contained"
              onClick={() => {
                setEditInfoModal({
                  message: shippmentModal.typeDiscount,
                  open: true,
                  key: "discount",
                });
              }}
            >
              <CreateIcon sx={{ mr: 1 }} />
              {shippmentModal.edit}
            </Button>
          )}
          <Typography>{JPYmoney(shipPrice?.prices?.discount)}</Typography>
        </Box>
        <Divider />

        {showPenaltyLine() && (
          <>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              width={"100%"}
              mb={1}
              mt={1}
            >
              <Typography>{shippmentModal.penalty}</Typography>
              {isAdmin && (
                <Button
                  sx={{ mr: 1 }}
                  variant="contained"
                  onClick={() => {
                    setEditInfoModal({
                      message: shippmentModal.penalty,
                      open: true,
                      key: "penalty",
                    });
                  }}
                >
                  <CreateIcon sx={{ mr: 1 }} />
                  {shippmentModal.edit}
                </Button>
              )}
              <Typography>{JPYmoney(shipPrice?.prices?.penalty)}</Typography>
            </Box>
            <Divider />
          </>
        )}

        <Box
          display={"flex"}
          justifyContent={"space-between"}
          width={"100%"}
          mb={1}
          mt={1}
        >
          <Typography>{shippmentModal.credits}</Typography>

          {disableBtns() && (
            <Switch
              disabled={
                disableBtns() && data?.deliverie?.status !== "waitingPayment"
                  ? true
                  : false
              }
              value={switchUseMyCredits}
              defaultChecked={switchUseMyCredits}
              size={"small"}
              color="warning"
              onChange={async (_e, check) => {
                setSwitchUseMyCredits(check);
                await updateCustomOptions("useMyCredits", check);
              }}
            />
          )}

          <Typography>{JPYmoney(shipPrice?.credits?.creditUsed)}</Typography>
        </Box>
        <Divider />
        {showPaymentOptions() && (
          <>
            <GenericSelect
              formWidth="100%"
              onChange={(value) => {
                setPaymentChoice(value);
                updateCustomOptions("paymentChoice", value);
              }}
              value={paymentChoice}
              placeholder={orderModal.payment}
              name="paymentChoice"
              emptyText="Select payment method"
              options={paymentChoices}
              disabled={
                disableBtns() && data?.deliverie?.status !== "waitingPayment"
                  ? true
                  : false
              }
              hasWarning={deliverie?.paymentChoice === ""}
            />

            <Divider />
          </>
        )}

        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          width={"100%"}
          mb={1}
          mt={1}
        >
          <Typography>{shippmentModal.paymentTax}</Typography>
          <Typography>{JPYmoney(shipPrice?.prices?.paymentTax)}</Typography>
        </Box>
        <Divider />

        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          width={"100%"}
          mb={1}
          mt={1}
        >
          <Typography>Total</Typography>
          <Typography>{JPYmoney(shipPrice?.prices?.remainingValue)}</Typography>
        </Box>
        <Divider />
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          width={"100%"}
          mb={1}
          mt={1}
        >
          <Typography>{shippmentModal.declaredValueLabel}</Typography>
          <Button
            sx={{
              color: "white",
            }}
            variant="contained"
            onClick={() => setDeclaredValueModal(true)}
            disabled={disabledDeclaredValueBTN()}
          >
            {shippmentModal.edit}
          </Button>
          <Typography>
            {deliverie?.declaredValue === 0
              ? shippmentModal.notDefined
              : JPYmoney(deliverie?.declaredValue)}
          </Typography>
        </Box>
        <Divider />
      </>
    );
  };

  return (
    <BoxContent
      config={{
        minWidth: "180px",
        p: 3,
        minHeight: 300,
        justifyContent: "center",
      }}
    >
      <Box
        width={"100%"}
        display={"flex"}
        justifyContent={"space-between"}
        flexWrap={"wrap"}
        mb={1}
      >
        <MessageIcon
          hasNewMessage={hasNewMessage()}
          openModal={() => setMessageModal(true)}
          tooltipOpen={messageTooltip}
          text={orderModal.tooltip}
          chatButton
        />
        {/* <WarningMessageYellow message={pixDisabledMessage} /> */}
        {isAdmin && (
          <Button variant="contained" onClick={() => setOpenIdsModal(true)}>
            Abrir IDS
          </Button>
        )}
      </Box>
      <Box display={"flex"} width={"100%"}>
        <GenericSelect
          onChange={(val) => {
            setChoiceIDAddress(val);
            updateCustomOptions("addressID", Number(val));
          }}
          options={
            address?.map((ad) => ({
              label: `${ad?.address} ${ad?.adressNumber} - ${ad?.city}/${ad?.country}`,
              value: String(ad?.id),
            })) ?? []
          }
          placeholder={shippmentModal.address}
          value={choiceIDAddress}
          name="address"
          disabled={disableBtns()}
        />
        <CopyClipboardBTN
          text={`${addressStrings.address} ${addressToCopy?.address} ${addressToCopy?.adressNumber} \n ${addressStrings.cityCountry} ${addressToCopy?.city}/${addressToCopy?.country} \n ${addressStrings.state} ${addressToCopy?.state}  \n ${addressStrings.postalCode} ${addressToCopy?.postalCode} \n ${addressStrings.complement} ${addressToCopy?.addressComplement} \n ${addressStrings.newComplement} ${addressToCopy?.addressNewComplement} \n ${addressStrings.receiver} ${addressToCopy?.receiver} \n ${addressStrings.ssn} ${addressToCopy?.ssn}`}
        />
      </Box>
      <Typography variant="h2">{shippmentModal.shipChoice}</Typography>
      <Box
        display={"flex"}
        justifyContent={"center"}
        flexWrap={"wrap"}
        p={2}
        gap={2}
        width={"100%"}
      >
        <Button
          variant="contained"
          sx={{
            fontSize: 11,
            bgcolor: amber[500],
            width: isMobile ? "100%" : "135px",
            color: "white",
            border:
              choiceShippment === "smallPacket" ? "3px solid green" : "none",
            whiteSpace: isMobile ? "wrap" : "nowrap",
            "&:disabled": {
              color: "white",
              bgcolor:
                disableBtns() && choiceShippment === "smallPacket"
                  ? amber[500]
                  : grey[300],
            },
          }}
          onClick={() => {
            setChoiceShippment("smallPacket");
            changeShippmentType("smallPacket");
          }}
          disabled={disableBtns() || prdKG + oldPrdKG > 2000}
        >
          Small Packet
        </Button>
        <Button
          variant="contained"
          sx={{
            fontSize: 11,
            bgcolor: blue[500],
            width: isMobile ? "100%" : "135px",
            color: "white",
            border: choiceShippment === "ems" ? "3px solid green" : "none",
            "&:disabled": {
              color: "white",
              bgcolor:
                disableBtns() && choiceShippment === "ems"
                  ? blue[500]
                  : grey[300],
            },
          }}
          onClick={() => {
            setChoiceShippment("ems");
            changeShippmentType("ems");
          }}
          disabled={disableBtns()}
        >
          EMS
        </Button>
        <Button
          variant="contained"
          sx={{
            fontSize: 11,
            bgcolor: grey[900],
            width: isMobile ? "100%" : "135px",
            color: "white",
            border: choiceShippment === "surface" ? "3px solid green" : "none",
            "&:disabled": {
              color: "white",
              bgcolor:
                disableBtns() && choiceShippment === "surface"
                  ? grey[900]
                  : grey[300],
            },
          }}
          onClick={() => {
            setChoiceShippment("surface");
            changeShippmentType("surface");
          }}
          disabled={disableBtns()}
        >
          Sal Ship Parcel
        </Button>

        <Button
          variant="contained"
          sx={{
            fontSize: 11,
            bgcolor: deepPurple[500],
            width: isMobile ? "100%" : "135px",
            color: "white",
            border:
              choiceShippment === "airParcel" ? "3px solid green" : "none",
            "&:disabled": {
              color: "white",
              bgcolor:
                disableBtns() && choiceShippment === "airParcel"
                  ? deepPurple[500]
                  : grey[300],
            },
          }}
          onClick={() => {
            setChoiceShippment("airParcel");
            changeShippmentType("airParcel");
          }}
          disabled={disableBtns()}
        >
          Sal Air Parcel
        </Button>

        {/* <Button
          variant="contained"
          sx={{
            fontSize: 11,
            bgcolor: orange[900],
            width: isMobile ? "100%" : "135px",
            color: "white",
            border: choiceShippment === "DHL" ? "3px solid green" : "none",
            "&:disabled": {
              color: "white",
              bgcolor:
                disableBtns() && choiceShippment === "DHL"
                  ? orange[900]
                  : grey[300],
            },
          }}
          onClick={() => {
            setChoiceShippment("DHL");
            changeShippmentType("DHL");
          }}
          disabled={disableBtns()}
        >
          DHL
        </Button> */}
      </Box>

      <Box width={"97%"}>
        <Divider />
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          width={"100%"}
          mb={1}
          mt={1}
        >
          <Typography>{shippmentModal.shippmentID}</Typography>
          <Typography>{deliverie?.id}</Typography>
        </Box>
        <Divider />
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          width={"100%"}
          mb={1}
          mt={1}
        >
          <Typography>{shippmentModal.createdAt}</Typography>
          <Typography>{formatedDate(deliverie?.created)}</Typography>
        </Box>
        <Divider />
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          flexWrap={"wrap"}
          alignItems={"center"}
          width={"100%"}
          mb={1}
          mt={1}
        >
          <Typography>{shippmentModal.shippedAt}</Typography>
          {isAdmin ? (
            <CustomDatePicker
              onChangeDate={(val) => changeShippedDate(val)}
              label={orderModal.shippedAt}
              date={
                formatedDate(deliverie?.shipped) === "-"
                  ? new Date()
                  : new Date(deliverie?.shipped)
              }
            />
          ) : (
            <Typography>{formatedDate(deliverie?.shipped)}</Typography>
          )}
        </Box>
        <Divider />
        <Box
          display={"flex"}
          flexWrap={"wrap"}
          justifyContent={"space-between"}
          alignItems={"center"}
          width={"100%"}
          mb={1}
          mt={1}
        >
          <Typography>{shippmentModal.trackingNumber}</Typography>
          <Box
            display="flex"
            flexWrap={"wrap"}
            rowGap={1}
            alignItems={"center"}
            justifyContent={"space-between"}
            width={"100%"}
          >
            <Box display="flex" alignItems={"center"}>
              {isAdmin && (
                <Button
                  sx={{ mr: 1 }}
                  variant="contained"
                  onClick={() => {
                    setEditInfoModal({
                      message: "Digite o novo código",
                      open: true,
                      key: "trackingCode",
                    });
                  }}
                >
                  <CreateIcon sx={{ mr: 1 }} />
                  {shippmentModal.edit}
                </Button>
              )}
              <Typography textAlign={"right"} sx={{ width: 150 }}>
                {deliverie?.trackingCode ?? "-"}
              </Typography>
            </Box>
            {deliverie?.trackingCode &&
              deliverieAddress?.country === "Brazil" && (
                <Button
                  sx={{ mr: 1 }}
                  variant="contained"
                  onClick={() => setTrackingModal(true)}
                >
                  <LocalShippingIcon sx={{ mr: 1 }} />
                  {shippmentModal.track}
                </Button>
              )}
          </Box>
        </Box>
        <Divider />

        <Typography variant="h1" mt={2}>
          Extras
        </Typography>

        <FormControlLabel
          control={
            <Checkbox
              checked={checkBoxes.reinforcedBox}
              disabled={disableBtns()}
              defaultChecked={Boolean(deliverie?.reinforcedBox)}
              onChange={(e) => {
                setCheckBoxes((old) => ({
                  ...old,
                  reinforcedBox: e.target.checked,
                }));
                updateCustomOptions("reinforcedBox", e.target.checked);
              }}
            />
          }
          label={`${shippmentModal.reinforcedBox} + ${JPYmoney(
            siteConfig?.reinforcedBoxPrice
          )}`}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={checkBoxes.plasticBubble}
              disabled={disableBtns()}
              defaultChecked={Boolean(deliverie?.plasticBubble)}
              onChange={(e) => {
                setCheckBoxes((old) => ({
                  ...old,
                  plasticBubble: e.target.checked,
                }));
                updateCustomOptions("plasticBubble", e.target.checked);
              }}
            />
          }
          label={`${shippmentModal.plasticB} + ${JPYmoney(
            siteConfig?.plasticBubblePrice
          )}`}
        />
        <FormControlLabel
          control={
            <Checkbox
              disabled={disableBtns()}
              checked={checkBoxes.securityFee}
              defaultChecked={deliverie?.securityFee}
              onChange={(e) => {
                setCheckBoxes((old) => ({
                  ...old,
                  securityFee: e.target.checked,
                }));
                updateCustomOptions("securityFee", e.target.checked);
              }}
            />
          }
          label={`${shippmentModal.securityFee}+ ${JPYmoney(500)}`}
        />
        <Divider sx={{ mb: 2 }} />
      </Box>

      {!disableBtns() && (
        <BasicPopover
          btnName={shippmentModal.popover}
          handleClickItem={(item) =>
            setSelectedProducts((old) => [...old, item])
          }
          removeItemFromSelected={(item) =>
            setSelectedProducts((old) =>
              old?.filter((oldIds) => oldIds?.id !== item?.id)
            )
          }
          itemsSelected={selectedProducts}
          oldProducts={oldProducts}
          list={products?.map((item) => ({
            id: item?.id,
            name: item?.name,
            imgUrl: item?.ProductImages[0]?.code,
            stock: item?.leftInStock,
            kg: item?.kg,
            qtd: 0,
            packageRemove: item?.removePackage,
            adminRemovePackage: item?.adminRemovePackage,
          }))}
        />
      )}

      {oldProducts.length > 0 && (
        <Typography variant="h1" mb={2}>
          {shippmentModal.oldProducts}
        </Typography>
      )}

      <Box display="flex" flexWrap={"wrap"} gap={2} justifyContent={"center"}>
        {oldProducts.map((item, index) => (
          <Card sx={{ maxWidth: 198 }} key={index}>
            <Typography textAlign={"center"}>
              Product id: <span style={{ color: "green" }}>{item?.id} </span>
            </Typography>
            <CardMedia
              component="img"
              height="194"
              image={item?.imgUrl}
              alt="Paella dish"
              sx={{ maxWidth: 198 }}
            />

            <IconButton
              onClick={() => deleteOldProds(item.id, item.qtd)}
              aria-label="delete"
              sx={{
                position: "absolute",
                bgcolor: "red",
                marginTop: "-190px",
                marginLeft: "155px",
                color: "white",
                zIndex: 12651651651561561,
                "&:hover": { bgcolor: "pink" },
              }}
              disabled={disableBtns()}
            >
              <DeleteIcon />
            </IconButton>
            <GenericInput
              placeholder={shippmentModal.quantity}
              onChange={(value) => updateQTDOldProducts(item, Number(value))}
              inputType="number"
              inputName={String(item?.id)}
              hasIcon={false}
              error={oldError.some((prdError) => prdError.id === item?.id)}
              errorMessage={
                oldError.some((prdError) => prdError.id === item?.id) &&
                "Verify quantity"
              }
              defaultValue={item?.qtd}
              disabled={disableBtns()}
            />
            <Typography textAlign={"center"} mb={1}>
              {shippmentModal.leftInStock} {item?.stock}
            </Typography>
            <Typography
              textAlign={"center"}
              color="red"
              sx={{ wordBreak: "break-word" }}
            >
              {verifyPackText(item.adminRemovePackage, item.packageRemove)}
            </Typography>
          </Card>
        ))}
      </Box>

      {selectedProducts.length > 0 && (
        <Typography variant="h1" mb={2} mt={2}>
          {shippmentModal.selectedItems}
        </Typography>
      )}

      <Box display="flex" flexWrap={"wrap"} gap={2}>
        {selectedProducts.map((item, index) => (
          <Card sx={{ maxWidth: 345 }} key={index}>
            <CardMedia
              component="img"
              height="194"
              image={item?.imgUrl}
              alt="Paella dish"
              sx={{ maxWidth: 198 }}
            />

            <IconButton
              onClick={() =>
                setSelectedProducts((old) =>
                  old?.filter((oldIds) => oldIds?.id !== item?.id)
                )
              }
              disabled={disableBtns()}
              aria-label="delete"
              sx={{
                position: "absolute",
                bgcolor: "red",
                marginTop: "-190px",
                marginLeft: "155px",
                zIndex: 12651651651561561,
                color: "white",
                "&:hover": { bgcolor: "pink" },
              }}
            >
              <DeleteIcon />
            </IconButton>
            <GenericInput
              placeholder="Quantity"
              onChange={(qtd, id) => handleChangeQTD(Number(qtd), Number(id))}
              inputType="number"
              inputName={String(item?.id)}
              hasIcon={false}
              error={error.some((prdError) => prdError.id === item?.id)}
              errorMessage={
                error.some((prdError) => prdError.id === item?.id) &&
                "Verify quantity"
              }
              disabled={disableBtns()}
            />
            <Typography textAlign={"center"} mb={1}>
              Stock {item?.stock}
            </Typography>
          </Card>
        ))}
      </Box>
      <Typography mt={1}>
        Total {prdKG + oldPrdKG > 999 ? "KG" : "g"}:
        {(prdKG + oldPrdKG).toLocaleString()}
      </Typography>
      {oldPrdKG + prdKG > 2000 && (
        <FormHelperText sx={{ bgcolor: amber[100], borderRadius: 2, p: 1 }}>
          <WarningAmberRoundedIcon sx={{ marginBottom: -0.8 }} />
          {shippmentModal.kgWarming}
        </FormHelperText>
      )}
      {shipError.open && (
        <FormHelperText
          sx={{ bgcolor: amber[100], borderRadius: 2, p: 1, mt: 2 }}
          error
        >
          <WarningAmberRoundedIcon sx={{ marginBottom: -0.8 }} />
          {shipError.errorMessage}
        </FormHelperText>
      )}

      {selectedProducts.length > 0 && (
        <Button
          disabled={selectedProducts.length === 0 || disableBtns()}
          sx={{ mt: 3 }}
          fullWidth
          variant="contained"
          onClick={AddItens}
        >
          {shippmentModal.addProducts}
        </Button>
      )}

      {/* parte do frete */}
      {prdKG + oldPrdKG > 0 && (
        <Box width={"95%"}>
          <FadeInContainer>
            {deliverie?.shippmentType === "DHL" && isAdmin && (
              <>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  width={"98%"}
                  mb={1}
                  mt={1}
                  sx={{
                    background:
                      deliverie?.dhlPrice <= 0 ? amber[200] : teal[200],
                    borderRadius: 2,
                    p: 1,
                  }}
                >
                  <Typography>DHL price</Typography>

                  <Button
                    sx={{ mr: 1 }}
                    variant="contained"
                    onClick={() => {
                      setEditInfoModal({
                        message: "Set DHL price for this shippment",
                        open: true,
                        key: "dhlPrice",
                      });
                    }}
                  >
                    <CreateIcon sx={{ mr: 1 }} />
                    {shippmentModal.edit}
                  </Button>

                  <Typography>{JPYmoney(deliverie?.dhlPrice)}</Typography>
                </Box>
                <Divider />
              </>
            )}
            {deliverie?.shippmentType !== "DHL" ? (
              <ShippmentPriceComponent />
            ) : deliverie?.shippmentType === "DHL" &&
              deliverie?.dhlPrice > 0 ? (
              <ShippmentPriceComponent />
            ) : (
              <Typography
                sx={{
                  background: amber[100],
                  p: 1,
                  borderRadius: 2,
                  textAlign: "center",
                }}
              >
                {isAdmin
                  ? "Please ADMIN, insert the DHL price for system calculate the prices"
                  : "Our store will calculate the shippment fee, please wait"}
              </Typography>
            )}

            {deliverie?.paymentChoice === "pix" && (
              <>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  width={"100%"}
                  mb={1}
                  mt={1}
                >
                  <Typography>BRL (Reais)</Typography>
                  <Typography>{shipPrice?.totalPix}</Typography>
                </Box>
                <Divider />
              </>
            )}

            {isAdmin && deliverie?.paymentIntent !== "" && (
              <Box
                sx={{
                  border: "1px solid grey",
                  borderRadius: 2,
                  mb: 2,
                  mt: 2,
                  p: 1,
                }}
              >
                <Typography textAlign={"center"}>Pay by credit card</Typography>
                <Typography>
                  Payment intent: {deliverie?.paymentIntent}{" "}
                </Typography>
                <Typography sx={{ wordBreak: "break-all" }}>
                  Charge: {deliverie?.chargeID}
                </Typography>
              </Box>
            )}

            {deliverie?.status === "waitingPayment" &&
              deliverie?.paymentChoice &&
              !isAdmin && (
                <FormHelperText
                  sx={{ bgcolor: amber[100], borderRadius: 2, p: 1, mt: 2 }}
                >
                  <WarningAmberRoundedIcon sx={{ marginBottom: -0.8 }} />
                  {getInfoPaymentChoice()}
                </FormHelperText>
              )}

            {deliverie?.status === "waitingPayment" &&
              deliverie?.paymentChoice === "card" &&
              !isAdmin && (
                <Button
                  sx={{ mt: 3 }}
                  fullWidth
                  variant="contained"
                  onClick={() => {
                    setCreditCardModalOpen(true);
                  }}
                >
                  Click here to pay with credit card
                </Button>
              )}

            {deliverie?.status === "processingPayment" && (
              <>
                <FormHelperText
                  sx={{ bgcolor: amber[100], borderRadius: 2, p: 1 }}
                >
                  <WarningAmberRoundedIcon sx={{ marginBottom: -0.8 }} />
                  Payment has not been fully processed, please click below to
                  verify payment
                </FormHelperText>
                <Button
                  sx={{ mt: 2 }}
                  variant="contained"
                  fullWidth
                  onClick={() =>
                    verifyPayment(deliverie?.chargeID, deliverie?.id)
                  }
                >
                  Verify payment
                </Button>
              </>
            )}
            {deliverie?.status === "notPaid" && (
              <>
                <FormHelperText
                  sx={{ bgcolor: amber[600], borderRadius: 2, p: 1 }}
                >
                  <WarningAmberRoundedIcon sx={{ marginBottom: -0.8 }} />
                  The payment process was not concluded, please contact your
                  card issuer or try again
                </FormHelperText>
                <Button
                  sx={{ mt: 3 }}
                  fullWidth
                  variant="contained"
                  onClick={() => {
                    setCreditCardModalOpen(true);
                  }}
                >
                  Click here to pay with credit card
                </Button>
              </>
            )}
          </FadeInContainer>
        </Box>
      )}
      {/* fim do frete */}

      {showProof() && (
        <FadeInContainer>
          <Typography variant="h1" mb={3} mt={3} textAlign={"center"}>
            {orderModal.proofPayment}
          </Typography>
          {limitSizeFiles >= 500 && (
            <Typography variant="h2" color={"red"} mb={1}>
              Max file size reached
            </Typography>
          )}

          <DropFileInput
            onFileChange={(files) => setFiles(files)}
            oldFiles={deliverie?.PaymentVoucher ?? []}
            fileRemoveAdmin={fileRemoveAdmin}
            disabledDeleteBTN={disableBtnsAdmin()}
          />
        </FadeInContainer>
      )}

      {isAdmin && (
        <Box>
          <Button
            sx={{
              bgcolor: green[500],
              color: "white",
              mt: 3,
              "&:hover": { bgcolor: green[200] },
            }}
            fullWidth
            variant="contained"
            onClick={() =>
              setConfirmModalOpen({
                message: shippmentModal.confirmStatusShipped,
                open: true,
                status: "shipped",
              })
            }
            disabled={disableBtnsAdmin()}
          >
            {shippmentModal.shippedBTN}
          </Button>

          <Button
            sx={{
              bgcolor: amber[500],
              color: "white",
              mt: 3,
              "&:hover": { bgcolor: amber[200] },
            }}
            fullWidth
            variant="contained"
            onClick={() =>
              setConfirmModalOpen({
                message: shippmentModal.confirmStatusWaitingPayment,
                open: true,
                status: "waitingPayment",
              })
            }
            disabled={disableBtnsAdmin()}
          >
            {shippmentModal.waitingPayment}
          </Button>

          <Button
            sx={{
              bgcolor: grey[500],
              color: "white",
              mt: 3,
              "&:hover": { bgcolor: grey[200] },
            }}
            fullWidth
            variant="contained"
            onClick={() =>
              setConfirmModalOpen({
                message: shippmentModal.editDelivery,
                open: true,
                status: "editDelivery",
              })
            }
            disabled={disableBtnsAdmin()}
          >
            {shippmentModal.editShipBTN}
          </Button>

          <Button
            sx={{
              bgcolor: red[500],
              color: "white",
              mt: 3,
              "&:hover": { bgcolor: red[200] },
            }}
            fullWidth
            variant="contained"
            onClick={() =>
              setConfirmModalOpen({
                message: shippmentModal.cancelShip,
                open: true,
                status: "canceled",
              })
            }
            disabled={disableBtnsAdmin()}
          >
            {shippmentModal.cancelBTN}
          </Button>

          <Button
            sx={{
              mt: 3,
              bgcolor: red[900],
              "&:hover": { bgcolor: red[200] },
            }}
            fullWidth
            variant="contained"
            onClick={() => setRefundModal(true)}
            disabled={disableBtnsAdmin()}
          >
            {adminButtons.cancelOrderWithRefund}
          </Button>
        </Box>
      )}

      {!disableBtns() && !isAdmin && (
        <>
          <Button
            sx={{
              bgcolor: amber[500],
              color: "white",
              mt: 3,
              "&:hover": { bgcolor: amber[200] },
            }}
            fullWidth
            variant="contained"
            onClick={() =>
              setConfirmModalOpen({
                message: shippmentModal.updateMessage,
                open: true,
                status: "waitingStore",
              })
            }
            disabled={disableBtnsAdmin() || selectedProducts.length > 0}
          >
            {shippmentModal.updateShip}
          </Button>
          <Button
            sx={{
              bgcolor: red[500],
              color: "white",
              mt: 3,
              "&:hover": { bgcolor: red[200] },
            }}
            fullWidth
            variant="contained"
            onClick={() =>
              setConfirmModalOpen({
                message: shippmentModal.deleteShip,
                open: true,
                status: "canceled",
              })
            }
            disabled={disableBtnsAdmin()}
          >
            {shippmentModal.deleteShipBTN}
          </Button>
        </>
      )}

      {deliverie?.status === "waitingPayment" &&
        deliverie?.paymentChoice !== "card" &&
        !isAdmin && (
          <Button
            sx={{ color: "white", mt: 3 }}
            fullWidth
            variant="contained"
            onClick={async () => {
              await uploadPaymentProof(files);
              updateCustomOptions("status", "paymentSent");
            }}
            disabled={
              deliverie?.PaymentVoucher?.length === 0
                ? files.length === 0 || !deliverie?.paymentChoice
                : false
            }
          >
            {deliverie?.PaymentVoucher?.length === 0
              ? "Upload proof"
              : "Send payment"}
          </Button>
        )}

      {shipPrice?.prices?.remainingValue === 0 &&
        deliverie?.status === "waitingPayment" &&
        prdKG + oldPrdKG > 0 &&
        !isAdmin && (
          <Button
            sx={{ color: "white", mt: 3 }}
            fullWidth
            variant="contained"
            onClick={() => updateCustomOptions("status", "paymentSent")}
          >
            Send payment
          </Button>
        )}

      <CustomModal
        openModal={confirmModalOpen.open}
        setOpenModal={() =>
          setConfirmModalOpen({ message: "", open: false, status: "" })
        }
        width={isSmalldesktop ? "90%" : "30%"}
      >
        <Box display={"flex"} justifyContent={"center"} mb={5} width={"100%"}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"100%"}
          >
            <Typography mb={2} width={315} sx={{ wordBreak: "break-word" }}>
              {confirmModalOpen.message}
            </Typography>

            <Box display={"flex"} justifyContent={"center"} gap={5}>
              <Button
                sx={{ bgcolor: green[500], color: "white" }}
                style={{ width: 100 }}
                onClick={() => {
                  updateDeliveryStatus();
                  setConfirmModalOpen({ message: "", open: false, status: "" });
                }}
              >
                {adminOrder.confirmBtn}
              </Button>
              <Button
                sx={{ bgcolor: red[500], color: "white" }}
                style={{ width: 100 }}
                onClick={() =>
                  setConfirmModalOpen({ message: "", open: false, status: "" })
                }
              >
                {adminOrder.cancelBtn}
              </Button>
            </Box>
          </Box>
        </Box>
      </CustomModal>

      <CustomModal
        openModal={declaredValueModal}
        setOpenModal={() => setDeclaredValueModal(false)}
        width={isSmalldesktop ? "90%" : "30%"}
      >
        <Box display={"flex"} justifyContent={"center"} mb={5} width={"100%"}>
          {/* declared value modal */}
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"100%"}
          >
            <Typography mb={2} width={315} sx={{ wordBreak: "break-word" }}>
              {shippmentModal.declaredValue}
            </Typography>

            <GenericInput
              hasIcon={false}
              onChange={(value) => {
                setDeclaredValue(Number(value));
              }}
              placeholder={shippmentModal.jpyValue}
              inputName="price"
              width={"60%"}
            />

            <Box display={"flex"} justifyContent={"center"} gap={5}>
              <Button
                sx={{ bgcolor: green[500], color: "white" }}
                style={{ width: 100 }}
                onClick={() => {
                  changeDeclaredValue(declaredValue);
                  setDeclaredValueModal(false);
                }}
              >
                {adminOrder.confirmBtn}
              </Button>
              <Button
                sx={{ bgcolor: red[500], color: "white" }}
                style={{ width: 100 }}
                onClick={() =>
                  setConfirmModalOpen({ message: "", open: false, status: "" })
                }
              >
                {adminOrder.cancelBtn}
              </Button>
            </Box>
          </Box>
        </Box>
      </CustomModal>

      <CustomModal
        openModal={editInfoModal.open}
        setOpenModal={() =>
          setEditInfoModal({ message: "", open: false, key: "" })
        }
        width={isSmalldesktop ? "90%" : "30%"}
      >
        {/* tracking code modal */}
        <Box display={"flex"} justifyContent={"center"} mb={5} width={"100%"}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"100%"}
          >
            <Typography>
              Now: {editInfoModalFunction(editInfoModal.key)?.title}
            </Typography>
            <Typography>{editInfoModal.message}</Typography>

            <Box
              display="flex"
              justifyContent={"center"}
              alignItems={"center"}
              width={"100%"}
            >
              <GenericInput
                hasIcon={false}
                onChange={(value) => {
                  editInfoModalFunction(editInfoModal.key)?.state(value);
                }}
                placeholder={editInfoModalFunction(editInfoModal.key)?.text}
                inputName="price"
                width={"60%"}
              />
              {editInfoModal.key === "customBoxWeight" && (
                <Button
                  sx={{
                    bgcolor: red[500],
                    color: "white",
                    height: 32,
                    mt: 1.5,
                  }}
                  onClick={() => {
                    updateCustomOptions("customBoxWeight", 0);
                    setEditInfoModal({ message: "", open: false, key: "" });
                  }}
                >
                  <DeleteIcon />
                </Button>
              )}
            </Box>
            <Box display={"flex"} justifyContent={"center"} gap={5}>
              <Button
                sx={{ bgcolor: green[500], color: "white" }}
                style={{ width: 100 }}
                onClick={() => {
                  editInfoModalFunction(editInfoModal.key)?.submit();
                  setEditInfoModal({ message: "", open: false, key: "" });
                }}
              >
                {adminOrder.confirmBtn}
              </Button>
              <Button
                sx={{ bgcolor: red[500], color: "white" }}
                style={{ width: 100 }}
                onClick={() =>
                  setEditInfoModal({ message: "", open: false, key: "" })
                }
              >
                {adminOrder.cancelBtn}
              </Button>
            </Box>
          </Box>
        </Box>
      </CustomModal>

      <CustomModal
        openModal={messageModal}
        setOpenModal={setMessageModal}
        width={isSmalldesktop ? "90%" : "30%"}
      >
        <MessageBox
          data={data?.deliverie?.WarningMessage}
          sendMessage={(newMessage) =>
            sendNewMessage({
              description: newMessage,
              orderID: data?.deliverie?.id,
              type: user.user.role,
              userID: user.user.id,
              messageType: "deliverie",
            })
          }
          readMessages={() => readAllMessages(data?.deliverie?.id, "deliverie")}
        />
      </CustomModal>

      <CustomModal
        openModal={refundModal}
        setOpenModal={() => setRefundModal(false)}
        width={isSmalldesktop ? "90%" : "30%"}
      >
        <Box display={"flex"} justifyContent={"center"} mb={5} width={"100%"}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"100%"}
          >
            <Typography mb={2} width={315} sx={{ wordBreak: "break-word" }}>
              {adminOrder.changeStatus} "Cancel with refund"?
            </Typography>
            <Typography
              sx={{ wordBreak: "break-word" }}
              width={320}
              mb={2}
              color="red"
            >
              {adminOrder.canceledErrorFullValue}
            </Typography>

            <>
              <Typography variant="h1">{orderModal.choiceRefund}</Typography>
              <Box
                display="flex"
                width={320}
                justifyContent={"space-evenly"}
                flexWrap={"wrap"}
                gap={2}
                p={2}
              >
                <Box sx={{ width: "40%" }}>
                  <Typography textAlign={"center"}>
                    {shippmentModal.credits}
                  </Typography>
                  <Button
                    sx={{ width: "100%" }}
                    variant="outlined"
                    onClick={() =>
                      setRefundValue(shipPrice?.credits?.creditUsed)
                    }
                  >
                    {JPYmoney(shipPrice?.credits?.creditUsed)}
                  </Button>
                </Box>
                <Box sx={{ width: "40%" }}>
                  <Typography textAlign={"center"}>Ship price</Typography>
                  <Button
                    sx={{ width: "100%" }}
                    variant="outlined"
                    onClick={() =>
                      setRefundValue(shipPrice?.prices?.remainingValue)
                    }
                  >
                    {JPYmoney(shipPrice?.prices?.remainingValue)}
                  </Button>
                </Box>
              </Box>
              <TextField
                id="filled-basic"
                variant="filled"
                label="Total refund ￥"
                sx={{ maxWidth: 150, mb: 3 }}
                size="small"
                value={refundValue}
                onChange={(e) => setRefundValue(Number(e.target.value))}
                type="number"
              />
            </>

            <Box display={"flex"} justifyContent={"center"} gap={5}>
              <Button
                sx={{ bgcolor: green[500], color: "white" }}
                style={{ width: 100 }}
                onClick={cancelAndRefund}
              >
                {adminOrder.confirmBtn}
              </Button>
              <Button
                sx={{ bgcolor: red[500], color: "white" }}
                style={{ width: 100 }}
                onClick={() => setRefundModal(false)}
              >
                {adminOrder.cancelBtn}
              </Button>
            </Box>
          </Box>
        </Box>
      </CustomModal>

      <TrackingPackage
        open={trackingModal}
        setOpen={() => setTrackingModal(false)}
        trackingCode={deliverie?.trackingCode}
      />

      <PaymentModalCreditCard
        open={creditCardModalOpen}
        setOpen={() => setCreditCardModalOpen(false)}
        itensInfo={{
          itemID: deliverie?.id ?? 0,
          totalValue: shipPrice?.prices?.remainingValue ?? 0,
          type: "Shippment",
        }}
      />

      <CustomModal
        openModal={openIdsModal}
        setOpenModal={setOpenIdsModal}
        width={isSmalldesktop ? "90%" : "30%"}
        title="IDs dos produtos na entrega"
      >
        <PrintComponentProductIDS
          productIDs={deliverie?.Delivery?.map(
            (item, index) => item?.ArrivedProducts?.id
          )}
        />
      </CustomModal>
    </BoxContent>
  );
}
