import { Box } from "@mui/material";
import { FadeInContainer } from "../../../components/AtomicDesign/Atoms";
import { BoxWithTitle } from "../../../components/AtomicDesign/Molecules";
import useTreasureProducts from "../../../hooks/context/useTreasureProducts";
import { useTranslate } from "../../../hooks/useTranslate";
import useAuth from "../../../hooks/context/useAuth";
import { CustomModal } from "../../../components/AtomicDesign/Organisms";
import { useBreakpoints } from "../../../hooks/useBreakpoins";
import { useState } from "react";
import { TreasureProductsOrderCards } from "./components/OrderList";
import { TreasureProductOrderDetails } from "./components/OrderDetails";

export function TreasureProductsOrders() {
  const {
    cleanFilters,
    loading,
    getFilterStorage,
    setFilters,
    setAttData,
    treasureProductsOrdersDataMemo,
    treasureProductsOrderRecords,
    setHandleIDProductOrder,
    treasureProductOrder,
    updateOrderStatus,
    verifyPayTreasureProductOrder,
  } = useTreasureProducts();
  const { strings } = useTranslate();
  const { language } = useAuth();
  const { isSmalldesktop } = useBreakpoints();
  const [modalOpen, setModalOpen] = useState(false);

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <FadeInContainer>
      <Box
        display={"flex"}
        flexWrap={"wrap"}
        justifyContent={"center"}
        alignContent={"center"}
        gap={5}
        p={1}
      >
        <BoxWithTitle
          title={strings.newOrderPage.title[language]}
          width="100%"
          childrenProps={{ height: "100%" }}
          padding={1}
          // filter
          hasFilter
          onSelectField={(val) => setFilters("key", val)}
          selectedField={getFilterStorage().key}
          onChangeTextField={(val) => {
            setFilters("searchTerm", val);
            setAttData((bool: boolean) => !bool);
          }}
          searchTerm={getFilterStorage().searchTerm}
          onClickCleanFilter={cleanFilters}
          // filter
          // pagnation
          hasPagination
          itensPerpage={(val) => {
            setFilters("itensPerPage", val);
            setAttData((bool: boolean) => !bool);
          }}
          itensPerPage={getFilterStorage().itensPerPage} // quantidade
          howManyPages={Math.ceil(
            treasureProductsOrderRecords / getFilterStorage().itensPerPage
          )}
          onChangePage={(page) => {
            setFilters("offset", page - 1);
            setAttData((bool: boolean) => !bool);
          }}
          // pagnation
        >
          <TreasureProductsOrderCards
            data={treasureProductsOrdersDataMemo}
            onClickOrder={(order) => {
              setHandleIDProductOrder(order.id);

              setModalOpen(true);
            }}
            isLoading={loading.ordersLoading}
          />
        </BoxWithTitle>
      </Box>
      <CustomModal
        openModal={modalOpen}
        setOpenModal={closeModal}
        width={isSmalldesktop ? "90%" : "40%"}
        title={`Order n° ${treasureProductOrder?.id}`}
      >
        <TreasureProductOrderDetails
          data={treasureProductOrder}
          isLoading={loading.oneOrder}
          submitNewStatus={updateOrderStatus}
          verifyPay={(data) => verifyPayTreasureProductOrder(data)}
        />
      </CustomModal>
    </FadeInContainer>
  );
}
