import { Box, Typography, Paper, Chip } from "@mui/material";

import { useBreakpoints } from "../../../../hooks/useBreakpoins";

import { useJPYmoney } from "../../../../hooks/useJPYmoney";
import { UseDateTime } from "../../../../hooks/useDateTime";
import { useCommonData } from "../../../../hooks/useCommonData";
import { amber, grey, red, teal } from "@mui/material/colors";
import {
  CircularLoading,
  NoData,
} from "../../../../components/AtomicDesign/Atoms";
import "../../TreasureProducts/styles.css";
interface Props {
  data: TreasureProductOrder[];
  onClickOrder(val: TreasureProductOrder): void;
  isLoading?: boolean;
}

export function TreasureProductsOrderCards({
  data,
  onClickOrder,
  isLoading,
}: Props) {
  const { treasureProductBuyModal } = useCommonData();
  const { formatedDate } = UseDateTime();
  const { isSmalldesktop, isMobile } = useBreakpoints();
  const { JPYmoney } = useJPYmoney();

  const translateStatus = (st: TreasureProductsOrderStatus) => {
    const status = {
      paymentSent: {
        text: treasureProductBuyModal.paymentSent,
        color: teal[400],
      },
      waitingPayment: {
        text: treasureProductBuyModal.waitingPayment,
        color: amber[400],
      },
      pickingFromStock: {
        text: treasureProductBuyModal.pickingFromStock,
        color: amber[800],
      },
      registeredSuite: {
        text: treasureProductBuyModal.registeredSuite,
        color: teal[800],
      },
      canceled: { text: treasureProductBuyModal.canceled, color: red[400] },
      canceledWithRefund: {
        text: treasureProductBuyModal.canceledWithRefund,
        color: red[800],
      },
      notPaid: { text: treasureProductBuyModal.notPaid, color: red[900] },
    };
    return status[st as keyof typeof status];
  };

  const imgsize = isMobile ? 100 : 150;
  const fontSize = isMobile ? 14 : 18;

  return (
    <Box
      width={"100%"}
      display="flex"
      flexWrap={"wrap"}
      gap={1}
      justifyContent={"center"}
    >
      {isLoading ? (
        <CircularLoading />
      ) : data && data?.length > 0 ? (
        data?.map((item, index) => (
          <Box
            display={"flex"}
            flexWrap={"wrap"}
            justifyContent={"center"}
            gap={3}
            key={index}
            width={isMobile ? "100%" : "auto"}
            sx={{
              transition: "0.4s ease",
              "&: hover": {
                cursor: "pointer",
                transform: "scale(1.05)",
              },
            }}
            onClick={() => onClickOrder(item)}
          >
            <Paper
              elevation={4}
              sx={{
                display: "flex",
                gap: 1,
                p: 1,
                height: "fit-content",
                margin: isSmalldesktop ? "10px 0" : "0",
                width: isSmalldesktop ? "100%" : 380,
              }}
            >
              <img
                src={`${item?.treasureProductsXOrders[0]?.treasureProducts?.treasureProductsImages[0]?.code}`}
                width={imgsize}
                height={imgsize}
                alt="figure"
                style={{
                  maxHeight: imgsize,
                  maxWidth: imgsize,
                  objectFit: "contain",
                  borderRadius: 5,
                  background: grey[200],
                  marginBottom: 8,
                }}
              />
              <Box width={"100%"}>
                <Typography
                  fontSize={fontSize}
                  style={{ wordBreak: "break-word" }}
                >
                  Order n° {item?.id} - {formatedDate(item?.createdAt)}
                </Typography>
                <Typography
                  fontSize={fontSize}
                  color={grey[600]}
                  style={{ wordBreak: "break-word" }}
                  className="elip2"
                >
                  {item?.treasureProductsXOrders[0]?.treasureProducts?.name}
                </Typography>
                <Typography
                  fontSize={fontSize}
                  color={grey[600]}
                  style={{ wordBreak: "break-word" }}
                  className="elip2"
                >
                  Product id:{" "}
                  {item?.treasureProductsXOrders[0]?.treasureProductsId}
                </Typography>

                <Typography
                  style={{
                    wordBreak: "break-word",
                    fontSize: fontSize,
                  }}
                  mt={1}
                  color={amber[900]}
                >
                  {JPYmoney(item?.boughtPrice * item?.quantity)}
                </Typography>
                <Typography
                  fontSize={fontSize}
                  style={{ wordBreak: "break-word" }}
                >
                  Suite {item?.suiteId}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "flex-end",
                  }}
                >
                  <Chip
                    label={translateStatus(item?.status).text}
                    style={{
                      background: translateStatus(item?.status).color,
                      color: "white",
                      fontWeight: 500,
                    }}
                  />
                </Box>
              </Box>
            </Paper>
          </Box>
        ))
      ) : (
        <NoData text="No data" />
      )}
    </Box>
  );
}
