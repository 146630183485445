import { Box, Typography } from "@mui/material";
import { amber, grey, red } from "@mui/material/colors";
import { useBreakpoints } from "../../../../hooks/useBreakpoins";
import { useJPYmoney } from "../../../../hooks/useJPYmoney";
import { CircularLoading } from "../../../../components/AtomicDesign/Atoms";

interface Props {
  treasureProductsDataMemo: TreasureProducts[];
  handleClickProduct: (productID: number) => void;
  isLoading: boolean;
}

export function TreasureProductCards({
  treasureProductsDataMemo,
  handleClickProduct,
  isLoading,
}: Props) {
  const { isMobile } = useBreakpoints();
  const { JPYmoney } = useJPYmoney();
  return (
    <>
      {isLoading ? (
        <CircularLoading />
      ) : (
        treasureProductsDataMemo &&
        treasureProductsDataMemo?.length > 0 &&
        treasureProductsDataMemo?.map((product, index) => (
          <Box
            key={index}
            onClick={() => handleClickProduct(product?.id)}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            sx={{
              maxHeight: 435,
              boxShadow: "0px 4px 14px -2px #0000002E",
              width: isMobile ? 140 : 200,
              height: "Hug (410x)px",
              padding: "8px",
              gap: "8px",
              borderRadius: "12px",
              opacity: " 0px",
              transition: "0.4s ease",
              background: "#FFFFFF",
              "&: hover": {
                cursor: "pointer",
                transform: "scale(1.05)",
              },
              // "&:not(:hover)": { zoom: 0, transition: "200ms ease-in-out" },
            }}
          >
            <img
              src={product?.treasureProductsImages[0]?.code}
              width={isMobile ? 150 : 150}
              height={isMobile ? 150 : 150}
              alt={"product"}
              style={{ borderRadius: "12px", objectFit: "contain" }}
            />
            <Box height={24} display={"flex"} gap={1}>
              {product?.itemsQuantity === 0 && (
                <Typography
                  sx={{
                    fontSize: isMobile ? 12 : 16,
                    fontWeight: 700,
                    textAlign: "left",
                    color: red[400],
                    wordBreak: "break-word",
                  }}
                >
                  Esgotado
                </Typography>
              )}
            </Box>
            <Typography
              sx={{
                mt: isMobile ? 3 : 0,
                height: 48,
              }}
              className="elip"
            >
              {product?.name}
            </Typography>
            <Typography
              sx={{
                height: 24,
                color: grey[500],
              }}
              className="elip2"
            >
              {product?.type}
            </Typography>
            <Typography
              sx={{
                height: 24,
                color: grey[500],
              }}
              className="elip2"
            >
              {product?.origin}
            </Typography>

            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 500,
                textAlign: "left",
                color: amber[800],
                minHeight: 52,
              }}
            >
              {JPYmoney(product?.price)}
            </Typography>
          </Box>
        ))
      )}
    </>
  );
}
