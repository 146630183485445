import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { CustomModal } from "../../Organisms";
import { UseDateTime } from "../../../../hooks/useDateTime";
import UpdateIcon from "@mui/icons-material/Update";
import EastIcon from "@mui/icons-material/East";
import { blue, grey, red, teal } from "@mui/material/colors";
import { useJPYmoney } from "../../../../hooks/useJPYmoney";
import { FadeInContainer } from "../../Atoms";
import useAuth from "../../../../hooks/context/useAuth";
import { useBreakpoints } from "../../../../hooks/useBreakpoins";
import { SimpleConfirmModal } from "../SimpleConfirmModal";
import { useTranslate } from "../../../../hooks/useTranslate";

interface Props {
  bidsList: AuctionBids[];
  createBid(data: UpdateBids): void;
  acceptBid(data: UpdateBids): void;
  loading: boolean;
  showSendButton: boolean;
}

export function AuctionBidSteps({
  bidsList,
  createBid,
  acceptBid,
  loading,
  showSendButton,
}: Props) {
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [updateBidModal, setUpdateBidModal] = useState(false);
  const [newBidValue, setNewBidValue] = useState(0);
  const [errorNewBid, setErrorNewBid] = useState(false);
  const [sendTimer, setSendTimer] = useState(10);
  const [openSimpleModal, setOpenSimpleModal] = useState({
    open: false,
    type: "",
  });
  const { formatedDate } = UseDateTime();
  const { JPYmoney } = useJPYmoney();
  const { isAdmin, language } = useAuth();
  const { strings } = useTranslate();
  const { isMobile } = useBreakpoints();

  const createNewBid = async () => {
    try {
      await createBid({
        bid: newBidValue,
        status: "new",
        orderToBuyID: bidsList[0]?.orderToBuyId,
      });
    } catch (e: any) {
      console.error(e);
    } finally {
      setUpdateBidModal(false);
    }
  };

  const acceptNewBidAdmin = async () => {
    const findBidNew = bidsList.find((bid) => bid.status === "new");
    if (!findBidNew) return;
    try {
      await acceptBid({
        id: findBidNew?.id,
        bid: findBidNew?.bid,
        status: "active",
        orderToBuyID: bidsList[0]?.orderToBuyId,
      });
    } catch (e: any) {
      console.error(e);
    } finally {
      setUpdateBidModal(false);
    }
  };

  const refuseNewBidAdmin = async () => {
    const findBidNew = bidsList.find((bid) => bid.status === "new");
    if (!findBidNew) return;
    try {
      await acceptBid({
        id: findBidNew?.id,
        bid: findBidNew?.bid,
        status: "inactive",
        orderToBuyID: bidsList[0]?.orderToBuyId,
      });
    } catch (e: any) {
      console.error(e);
    } finally {
      setUpdateBidModal(false);
    }
  };

  const filterByStatus = () => {
    return bidsList
      .filter((bid) => bid.status === "active" || bid.status === "new")
      .sort((a, b) => (a.status === "active" ? -1 : 1));
  };

  const bidColor = (status: AuctionBidsStatus) => {
    switch (status) {
      case "active":
        return blue[200];
      case "new":
        return teal[300];
      case "inactive":
        return grey[300];
      default:
        return "blue";
    }
  };

  useEffect(() => {
    const activeBid = bidsList?.find((bid) => bid?.status === "active")?.bid;
    setNewBidValue(activeBid ?? 0);
  }, [bidsList]);

  useEffect(() => {
    const activeBid =
      bidsList?.find((bid) => bid?.status === "active")?.bid ?? 0;
    if (newBidValue < activeBid) {
      setErrorNewBid(true);
    } else {
      setErrorNewBid(false);
    }
    // eslint-disable-next-line
  }, [newBidValue]);

  useEffect(() => {
    const timer = setTimeout(() => {
      const verifyvalue = bidsList?.find(
        (bid) => bid?.status === "active"
      )?.bid;

      if (errorNewBid || verifyvalue === newBidValue) {
        setSendTimer(10);
      } else {
        setSendTimer((oldValue) => oldValue - 1);
      }
    }, 1000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line
  }, [sendTimer, errorNewBid, newBidValue]);

  const addBid = (value: number) => {
    setNewBidValue((oldValue) => oldValue + value);
  };

  const subBid = (value: number) => {
    setNewBidValue((oldValue) => oldValue - value);
  };

  const disabledSendButton =
    errorNewBid ||
    newBidValue === bidsList?.find((bid) => bid?.status === "active")?.bid;

  const isWaitingStore = Boolean(
    bidsList?.find((bid) => bid?.status === "new")
  );

  const font = isMobile ? 12 : 14;

  const translateStatus = (status: AuctionBidsStatus) => {
    switch (status) {
      case "active":
        return strings.newOrderPage.active[language];
      case "new":
        return strings.newOrderPage.new[language];
      case "inactive":
        return strings.newOrderPage.inactive[language];
      default:
        return "";
    }
  };

  return (
    <Box width={"100%"} display={"flex"} flexDirection={"column"} gap={1}>
      <Box
        width={"100%"}
        display={"flex"}
        justifyContent={"center"}
        flexDirection={"column"}
        gap={1}
        mb={1}
      >
        <Typography textAlign={"center"}>
          {strings.newOrderPage.bids[language]}
        </Typography>
        <Divider />
      </Box>
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={isMobile ? 0.5 : 2}
      >
        <Button
          variant={"contained"}
          color={"primary"}
          onClick={() => setShowHistoryModal(true)}
          disabled={bidsList?.length <= 1}
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            fontSize: font,
          }}
        >
          <UpdateIcon sx={{ mr: 1 }} /> {strings.newOrderPage.history[language]}
        </Button>
        <EastIcon />
        {filterByStatus().map((bid, index) => (
          <Box
            key={index}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            gap={isMobile ? 0 : 1}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                borderColor: bidColor(bid?.status),
                border: `5px solid ${bidColor(bid?.status)}`,
                borderRadius: "5px",
                minWidth: 70,
              }}
            >
              <Typography
                sx={{
                  background: bidColor(bid?.status),
                  width: "100%",
                  textAlign: "center",
                  color: "#FFFFFF",
                  fontSize: font,
                }}
              >
                {translateStatus(bid?.status).toUpperCase()}
              </Typography>
              <Typography sx={{ p: 1, fontSize: font }}>
                {JPYmoney(bid?.bid)}
              </Typography>
            </Box>
            {filterByStatus().length === 2 && index !== 1 && (
              <EastIcon sx={{ ml: isMobile ? 0.5 : 0 }} />
            )}
          </Box>
        ))}
      </Box>
      {!isAdmin && showSendButton && (
        <Button
          variant={"contained"}
          color={"primary"}
          onClick={() => setUpdateBidModal(true)}
          disabled={isWaitingStore}
          sx={{
            fontSize: font,
            "&:disabled": { background: grey[500], color: "#FFFFFF" },
            mb: -4,
          }}
        >
          {isWaitingStore
            ? strings.newOrderPage.waitingBidUpdate[language]
            : strings.newOrderPage.updateMyBid[language]}
        </Button>
      )}
      {isAdmin && isWaitingStore && (
        <Box
          display={"flex"}
          justifyContent={"center"}
          gap={1}
          flexWrap={"wrap"}
          mt={2}
        >
          <Button
            variant={"contained"}
            color={"primary"}
            onClick={() => setOpenSimpleModal({ open: true, type: "active" })}
            disabled={!isWaitingStore}
            sx={{
              width: 200,
              "&:disabled": { background: grey[500], color: "#FFFFFF" },
            }}
          >
            {strings.newOrderPage.updateClienteBid[language]}
          </Button>
          <Button
            variant={"contained"}
            color={"primary"}
            onClick={() => setOpenSimpleModal({ open: true, type: "inactive" })}
            disabled={!isWaitingStore}
            sx={{
              width: 200,
              background: red[500],
              "&:hover": { background: red[300] },
              "&:disabled": { background: grey[500], color: "#FFFFFF" },
            }}
          >
            {strings.newOrderPage.refuseBid[language]}
          </Button>
        </Box>
      )}

      <CustomModal
        openModal={showHistoryModal}
        setOpenModal={() => setShowHistoryModal(false)}
        title={"Histórico de lances"}
      >
        <Box
          display={"flex"}
          justifyContent={"center"}
          flexDirection={"column"}
          flexWrap={"wrap"}
          maxHeight={isMobile ? "auto" : 850}
        >
          {bidsList
            .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
            .map((bid, index) => {
              if (index + 1 === bidsList?.length) {
                return (
                  <Box
                    key={index}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    flexDirection={"column"}
                    gap={1}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                        borderColor: bidColor(bid?.status),
                        border: `5px solid ${bidColor(bid?.status)}`,
                        borderRadius: "5px",
                        minWidth: 70,
                      }}
                    >
                      <Typography
                        sx={{
                          background: bidColor(bid?.status),
                          width: "100%",
                          textAlign: "center",
                          color: "#FFFFFF",
                        }}
                      >
                        {translateStatus(bid?.status).toUpperCase()}
                      </Typography>
                      <Typography sx={{ pl: 1, pr: 1 }}>
                        {JPYmoney(bid?.bid)}
                      </Typography>
                      <Typography sx={{ pl: 1, pr: 1 }}>
                        {formatedDate(bid?.createdAt, true)}
                      </Typography>
                    </Box>
                    {bidsList?.length !== index + 1 && (
                      <EastIcon sx={{ rotate: "-90deg", mb: 1 }} />
                    )}
                    <EastIcon sx={{ rotate: "-90deg", mb: 1 }} />
                    <Typography
                      sx={{
                        background: blue[300],
                        color: "#FFFFFF",
                        p: 1,
                        borderRadius: 2,
                        fontWeight: 500,
                      }}
                    >
                      LEILÃO CRIADO
                    </Typography>
                  </Box>
                );
              } else {
                return (
                  <Box
                    key={index}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    flexDirection={"column"}
                    gap={1}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                        borderColor: bidColor(bid?.status),
                        border: `5px solid ${bidColor(bid?.status)}`,
                        borderRadius: "5px",
                        minWidth: 70,
                      }}
                    >
                      <Typography
                        sx={{
                          background: bidColor(bid?.status),
                          width: "100%",
                          textAlign: "center",
                          color: "#FFFFFF",
                        }}
                      >
                        {translateStatus(bid?.status).toUpperCase()}
                      </Typography>
                      <Typography sx={{ pl: 1, pr: 1 }}>
                        {JPYmoney(bid?.bid)}
                      </Typography>
                      <Typography sx={{ pl: 1, pr: 1 }}>
                        {formatedDate(bid?.createdAt, true)}
                      </Typography>
                    </Box>
                    {bidsList?.length !== index + 1 && (
                      <EastIcon sx={{ rotate: "-90deg", mb: 1 }} />
                    )}
                  </Box>
                );
              }
            })}
        </Box>
      </CustomModal>

      <CustomModal
        openModal={updateBidModal}
        setOpenModal={() => setUpdateBidModal(false)}
        title={strings.newOrderPage.updateMyBid[language]}
      >
        <Box
          display={"flex"}
          gap={1}
          width={"100%"}
          justifyContent={"center"}
          mt={1}
        >
          <Typography>{strings.newOrderPage.bidNow[language]}: </Typography>
          <Typography>
            {JPYmoney(
              bidsList?.find((bid) => bid?.status === "active")?.bid ?? 0
            )}
          </Typography>
        </Box>
        <Box width={"100%"} display={"flex"} justifyContent={"center"}>
          <Divider sx={{ width: "80%" }} />
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={3}
          justifyContent={"center"}
          alignItems={"center"}
          width={"100%"}
          mt={3}
          mb={3}
        >
          <Box display={"flex"} gap={1}>
            <Button
              variant="contained"
              sx={{
                background: teal[500],
                "&:hover": { background: teal[300] },
                width: 95,
              }}
              onClick={() => addBid(500)}
            >
              + {JPYmoney(500)}
            </Button>
            <Button
              variant="contained"
              sx={{
                background: teal[500],
                "&:hover": { background: teal[300] },
                width: 96,
              }}
              onClick={() => addBid(1000)}
            >
              + {JPYmoney(1000)}
            </Button>
            <Button
              variant="contained"
              sx={{
                background: teal[500],
                "&:hover": { background: teal[300] },
                width: 96,
              }}
              onClick={() => addBid(5000)}
            >
              + {JPYmoney(5000)}
            </Button>
          </Box>
          <Typography
            textAlign={"center"}
            sx={{
              border: `1px solid ${errorNewBid ? red[500] : teal[500]}`,
              borderRadius: 1,
              padding: 1,
            }}
          >
            {strings.newOrderPage.newBid[language]}: {JPYmoney(newBidValue)}{" "}
            {errorNewBid && (
              <Typography color={"error"}>
                {strings.newOrderPage.bidError[language]}
              </Typography>
            )}
          </Typography>
          <Box display={"flex"} gap={1}>
            <Button
              variant="contained"
              sx={{
                background: red[500],
                "&:hover": { background: red[300] },
                width: 95,
              }}
              onClick={() => subBid(500)}
              disabled={errorNewBid}
            >
              - {JPYmoney(500)}
            </Button>
            <Button
              variant="contained"
              sx={{
                background: red[500],
                "&:hover": { background: red[300] },
                width: 95,
              }}
              onClick={() => subBid(1000)}
              disabled={errorNewBid}
            >
              - {JPYmoney(1000)}
            </Button>
            <Button
              variant="contained"
              sx={{
                background: red[500],
                "&:hover": { background: red[300] },
                width: 95,
              }}
              onClick={() => subBid(5000)}
              disabled={errorNewBid}
            >
              - {JPYmoney(5000)}
            </Button>
          </Box>
        </Box>

        {!disabledSendButton && (
          <FadeInContainer>
            <Typography textAlign={"center"} mb={2}>
              {strings.newOrderPage.warningNewBid(newBidValue)[language]}
            </Typography>
          </FadeInContainer>
        )}

        <Button
          disabled={disabledSendButton || sendTimer > 0}
          variant="contained"
          sx={{
            background: blue[300],
            "&:hover": { background: blue[500] },
          }}
          fullWidth
          onClick={createNewBid}
        >
          {loading ? (
            <CircularProgress size={20} />
          ) : sendTimer > 0 ? (
            `${strings.newOrderPage.sendNewqBid[language]} ${
              sendTimer > 0 && sendTimer + "s"
            }`
          ) : (
            strings.newOrderPage.sendNewqBid[language]
          )}
        </Button>
      </CustomModal>

      <SimpleConfirmModal
        cancelClick={() => setOpenSimpleModal({ open: false, type: "" })}
        confirmClick={() => {
          if (openSimpleModal.type === "inactive") {
            refuseNewBidAdmin();
          } else {
            acceptNewBidAdmin();
          }
          setOpenSimpleModal({ open: false, type: "" });
        }}
        open={openSimpleModal.open}
        text={`${
          strings.newOrderPage.warningSendNewBid[language]
        } ${translateStatus(openSimpleModal.type as any).toUpperCase()}?`}
      />
    </Box>
  );
}
