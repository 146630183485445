import { Box, Typography, TypographyProps } from "@mui/material";

interface Props {
  title: string | number;
  text: string | number;
  titleProps?: TypographyProps;
  textProps?: TypographyProps;
  html?: string;
}

export function DataText({ title, text, titleProps, textProps, html }: Props) {
  return (
    <Box display={"flex"}>
      <Typography variant="h2" fontWeight={500} width={"110px"} {...titleProps}>
        {title}
      </Typography>
      {html ? (
        <div dangerouslySetInnerHTML={{ __html: html }} />
      ) : (
        <Typography
          variant="h2"
          fontWeight={400}
          width={"200px"}
          {...textProps}
        >
          {text}
        </Typography>
      )}
    </Box>
  );
}
