import "./styles.css"; // Se você estiver usando o mesmo CSS
import { useEffect, useState } from "react";
import { getTreasureProductsListHomeService } from "../../../../../../services/treasureProducts";
import { Box, Typography } from "@mui/material";
import { useBreakpoints } from "../../../../../../hooks/useBreakpoins";
import { amber, grey } from "@mui/material/colors";
import { useJPYmoney } from "../../../../../../hooks/useJPYmoney";

export function Slider2Products() {
  const { JPYmoney } = useJPYmoney();
  const { isMobile } = useBreakpoints();
  const [productsHome, setProductsHome] = useState([] as TreasureProducts[]);
  const getProductHome = async () => {
    try {
      const getProductsHome = await getTreasureProductsListHomeService();
      setProductsHome(getProductsHome);
    } catch {}
  };

  useEffect(() => {
    getProductHome();
  }, []);

  const Product = ({ product }: { product: TreasureProducts }) => (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      sx={{
        maxHeight: 435,
        boxShadow: "0px 4px 14px -2px #0000002E",
        width: isMobile ? 140 : 200,
        height: "Hug (410x)px",
        padding: "8px",
        gap: "8px",
        borderRadius: "12px",
        opacity: " 0px",
        background: "#FFFFFF",
      }}
    >
      <img
        src={product?.treasureProductsImages[0]?.code}
        width={isMobile ? 130 : 150}
        height={isMobile ? 130 : 150}
        alt={"product"}
        style={{ borderRadius: "12px", objectFit: "contain" }}
      />

      <Typography
        sx={{
          mt: isMobile ? 3 : 0,
          height: 48,
          width: "100%",
          wordBreak: "break-word",
          textOverflow: "ellipsis",
          overflow: "hidden",
          textAlign: "center",
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 2,
          whiteSpace: "normal",
        }}
      >
        {product?.name}
      </Typography>
      <Typography
        sx={{
          height: 24,
          color: grey[500],
          width: "100%",
          wordBreak: "break-word",
          textOverflow: "ellipsis",
          overflow: "hidden",
          textAlign: "center",
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 1,
          whiteSpace: "normal",
        }}
      >
        {product?.type}
      </Typography>
      <Typography
        sx={{
          height: 24,
          color: grey[500],
          width: "100%",
          wordBreak: "break-word",
          textOverflow: "ellipsis",
          overflow: "hidden",
          textAlign: "center",
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 1,
          whiteSpace: "normal",
        }}
      >
        {product?.origin}
      </Typography>

      <Typography
        sx={{
          fontSize: "18px",
          fontWeight: 500,
          textAlign: "left",
          color: amber[800],
          minHeight: 52,
        }}
      >
        {JPYmoney(product?.price)}
      </Typography>
    </Box>
  );

  return (
    <div className="logos2">
      <div className="logos2-slide-wrapper">
        <div className="logos2-slide">
          {[...productsHome, ...productsHome].map((prd) => (
            <Product product={prd} key={prd.id} />
          ))}
        </div>
      </div>
    </div>
  );
}
