import { Box, Divider, InputAdornment, Typography } from "@mui/material";
import { countriesWithZones } from "../EditCreateAddress/data";
import { GenericInput, GenericSelect } from "../../Molecules";
import { useCallback, useEffect, useState } from "react";
import { useBreakpoints } from "../../../../hooks/useBreakpoins";
import { BreakpointBox, CircularLoading } from "../../Atoms";
import { calcShipPrice } from "../../../../services/shippmentSimulation";
import { grey } from "@mui/material/colors";
import { useJPYmoney } from "../../../../hooks/useJPYmoney";
import japanpostlogo from "../../../../assets/images/japanpostlogo.png";
// import dhllogo from "../../../../assets/images/dhllogo.png";

type FreteTypes = {
  tax: number;
  shippmentFee: number;
};

interface Frete {
  smallPacket: FreteTypes;
  ems: FreteTypes;
  surface: FreteTypes;
  airParcel: FreteTypes;
}

export function ShippingFeeSimulator() {
  const { JPYmoney } = useJPYmoney();
  const { isMobile } = useBreakpoints();
  const [contrySelected, setCountrySelected] = useState("Brazil");
  const [weight, setWeight] = useState(400);
  const [frete, setFrete] = useState({} as Frete);
  const [loading, setLoading] = useState(false);

  const globalZoneId =
    countriesWithZones.find((country) => country.name === contrySelected)
      ?.globalZoneId ?? 0;

  const regexNumber = new RegExp("^[0-9]*$");

  const calcFrete = useCallback(async () => {
    setLoading(true);
    try {
      const getFrete = await calcShipPrice({ weight, globalZoneId });
      setFrete(getFrete);
    } catch {
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [contrySelected, weight]);

  //   useEffect(() => {
  //     setCountrySelected("Brazil");
  //     setWeight(2000);
  //   }, [])

  useEffect(() => {
    if (contrySelected && weight > 0) {
      calcFrete();
    }
    // eslint-disable-next-line
  }, [contrySelected, weight]);

  const calcPrice = (price: number) => {
    if (!price) return 0;
    return JPYmoney(price);
  };

  return (
    <Box sx={{ background: "#FFF", borderRadius: 5, p: 2 }}>
      <BreakpointBox>
        <GenericSelect
          name="country"
          onChange={(value) => setCountrySelected(value)}
          options={countriesWithZones.map((data) => ({
            label: data.name,
            value: data.name,
          }))}
          placeholder={"Country"}
          value={contrySelected}
          defaultValue={contrySelected}
          width={isMobile ? "100%" : "50%"}
        />
        <GenericInput
          hasIcon={false}
          endAdornment={<InputAdornment position="end">g</InputAdornment>}
          onChange={(value) => {
            if (value.match(regexNumber)) {
              if (Number(value) > 30000) {
                setWeight(3000);
              } else {
                setWeight(Number(value));
              }
            }
          }}
          value={weight}
          placeholder={"Weight"}
          inputName="address"
          width={isMobile ? "100%" : "50%"}
        />
      </BreakpointBox>

      <Box
        display={"flex"}
        justifyContent={isMobile ? "center" : "space-between"}
        flexWrap={"wrap"}
        alignItems={"center"}
      >
        <Box
          sx={{
            p: 2,
            background: weight > 2000 ? grey[300] : "transparent",
            borderRadius: 2,
          }}
        >
          <Typography
            variant="h1"
            mb={2}
            sx={{ color: weight > 2000 ? grey[500] : "black" }}
          >
            Small Packet
          </Typography>
          <Box display="flex">
            <Typography
              width={112}
              sx={{ color: weight > 2000 ? grey[500] : "black" }}
            >
              Service tax:
            </Typography>
            <Typography sx={{ color: weight > 2000 ? grey[500] : "black" }}>
              {loading ? (
                <CircularLoading mb={0} mt={1} size={10} />
              ) : weight > 2000 ? (
                "Very heavy"
              ) : (
                calcPrice(frete?.smallPacket?.tax)
              )}
            </Typography>
          </Box>
          <Box display="flex">
            <Typography
              width={112}
              sx={{ color: weight > 2000 ? grey[500] : "black" }}
            >
              Shippment fee:
            </Typography>
            <Typography sx={{ color: weight > 2000 ? grey[500] : "black" }}>
              {loading ? (
                <CircularLoading mb={0} mt={1} size={10} />
              ) : weight > 2000 ? (
                "Very heavy"
              ) : (
                calcPrice(frete?.smallPacket?.shippmentFee ?? 0)
              )}
            </Typography>
          </Box>

          <Divider />
          <Box display="flex">
            <Typography
              width={112}
              sx={{ color: weight > 2000 ? grey[500] : "black" }}
            >
              Total:
            </Typography>
            <Typography sx={{ color: weight > 2000 ? grey[500] : "black" }}>
              {loading ? (
                <CircularLoading mb={0} mt={1} size={10} />
              ) : weight > 2000 ? (
                "Very heavy"
              ) : (
                calcPrice(
                  frete?.smallPacket?.shippmentFee + frete?.smallPacket?.tax
                )
              )}
            </Typography>
          </Box>
        </Box>

        <Box sx={{ p: 2 }}>
          <Typography variant="h1" mb={2}>
            EMS
          </Typography>
          <Box display="flex">
            <Typography width={112}>Service tax:</Typography>
            <Typography>
              {loading ? (
                <CircularLoading mb={0} mt={1} size={10} />
              ) : (
                calcPrice(frete?.ems?.tax)
              )}
            </Typography>
          </Box>
          <Box display="flex">
            <Typography width={112}>Shippment fee:</Typography>
            <Typography>
              {loading ? (
                <CircularLoading mb={0} mt={1} size={10} />
              ) : (
                calcPrice(frete?.ems?.shippmentFee)
              )}
            </Typography>
          </Box>

          <Divider />
          <Box display="flex">
            <Typography width={112}>Total:</Typography>
            <Typography>
              {loading ? (
                <CircularLoading mb={0} mt={1} size={10} />
              ) : (
                calcPrice(frete?.ems?.shippmentFee + frete?.ems?.tax)
              )}
            </Typography>
          </Box>
        </Box>

        <Box sx={{ p: 2 }}>
          <Typography variant="h1" mb={2}>
            Sal Ship Parcel
          </Typography>
          <Box display="flex">
            <Typography width={112}>Service tax:</Typography>
            <Typography>
              {loading ? (
                <CircularLoading mb={0} mt={1} size={10} />
              ) : (
                calcPrice(frete?.surface?.tax)
              )}
            </Typography>
          </Box>
          <Box display="flex">
            <Typography width={112}>Shippment fee:</Typography>
            <Typography>
              {loading ? (
                <CircularLoading mb={0} mt={1} size={10} />
              ) : (
                calcPrice(frete?.surface?.shippmentFee)
              )}
            </Typography>
          </Box>
          <Divider />
          <Box display="flex">
            <Typography width={112}>Total:</Typography>
            <Typography>
              {loading ? (
                <CircularLoading mb={0} mt={1} size={10} />
              ) : (
                calcPrice(frete?.surface?.shippmentFee + frete?.surface?.tax)
              )}
            </Typography>
          </Box>
        </Box>

        <Box sx={{ p: 2 }}>
          <Typography variant="h1" mb={2}>
            Sal Air Parcel
          </Typography>
          <Box display="flex">
            <Typography width={112}>Service tax:</Typography>
            <Typography>
              {loading ? (
                <CircularLoading mb={0} mt={1} size={10} />
              ) : (
                calcPrice(frete?.airParcel?.tax)
              )}
            </Typography>
          </Box>
          <Box display="flex">
            <Typography width={112}>Shippment fee:</Typography>
            <Typography>
              {loading ? (
                <CircularLoading mb={0} mt={1} size={10} />
              ) : (
                calcPrice(frete?.airParcel?.shippmentFee)
              )}
            </Typography>
          </Box>
          <Divider />
          <Box display="flex">
            <Typography width={112}>Total:</Typography>
            <Typography>
              {loading ? (
                <CircularLoading mb={0} mt={1} size={10} />
              ) : (
                calcPrice(
                  frete?.airParcel?.shippmentFee + frete?.airParcel?.tax
                )
              )}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        width={"100%"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <img
          src={japanpostlogo}
          alt="japan post logo"
          width={150}
          height={40}
        />
        {/* <img src={dhllogo} alt="dhl logo" width={150} /> */}
      </Box>
    </Box>
  );
}
